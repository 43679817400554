<template>
  <div class="register-container">
    <app-header rootClassName="header-root-class-name1"></app-header>
    <div class="register-container1 section">
      <app-h1 H1="We believe that learning should be fun" rootClassName="h1-root-class-name2"></app-h1>
    </div>
    <p class="register-text">
      And we&apos;re committed to providing an enjoyable and engaging way to
      improve your soft skills through play.
    </p>
    <div class="register-steam section">
      <div class="register-cta">
        <div class="register-container2">
          <span class="register-text1" @click="loginWithSteam" style="cursor: pointer;">Sign in through STEAM</span>
          <div v-if="username"></div>	<!-- Check if username is defined -->
        </div>
        <span class="register-text2">This site is not associated with Valve Corp.</span>
      </div>
      <a :href="appLogin" target="_blank" rel="noopener noreferrer" class="register-navlink1">Login as an Admin/Coach?</a>
      <mobile-only-msg>rootClassName="mobile-only-msg-root-class-name1"</mobile-only-msg>
      <img alt="pastedImage" src="/external/pastedimage-fj2-1400w.png" class="register-pasted-image"/>
    </div>
    <div class="register-container3 section">
      <div class="register-container4">
        <app-step number="1" description="Click the link above to register with Steam" rootClassName="step-root-class-name"></app-step>
        <app-step number="2" description="Activate your account using your Steam credentials" rootClassName="step-root-class-name1"></app-step>
        <app-step number="3" description="Go to Steam, choose your game and begin playing" rootClassName="step-root-class-name2"></app-step>
        <app-step number="4" description="Your dashboard in SkillQuest App will offer analytics after a few hours of play"></app-step>
      </div>
      <div class="register-container5"></div>
    </div>
    <app-footer rootClassName="footer-root-class-name1"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppH1 from '../components/h1'
import MobileOnlyMsg from '../components/mobile-only-msg'
import AppStep from '../components/step'
import AppFooter from '../components/footer'

export default {
  name: 'Register',
  components: {
    AppHeader,
    AppH1,
    MobileOnlyMsg,
    AppStep,
    AppFooter,
  },
  data() {
    return {
      // Logs in the development server as a user with my wisepath.ai address
      // appLogin: 'https://app.skillquest.ai/it-it/paa-login?Code=57d1455d-52b7-46cd-b093-821f483fc9d4',
      // appLogin: `https://app.skillquest.ai/it-it/paa-login?Code=${this.inviteToken}`,
      user: null,
      username: null, // Initialize username data
      inviteToken: null,	// Received in the link from PlantanApp invite and will be used to automatically login
      steamId: null,
    };
  },
  mounted() {
    //The URL received has the URL Parameter needed, inviteToken
	  const queryString = window.location.search;
	  const params = new URLSearchParams(queryString);
	  this.inviteToken = params.get('inviteToken');
	},
  computed: {
    appLogin() {
	    return 'https://app.skillquest.ai/en-us/paa-login';
      //return `https://app.skillquest.ai/en-us/paa-login?Code=${this.inviteToken}&steamId=${steamId}`;
    },
  },
  methods: {
    async loginWithSteam() {
      // Redirect the user to the Steam login page
      // Open a new window/tab for authentication
      const params = new URLSearchParams(window.location.search);
	    window.location.replace(`https://skillquest.ai:3000/auth/steam/${params.get('inviteToken') ? `?inviteToken=${params.get('inviteToken')}` : ''}`);
    },
  },
  metaInfo: {
    title: 'Register - SkillQuest',
    meta: [{ property:'og:title', content:'Register - SkillQuest' }],
  },
}
</script>

<style scoped>
.register-container {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.register-container1 {
  flex: 1;
  display: flex;
  align-self: center;
  justify-content: center;
}
.register-text {
  max-width: 770px;
  margin-top: -80px;
  font-size: 32px;
  text-align: center;
  padding-top: 00px;
}
.register-steam {
  gap: var(--dl-space-space-sixunits);
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.register-cta {
  width: 700px;
  height: auto;
  display: flex;
  overflow: hidden;
  border-radius: 30px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-app-primary);
}
.register-navlink {
  display: contents;
}
.register-container2 {
  flex: 0 0 auto;
  width: 700px;
  height: 130px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-app-lightblue);
}
.register-text1 {
  font-size: 36px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.register-text2 {
  color: var(--dl-color-app-white);
  width: 100%;
  height: 60px;
  display: flex;
  font-size: 26px;
  text-align: center;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: center;
}
.register-navlink1 {
  font-size: 26px;
  text-decoration: none;
}
.register-pasted-image {
  width: 617px;
}
.register-container3 {
  gap: var(--dl-space-space-sixunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.register-container4 {
  gap: var(--dl-space-space-sixunits);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.register-container5 {
  gap: var(--dl-space-space-sixunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
}
@media(max-width: 767px) {
  .register-text {
    font-size: 28px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-sixunits);
    padding-right: var(--dl-space-space-sixunits);
  }
  .register-steam {
    gap: var(--dl-space-space-threeunits);
  }
  .register-cta {
    width: 90%;
  }
  .register-container2 {
    width: 100%;
  }
  .register-text2 {
    font-size: 16px;
  }
  .register-pasted-image {
    width: 100%;
  }
  .register-container4 {
    width: var(--dl-size-size-xxlarge);
    align-items: center;
    flex-direction: column;
  }
  .register-container5 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .register-text {
    font-size: 28px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .register-cta {
    width: 90%;
    align-items: center;
    justify-content: center;
  }
  .register-container2 {
    width: 90%;
    align-self: center;
  }
  .register-text1 {
    font-size: 24px;
  }
  .register-text2 {
    color: #052f9c;
  }
  .register-container3 {
    flex-direction: column;
  }
}
</style>
