<template>
  <div class="app-component-container" v-bind:class="rootClassName">
    <calendar-pick></calendar-pick>
    <calendar-pick date="January 24, 2019"></calendar-pick>
  </div>
</template>

<script>
import CalendarPick from './calendar-pick'

export default {
  name: 'AppComponent',
  props: {
    rootClassName: String,
  },
  components: {
    CalendarPick,
  },
}
</script>

<style scoped>
.app-component-container {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
</style>
