<template>
  <div class="calendar-pick-container">
    <div class="calendar-pick-container1">
      <span class="calendar-pick-text">{{ date }}</span>
    </div>
    <img
      alt="pastedImage"
      src="/external/pastedimage-2hu8-200w.png"
      class="calendar-pick-pasted-image"
    />
  </div>
</template>

<script>
export default {
  name: 'CalendarPick',
  props: {
    date: {
      type: String,
      default: 'June 09, 2016',
    },
  },
}
</script>

<style scoped>
.calendar-pick-container {
  gap: var(--dl-space-space-halfunit);
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  padding-left: 4px;
  border-radius: 10px;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
}
.calendar-pick-container1 {
  width: 140px;
  height: 30px;
  display: flex;
  align-items: flex-end;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.calendar-pick-text {
  font-size: 14px;
}
.calendar-pick-pasted-image {
  width: 19px;
}
</style>
