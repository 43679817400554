<template>
  <div class="button-container" v-bind:class="rootClassName">
    <button class="button-button">
      <span class="button-text">{{ text }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {
      type: String,
      default: 'learn more',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.button-container {
  display: flex;
  position: relative;
}
.button-button {
  width: 260px;
  height: 60px;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius8);
  text-transform: uppercase;
  justify-content: center;
  background-color: var(--dl-color-app-primary);
}
.button-text {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 24px;
  align-self: auto;
  font-style: SemiBold;
  text-align: center;
  font-family: Fredoka;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.button-root-class-name {
  align-self: center;
}
.button-root-class-name1 {
  margin-top: var(--dl-space-space-threeunits);
}
</style>
