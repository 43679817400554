<template>
  <div class="on-board-from-steam-container">
    <app-header rootClassName="header-root-class-name4"></app-header>
    <div class="on-board-from-steam-container01">
      <div class="on-board-from-steam-container02">
        <app-h1 H1="Profile" rootClassName="h1-root-class-name5"></app-h1>
        <span class="on-board-from-steam-text">Tell us a bit about yourself</span>
      </div>
      <div class="on-board-from-steam-container03">
        <div class="on-board-from-steam-container04 section">
          <div class="on-board-from-steam-container05">
            <span class="on-board-from-steam-text01">First and last name</span>
            <input
              type="text"
              placeholder="Enter your name"
              class="on-board-from-steam-textinput input"
            />
          </div>
          <div class="on-board-from-steam-container06">
            <div class="on-board-from-steam-container07">
              <span class="on-board-from-steam-text02">Email</span>
              <span class="on-board-from-steam-text03">*</span>
            </div>
            <input
              type="text"
              placeholder="Enter your email address"
              class="on-board-from-steam-textinput1 input"
            />
          </div>
          <div class="on-board-from-steam-container08">
            <span class="on-board-from-steam-text04">Location</span>
            <input
              type="text"
              placeholder="Enter your location"
              class="on-board-from-steam-textinput2 input"
            />
          </div>
          <div class="on-board-from-steam-container09">
            <span class="on-board-from-steam-text05">Birthday</span>
            <input
              type="text"
              placeholder="Enter your birthday: MM/DD/YYYY"
              class="on-board-from-steam-textinput3 input"
            />
          </div>
          <div class="on-board-from-steam-container10">
            <span class="on-board-from-steam-text06">
              Profession/Filed of study
            </span>
            <input
              type="text"
              placeholder="Enter your profession or field of study"
              class="on-board-from-steam-textinput4 input"
            />
          </div>
          <div class="on-board-from-steam-container11">
            <span class="on-board-from-steam-text07">
              Why do you want to improve your soft skills?
            </span>
            <div class="on-board-from-steam-container12">
              <span class="on-board-from-steam-text08">
                Cognitive Flexibility
              </span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-fv4-200h.png"
                class="on-board-from-steam-pasted-image"
              />
            </div>
          </div>
          <div class="on-board-from-steam-container13">
            <img
              alt="pastedImage"
              src="/external/pastedimage-zb1.svg"
              class="on-board-from-steam-checkbox"
            />
            <span>I&apos;m 16 years or older</span>
          </div>
          <div class="on-board-from-steam-container14">
            <img
              alt="pastedImage"
              src="/external/pastedimage-zb1.svg"
              class="on-board-from-steam-checkbox1"
            />
            <span>I have read the Privacy and Cookie Policy</span>
          </div>
          <router-link to="/app" class="on-board-from-steam-navlink button">
            <span>
              <span>ENTER</span>
              <br />
            </span>
          </router-link>
        </div>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name8"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppH1 from '../components/h1'
import AppFooter from '../components/footer'

export default {
  name: 'OnBoardFromSteam',
  components: {
    AppHeader,
    AppH1,
    AppFooter,
  },
  metaInfo: {
    title: 'On-Board-from-Steam - SkillQuest',
    meta: [
      {
        property: 'og:title',
        content: 'On-Board-from-Steam - SkillQuest',
      },
    ],
  },
}
</script>

<style scoped>
.on-board-from-steam-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.on-board-from-steam-container01 {
  gap: 30px;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: flex-start;
}
.on-board-from-steam-container02 {
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.on-board-from-steam-text {
  color: var(--dl-color-app-neutral);
  font-size: 14px;
}
.on-board-from-steam-container03 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.on-board-from-steam-container04 {
  gap: 20px;
  flex: 1;
  display: flex;
  max-width: 460px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.on-board-from-steam-container05 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.on-board-from-steam-text01 {
  font-style: normal;
  font-weight: 500;
}
.on-board-from-steam-textinput {
  width: 100%;
}
.on-board-from-steam-container06 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.on-board-from-steam-container07 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.on-board-from-steam-text02 {
  font-style: normal;
  font-weight: 500;
}
.on-board-from-steam-text03 {
  color: var(--dl-color-app-orange);
  font-style: normal;
  font-weight: 500;
}
.on-board-from-steam-textinput1 {
  width: 100%;
}
.on-board-from-steam-container08 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.on-board-from-steam-text04 {
  font-style: normal;
  font-weight: 500;
}
.on-board-from-steam-textinput2 {
  width: 100%;
}
.on-board-from-steam-container09 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.on-board-from-steam-text05 {
  font-style: normal;
  font-weight: 500;
}
.on-board-from-steam-textinput3 {
  width: 100%;
}
.on-board-from-steam-container10 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.on-board-from-steam-text06 {
  font-style: normal;
  font-weight: 500;
}
.on-board-from-steam-textinput4 {
  width: 100%;
}
.on-board-from-steam-container11 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.on-board-from-steam-text07 {
  font-style: normal;
  font-weight: 500;
}
.on-board-from-steam-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 42px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.on-board-from-steam-text08 {
  color: #5F5F5F50;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.on-board-from-steam-pasted-image {
  width: 12px;
}
.on-board-from-steam-container13 {
  gap: 8px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.on-board-from-steam-checkbox {
  width: 30px;
  height: 30px;
}
.on-board-from-steam-container14 {
  gap: 8px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.on-board-from-steam-checkbox1 {
  width: 30px;
  height: 30px;
}
.on-board-from-steam-navlink {
  color: var(--dl-color-app-white);
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-app-primary);
}
@media(max-width: 991px) {
  .on-board-from-steam-container02 {
    gap: 5px;
    align-self: center;
    align-items: center;
  }
  .on-board-from-steam-text {
    color: var(--dl-color-app-neutral);
  }
}
</style>
