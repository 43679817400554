<template>
  <div class="how-it-works-container">
    <app-header rootClassName="header-root-class-name"></app-header>
    <app-h1 rootClassName="h1-root-class-name1"></app-h1>
    <div class="how-it-works-hero section">
      <div class="how-it-works-container1">
        <img
          alt="pastedImage"
          src="/external/pastedimage-s1bf-500w.png"
          class="how-it-works-pasted-image"
        />
        <p class="how-it-works-text p">
          And with our partnership with Wisepath, you can measure and track your
          progress over time to ensure you&apos;re making the most of your gaming
          experience.
        </p>
      </div>
    </div>
    <div class="how-it-works-how-it-works section">
      <app-h2 H2="How It Works" rootClassName="h2-root-class-name1"></app-h2>
      <div class="how-it-works-row-1">
        <div class="how-it-works-container2">
          <app-step
            number="1"
            description="Click the link on our REGISTER page"
            rootClassName="step-root-class-name3"
          ></app-step>
          <app-step
            number="2"
            description="Activate your account using your Steam credentials"
            rootClassName="step-root-class-name4"
          ></app-step>
        </div>
        <img
          alt="pastedImage"
          src="/external/pastedimage-qya5-500h.png"
          class="how-it-works-pasted-image1"
        />
      </div>
      <div class="how-it-works-row-2">
        <img
          alt="pastedImage"
          src="/external/pastedimage-0jaj-800w.png"
          class="how-it-works-pasted-image2"
        />
        <div class="how-it-works-container3">
          <app-step
            number="3"
            description="Go to Steam, choose your game and begin playing"
            rootClassName="step-root-class-name5"
          ></app-step>
          <app-step
            number="4"
            description="Your dashboard in SkillQuest App will offer analytics after a few hours of play"
            rootClassName="step-root-class-name6"
          ></app-step>
        </div>
      </div>
      <router-link to="/register" class="how-it-works-navlink">
        <app-button
          text="REGISTER ME!"
          rootClassName="button-root-class-name"
          class="how-it-works-component3"
        ></app-button>
      </router-link>
    </div>
    <app-footer rootClassName="footer-root-class-name"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppH1 from '../components/h1'
import AppH2 from '../components/h2'
import AppStep from '../components/step'
import AppButton from '../components/button'
import AppFooter from '../components/footer'

export default {
  name: 'HowItWorks',
  components: {
    AppHeader,
    AppH1,
    AppH2,
    AppStep,
    AppButton,
    AppFooter,
  },
  metaInfo: {
    title: 'How-it-works - SkillQuest',
    meta: [
      {
        property: 'og:title',
        content: 'How-it-works - SkillQuest',
      },
    ],
  },
}
</script>

<style scoped>
.how-it-works-container {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.how-it-works-hero {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  position: relative;
  min-height: 532px;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.how-it-works-container1 {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-self: stretch;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  margin-left: var(--dl-space-space-sixunits);
  flex-direction: row;
  justify-content: flex-start;
}
.how-it-works-pasted-image {
  top: -156px;
  left: 503px;
  width: 360px;
  position: absolute;
}
.how-it-works-text {
  width: 443px;
  text-align: left;
  padding-left: var(--dl-space-space-twounits);
}
.how-it-works-how-it-works {
  gap: var(--dl-space-space-sixunits);
  display: flex;
  flex-direction: column;
}
.how-it-works-row-1 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.how-it-works-container2 {
  gap: var(--dl-space-space-sixunits);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.how-it-works-pasted-image1 {
  width: 50%;
  display: flex;
  align-self: center;
}
.how-it-works-row-2 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.how-it-works-pasted-image2 {
  width: 50%;
  display: flex;
  align-self: center;
}
.how-it-works-container3 {
  gap: var(--dl-space-space-sixunits);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.how-it-works-navlink {
  display: contents;
}
.how-it-works-component3 {
  text-decoration: none;
}
@media(max-width: 991px) {
  .how-it-works-pasted-image {
    top: -95px;
    left: 441px;
    width: 30%;
  }
  .how-it-works-text {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 767px) {
  .how-it-works-container {
    gap: var(--dl-space-space-threeunits);
  }
  .how-it-works-hero {
    min-height: 634px;
    border-color: var(--dl-color-app-primary);
    border-width: 1px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .how-it-works-container1 {
    gap: var(--dl-space-space-threeunits);
    align-self: center;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    margin-left: 0px;
    flex-direction: column;
  }
  .how-it-works-pasted-image {
    width: 294px;
    height: 406px;
    position: unset;
  }
  .how-it-works-text {
    width: 100%;
    font-size: 22px;
    text-align: center;
    margin-left: 0px;
    padding-left: 0px;
  }
  .how-it-works-how-it-works {
    height: 2211px;
    padding-top: var(--dl-space-space-twounits);
  }
  .how-it-works-row-1 {
    flex-direction: column;
  }
  .how-it-works-pasted-image1 {
    width: 634px;
    height: 406px;
  }
  .how-it-works-row-2 {
    flex-direction: column-reverse;
  }
  .how-it-works-pasted-image2 {
    width: 506px;
    height: 393px;
  }
}
@media(max-width: 479px) {
  .how-it-works-hero {
    width: 320px;
  }
  .how-it-works-pasted-image1 {
    width: 407px;
    height: 260px;
  }
  .how-it-works-pasted-image2 {
    width: 372px;
    height: 288px;
  }
}
</style>
