<template>
  <div class="sign-in-container">
    <app-header rootClassName="header-root-class-name3"></app-header>
    <div class="sign-in-container01 section">
      <div class="sign-in-container02">
        <img alt="pastedImage" src="/external/pastedimage-yd4w-600w.png" class="sign-in-pasted-image"/>
      </div>
      <div class="sign-in-container03">
        <div class="sign-in-container04">
          <div class="sign-in-container05">
            <app-h1 H1="Sign In" rootClassName="h1-root-class-name4"></app-h1>
            <div class="sign-in-container06">
              <div class="sign-in-container07">
                <span class="sign-in-text">Email</span>
                <input type="text" placeholder="Enter your email address" class="sign-in-textinput input"/>
              </div>
              <div class="sign-in-container08">
                <span class="sign-in-text1">Password</span>
                <div class="sign-in-container09">
                  <input type="password" placeholder="Enter your password" class="sign-in-textinput1 input"/>
                  <img alt="pastedImage" src="/external/pastedimage-cf5s.svg" class="sign-in-pasted-image1"/>
                </div>
              </div>
              <router-link to="/app" class="sign-in-navlink button"><span><span>ENTER</span><br/></span></router-link>
              <div v-if="username"> <!-- Check if username is defined -->
                <h4>STEAM authenticated you as: {{ username }}</h4>
                <h4>with steamId: {{ steamId }}</h4>
                <!-- Other content for logged-in state without Steam -->
                <!-- <img :src="user.profilePicture" alt="Profile Picture" v-if="user.profilePicture" /> -->
                <!-- Display profile picture if available -->
              </div>
                <div v-else>
                  <button @click="loginWithSteam">Login with Steam</button>
                </div>
                  <span><a :href="externalForgotPassword" target="_blank" class="footer-cookie-policy1 footer-links">Forgot password: ?</a></span>
              </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name7"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppH1 from '../components/h1'
import AppFooter from '../components/footer'

export default {
  name: 'SignIn',
  components: {
    AppHeader,
    AppH1,
    AppFooter,
  },
  created() {
		const inviteToken = this.$route.query.invitetoken;
		const userId = this.$route.query.userId;
    // Log the received parameters
		console.log("invitetoken", inviteToken);
		console.log("userId", userId);
  },
  data() {
    return {
      externalForgotPassword: 'https://app.skillquest.ai/en-us/forgot-password/',
      user:null,
      username: null, // Initialize username data
      steamId: null, // Initialize steamId data
      inviteToken: null, // Initialize inviteToken data
      userId: null, // Initialize userId data
    };
  },
  methods: {
    async loginWithSteam() {
      // Redirect the user to the Steam login page
      // Open a new window/tab for authentication
      const popup = window.open('http://skillquest.ai:3000/auth/steam?callbackUrl=http://skillquest.ai:3000/auth/steam/return', '_blank');
      window.addEventListener('message', async (event) => {
        if (event.data && event.data.user) {
          console.log("in event listener")
          this.user = event.data.user; // Update user data when received
          this.username = event.data.user.username
          this.steamId = event.data.user.steamId
          popup.close();
        }
      });
      console.log(this.user);
      console.log(this.username);
    },
  },
  metaInfo: {
    title: 'Sign-In - SkillQuest',
    meta: [{ property:'og:title', content:'Sign-In - SkillQuest' }],
  },
}
</script>

<style scoped>
.sign-in-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}
.sign-in-container01 {
  gap: 40px;
  flex: 1;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: center;
}
.sign-in-container02 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.sign-in-pasted-image {
  flex: 1;
  width: 100%;
  max-width: 600px;
}
.sign-in-container03 {
  flex: 1;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.sign-in-container04 {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}
.sign-in-container05 {
  gap: 30px;
  flex: 1;
  display: flex;
  max-width: 460px;
  align-items: center;
  flex-direction: column;
}
.sign-in-container06 {
  gap: 20px;
  width: 100%;
  display: flex;
  max-width: 480px;
  align-items: center;
  flex-direction: column;
}
.sign-in-container07 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sign-in-text {
  font-style: normal;
  font-weight: 500;
}
.sign-in-textinput {
  width: 100%;
}
.sign-in-container08 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sign-in-text1 {
  font-style: normal;
  font-weight: 500;
}
.sign-in-container09 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.sign-in-textinput1 {
  width: 100%;
}
.sign-in-pasted-image1 {
  top: 0px;
  right: var(--dl-space-space-unit);
  width: 21px;
  bottom: 0px;
  height: 16px;
  margin: auto;
  position: absolute;
}
.sign-in-navlink {
  color: var(--dl-color-app-white);
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-app-primary);
}
.sign-in-text5 {
  font-size: 14px;
  align-self: center;
}
.sign-in-container10 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.sign-in-text6 {
  font-size: 14px;
  align-self: center;
}
.sign-in-navlink1 {
  color: var(--dl-color-app-orange);
  font-size: 14px;
  align-self: center;
  text-decoration: none;
}
@media(max-width: 991px) {
  .sign-in-container01 {
    flex-direction: column;
  }
  .sign-in-pasted-image {
    max-height: 300px;
  }
  .sign-in-container03 {
    width: 100%;
  }
  .sign-in-container05 {
    max-width: 100%;
  }
  .sign-in-container06 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .sign-in-pasted-image {
    max-height: 300px;
  }
  .sign-in-container05 {
    width: 100%;
  }
}
</style>
