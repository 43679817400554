var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-up-container"},[_c('app-header',{attrs:{"rootClassName":"header-root-class-name5"}}),_c('div',{staticClass:"sign-up-container01"},[_c('div',{staticClass:"sign-up-container02"},[_c('app-h1',{attrs:{"H1":"Sign Up","rootClassName":"h1-root-class-name6"}}),_c('span',{staticClass:"sign-up-text"},[_vm._v("We're glad you're here")])],1),_c('div',{staticClass:"sign-up-container03"},[_c('div',{staticClass:"sign-up-container04 section"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('router-link',{staticClass:"sign-up-navlink button",attrs:{"to":"/app-coach-profile-teams"}},[_c('span',[_c('span',[_vm._v("ENTER")]),_c('br')])])],1),_c('div',{staticClass:"sign-up-container15"},[_c('router-link',{staticClass:"sign-up-navlink1",attrs:{"to":"/sign-in"}},[_vm._v("Already have an account?")]),_c('router-link',{staticClass:"sign-up-navlink2",attrs:{"to":"/sign-in"}},[_vm._v("Sign in")])],1)]),_c('div',{staticClass:"sign-up-container16"},[_c('mobile-only-msg',{attrs:{"rootClassName":"mobile-only-msg-root-class-name"}})],1)]),_c('app-footer',{attrs:{"rootClassName":"footer-root-class-name9"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-up-container05"},[_c('span',{staticClass:"sign-up-text01"},[_vm._v("First and last name")]),_c('input',{staticClass:"sign-up-textinput input",attrs:{"type":"text","placeholder":"Enter your name"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-up-container06"},[_c('div',{staticClass:"sign-up-container07"},[_c('span',{staticClass:"sign-up-text02"},[_vm._v("Email")]),_c('span',{staticClass:"sign-up-text03"},[_vm._v("*")])]),_c('input',{staticClass:"sign-up-textinput1 input",attrs:{"type":"text","placeholder":"Enter your email address"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-up-container08"},[_c('span',{staticClass:"sign-up-text04"},[_vm._v("Password")]),_c('div',{staticClass:"sign-up-container09"},[_c('input',{staticClass:"sign-up-textinput2 input",attrs:{"type":"text","placeholder":"Enter your password"}}),_c('img',{staticClass:"sign-up-pasted-image",attrs:{"alt":"pastedImage","src":"/external/pastedimage-cf5s.svg"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-up-container10"},[_c('span',{staticClass:"sign-up-text05"},[_c('span',[_vm._v("Confirm Password")]),_c('br')]),_c('div',{staticClass:"sign-up-container11"},[_c('input',{staticClass:"sign-up-textinput3 input",attrs:{"type":"text","placeholder":"Enter your password"}}),_c('img',{staticClass:"sign-up-pasted-image1",attrs:{"alt":"pastedImage","src":"/external/pastedimage-cf5s.svg"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-up-container12"},[_c('span',{staticClass:"sign-up-text08"},[_vm._v("Location")]),_c('input',{staticClass:"sign-up-textinput4 input",attrs:{"type":"text","placeholder":"Enter your location"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-up-container13"},[_c('img',{staticClass:"sign-up-checkbox",attrs:{"alt":"pastedImage","src":"/external/pastedimage-zb1.svg"}}),_c('span',[_vm._v("I'm 16 years or older")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-up-container14"},[_c('img',{staticClass:"sign-up-checkbox1",attrs:{"alt":"pastedImage","src":"/external/pastedimage-zb1.svg"}}),_c('span',[_vm._v("I have read the Privacy and Cookie Policy")])])
}]

export { render, staticRenderFns }