<template>
  <div class="app-container">
    <header-app rootClassName="header-app-root-class-name"></header-app>
    <mobile-screen></mobile-screen>
    <div class="desktopApp">
      <div class="app-left">
        <span class="app-text">Modes</span>
        <router-link to="/app" class="app-navlink">
          <div id="soft-skills" class="app-container02 left-menu-btn">
            <div class="icon-wrapper">
              <img
                alt="pastedImage"
                src="/external/pastedimage-wg87.svg"
                class="app-pasted-image"
              />
            </div>
            <span class="app-text01">Soft Skills</span>
          </div>
        </router-link>
        <router-link to="/app-improvement" class="app-navlink1">
          <div id="improvements" class="app-container04 left-menu-btn">
            <div class="icon-wrapper">
              <div class="app-container06">
                <div class="app-container07">
                  <DangerousHTML
                    html="<svg
    width='20'
    height='19'
    viewBox='0 0 20 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 15.27L16.18 19L14.54 11.97L20 7.24L12.81 6.63L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27Z'
      fill='#5F5F5F'
    />
  </svg>
  "
                  ></DangerousHTML>
                </div>
              </div>
            </div>
            <span class="app-text02">Improvement</span>
          </div>
        </router-link>
        <router-link to="/app-match" class="app-navlink2">
          <div id="match" class="app-container08 left-menu-btn">
            <div class="icon-wrapper">
              <div>
                <div class="app-container11">
                  <DangerousHTML
                    html="<svg
    width='23'
    height='14'
    viewBox='0 0 23 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.0857 8.83324L12.5566 10.3041L21.3753 1.48532L22.8482 2.95824L12.5566 13.2499L5.92741 6.62074L7.40033 5.14782L9.61387 7.36136L11.0857 8.83219V8.83324ZM11.0878 5.8874L16.2462 0.728027L17.7149 2.19678L12.5566 7.35615L11.0878 5.8874ZM8.14303 11.778L6.67116 13.2499L0.0419922 6.62074L1.51491 5.14782L2.98678 6.61969L2.98574 6.62074L8.14303 11.778Z'
      fill='#5F5F5F'
    />
  </svg>
  "
                  ></DangerousHTML>
                </div>
              </div>
            </div>
            <span class="app-text03">Match</span>
          </div>
        </router-link>
        <router-link to="/app-games" class="app-navlink3">
          <div id="games" class="app-container12 left-menu-btn">
            <div class="icon-wrapper">
              <div>
                <div class="app-container15">
                  <DangerousHTML
                    html="<svg width='31' height='18' viewBox='0 0 31 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path d='M17.3316 14.3949H13.6779C11.786 15.9246 10.886 16.4 8.62022 16.6203C4.41169 16.6203 1 13.2086 1 9.0001C1 4.79157 4.41169 1.37988 8.62022 1.37988H22.6468' stroke='#5F5F5F'/>
  <path d='M22.4668 1.36792C26.6751 1.41753 30.0463 4.86919 29.9967 9.07743C29.9471 13.2857 26.4954 16.6569 22.2872 16.6073C20.2139 16.5829 18.1436 15.5417 16.7857 14.182' stroke='#5F5F5F'/>
  <line x1='7.76855' y1='6.93994' x2='7.76855' y2='11.8743' stroke='#5F5F5F' stroke-linecap='round'/>
  <line x1='5.64551' y1='9.6167' x2='10.5798' y2='9.6167' stroke='#5F5F5F' stroke-linecap='round'/>
  <circle cx='21.0958' cy='10.5509' r='1.34871' fill='#5F5F5F'/>
  <circle cx='24.3321' cy='7.58357' r='1.34871' fill='#5F5F5F'/>
  </svg>
  "
                  ></DangerousHTML>
                </div>
              </div>
            </div>
            <span class="app-text04">Games</span>
          </div>
        </router-link>
        <div class="app-separator"></div>
        <span class="app-text05">Settings</span>
        <router-link to="/app-profile" class="app-navlink4">
          <div id="profile" class="app-container16 left-menu-btn">
            <div class="icon-wrapper">
              <div>
                <div class="app-container19">
                  <DangerousHTML
                    html="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path fill-rule='evenodd' clip-rule='evenodd' d='M6.66699 7.50016C6.66699 9.34111 8.15938 10.8335 10.0003 10.8335C11.8413 10.8335 13.3337 9.34111 13.3337 7.50016C13.3337 5.65921 11.8413 4.16683 10.0003 4.16683C8.15938 4.16683 6.66699 5.65921 6.66699 7.50016ZM8.33366 7.50016C8.33366 8.42064 9.07985 9.16683 10.0003 9.16683C10.9208 9.16683 11.667 8.42064 11.667 7.50016C11.667 6.57969 10.9208 5.8335 10.0003 5.8335C9.07985 5.8335 8.33366 6.57969 8.33366 7.50016Z' fill='#5F5F5F'/>
  <path fill-rule='evenodd' clip-rule='evenodd' d='M10.0003 0.833496C15.0629 0.833496 19.167 4.93755 19.167 10.0002C19.167 15.0628 15.0629 19.1668 10.0003 19.1668C4.93772 19.1668 0.83366 15.0628 0.83366 10.0002C0.83366 4.93755 4.93772 0.833496 10.0003 0.833496ZM17.5003 10.0002C17.5003 11.742 16.9065 13.3452 15.9103 14.6182C14.54 12.8242 12.3784 11.6668 9.94659 11.6668C7.5463 11.6668 5.40936 12.7944 4.03658 14.5487C3.0728 13.287 2.50033 11.7105 2.50033 10.0002C2.50033 5.85803 5.85819 2.50016 10.0003 2.50016C14.1425 2.50016 17.5003 5.85803 17.5003 10.0002ZM10.0003 17.5002C11.7922 17.5002 13.4373 16.8718 14.7273 15.8234C13.6724 14.3178 11.9244 13.3335 9.94659 13.3335C7.99329 13.3335 6.26422 14.2936 5.20552 15.7675C6.50543 16.8494 8.17689 17.5002 10.0003 17.5002Z' fill='#5F5F5F'/>
  </svg>
  "
                  ></DangerousHTML>
                </div>
              </div>
            </div>
            <span class="app-text06">Profile</span>
          </div>
        </router-link>
        <div id="Notifications" class="left-menu-btn">
          <div class="icon-wrapper">
            <div>
              <div class="app-container23">
                <DangerousHTML
                  html="<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <g clip-path='url(#clip0_49_6427)'>
  <path d='M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17Z' fill='#5F5F5F'/>
  </g>
  <defs>
  <clipPath id='clip0_49_6427'>
  <rect width='24' height='24' fill='white'/>
  </clipPath>
  </defs>
  </svg>
  "
                ></DangerousHTML>
              </div>
            </div>
          </div>
          <span class="app-text07">Notifications</span>
        </div>
        <div id="Billing" class="left-menu-btn">
          <div class="icon-wrapper">
            <div>
              <div class="app-container27">
                <DangerousHTML
                  html="<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path d='M15.25 16.5H12.75C12.5938 16.5 12.5 16.625 12.5 16.75V17.25C12.5 17.4062 12.5938 17.5 12.75 17.5H15.25C15.375 17.5 15.5 17.4062 15.5 17.25V16.75C15.5 16.625 15.375 16.5 15.25 16.5ZM8.5 11.5V14.5C8.5 14.7812 8.71875 15 9 15H15C15.25 15 15.5 14.7812 15.5 14.5V11.5C15.5 11.25 15.25 11 15 11H9C8.71875 11 8.5 11.25 8.5 11.5ZM9.5 12H14.5V14H9.5V12ZM17.5312 7.0625L14.9062 4.46875C14.625 4.1875 14.25 4 13.875 4H7.46875C6.65625 4.03125 6 4.6875 6 5.53125V18.5312C6 19.3438 6.65625 20 7.46875 20H16.4688C17.3125 20 18 19.3438 18 18.5312V8.125C18 7.75 17.8125 7.34375 17.5312 7.0625ZM13.9688 5.625L16.375 8.03125H13.9688V5.625ZM16.5 18.5312H7.46875V5.53125H12.4688V8.78125C12.4688 9.1875 12.8125 9.53125 13.2188 9.53125H16.5V18.5312ZM8.75 7.5H11.25C11.375 7.5 11.5 7.40625 11.5 7.25V6.75C11.5 6.625 11.375 6.5 11.25 6.5H8.75C8.59375 6.5 8.5 6.625 8.5 6.75V7.25C8.5 7.40625 8.59375 7.5 8.75 7.5ZM8.75 9.5H11.25C11.375 9.5 11.5 9.40625 11.5 9.25V8.75C11.5 8.625 11.375 8.5 11.25 8.5H8.75C8.59375 8.5 8.5 8.625 8.5 8.75V9.25C8.5 9.40625 8.59375 9.5 8.75 9.5Z' fill='#5F5F5F'/>
  </svg>
  "
                ></DangerousHTML>
              </div>
            </div>
          </div>
          <span class="app-text08">
            <span>Billing</span>
            <br />
          </span>
        </div>
      </div>
      <div class="app-right">
        <soft-skills-tabs
          rootClassName="soft-skills-tabs-root-class-name"
        ></soft-skills-tabs>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name3"></app-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import HeaderApp from '../components/header-app'
import MobileScreen from '../components/mobile-screen'
import SoftSkillsTabs from '../components/soft-skills-tabs'
import AppFooter from '../components/footer'

export default {
  name: 'App',
  components: {
    HeaderApp,
    MobileScreen,
    DangerousHTML,
    SoftSkillsTabs,
    AppFooter,
  },
  metaInfo: {
    title: 'App - SkillQuest',
    meta: [
      {
        property: 'og:title',
        content: 'App - SkillQuest',
      },
    ],
  },
}
</script>

<style scoped>
.app-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app-left {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 262px;
  display: flex;
  position: relative;
  padding-top: var(--dl-space-space-fourunits);
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-fourunits);
  flex-direction: column;
  background-color: var(--dl-color-app-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
}
.app-text {
  color: var(--dl-color-app-primary);
  font-weight: 500;
  line-height: 175%;
}
.app-navlink {
  display: contents;
}
.app-container02 {
  text-decoration: none;
  background-color: var(--dl-color-app-primary);
}
.app-pasted-image {
  width: 18px;
  height: 17px;
}
.app-text01 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-navlink1 {
  display: contents;
}
.app-container04 {
  text-decoration: none;
}
.app-container06 {
  flex: 0 0 auto;
}
.app-container07 {
  display: contents;
}
.app-text02 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-navlink2 {
  display: contents;
}
.app-container08 {
  text-decoration: none;
}
.app-container11 {
  display: contents;
}
.app-text03 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-navlink3 {
  display: contents;
}
.app-container12 {
  text-decoration: none;
}
.app-container15 {
  display: contents;
}
.app-text04 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-separator {
  flex: 0 0 auto;
  width: 165px;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 2px;
  padding-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.app-text05 {
  color: var(--dl-color-app-primary);
  font-weight: 500;
  line-height: 175%;
}
.app-navlink4 {
  display: contents;
}
.app-container16 {
  text-decoration: none;
}
.app-container19 {
  display: contents;
}
.app-text06 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-container23 {
  display: contents;
}
.app-text07 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-container27 {
  display: contents;
}
.app-text08 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-right {
  flex: 1;
  display: flex;
  position: relative;
  justify-content: space-between;
}
</style>
