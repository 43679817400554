<template>
  <div class="app-match-container">
    <header-app rootClassName="header-app-root-class-name3"></header-app>
    <mobile-screen rootClassName="mobile-screen-root-class-name7"></mobile-screen>
    <div class="desktopApp">
      <div class="app-match-left">
        <span class="app-match-text">Modes</span>
        <router-link to="/app" class="app-match-navlink">
          <div id="soft-skills" class="app-match-container02 left-menu-btn">
            <div class="icon-wrapper">
              <div class="app-match-container04">
                <div class="app-match-container05">
                  <DangerousHTML
                    html="<svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path d='M13.6562 8.1875C13.5312 8.28125 13.3438 8.40625 13.25 8.40625C12.5 8.40625 13.2812 5.15625 13.4375 4.5C12.5312 4.6875 9.25 5.34375 8.4375 4.71875C8.46875 4.15625 9.5 3.65625 9.5 2.3125C9.5 0.90625 8.15625 0 6.75 0C5.34375 0 3.5 0.8125 3.5 2.375C3.5 3.625 4.375 4.375 4.46875 4.6875C4.46875 5.59375 0.875 4.75 0 4.53125V15.5C0.3125 15.5938 1.75 16 3.25 16C4.65625 16 6 15.5312 6 14.125C6 13.125 5 12.6875 5 11.8125C5 11.2812 5.90625 10.9688 6.53125 10.9688C7.1875 10.9688 8 11.3125 8 11.75C8 12.5 7 12.9062 7 14.1875C7 16.2812 9.53125 16 11.1875 15.7812C12.3438 15.6562 12.2188 15.6875 13.4688 15.6875C13.375 15.4062 12.5938 13.125 13.0312 12.5312C13.3438 12.125 14.25 13.5 15.6562 13.5C17.125 13.5 18 11.9062 18 10.5C18 8.71875 16.5625 6.34375 13.6562 8.1875ZM15.6562 12C14.9062 12 14.5 11 13.1875 11C12.0938 11 11.0938 11.8438 11.5312 14.25C10.9375 14.3125 8.875 14.6562 8.5 14.3125C8.15625 14 9.5 13.125 9.5 11.75C9.5 10.3125 7.9375 9.46875 6.53125 9.46875C5.0625 9.46875 3.5 10.3125 3.5 11.8125C3.5 13.4062 4.78125 14 4.40625 14.2812C3.875 14.6875 2.15625 14.4375 1.5 14.3125V6.40625C3.46875 6.75 6 6.6875 6 4.6875C6 3.6875 5 3.25 5 2.375C5 1.84375 6.21875 1.5 6.75 1.5C7.28125 1.5 8 1.75 8 2.3125C8 3.0625 6.9375 3.46875 6.9375 4.75C6.9375 6.625 9.65625 6.59375 11.5312 6.375C11.3125 8.03125 11.5625 9.90625 13.25 9.90625C14.25 9.90625 14.6875 9 15.625 9C16.1562 9 16.5 9.875 16.5 10.5C16.5 11.1875 16.0938 12 15.6562 12Z' fill='#5F5F5F'/>
  </svg>
  "
                  ></DangerousHTML>
                </div>
              </div>
            </div>
            <span class="app-match-text01">Soft Skills</span>
          </div>
        </router-link>
        <router-link to="/app-improvement" class="app-match-navlink1">
          <div id="improvements" class="app-match-container06 left-menu-btn">
            <div class="icon-wrapper">
              <div class="app-match-container08">
                <div class="app-match-container09">
                  <DangerousHTML
                    html="<svg
    width='20'
    height='19'
    viewBox='0 0 20 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 15.27L16.18 19L14.54 11.97L20 7.24L12.81 6.63L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27Z'
      fill='#5F5F5F'
    />
  </svg>
  "
                  ></DangerousHTML>
                </div>
              </div>
            </div>
            <span class="app-match-text02">Improvement</span>
          </div>
        </router-link>
        <router-link to="/app-games" class="app-match-navlink2">
          <div id="match" class="app-match-container10 left-menu-btn">
            <div class="icon-wrapper">
              <div>
                <div class="app-match-container13">
                  <DangerousHTML
                    html="<svg
    width='23'
    height='14'
    viewBox='0 0 23 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.0857 8.83324L12.5566 10.3041L21.3753 1.48532L22.8482 2.95824L12.5566 13.2499L5.92741 6.62074L7.40033 5.14782L9.61387 7.36136L11.0857 8.83219V8.83324ZM11.0878 5.8874L16.2462 0.728027L17.7149 2.19678L12.5566 7.35615L11.0878 5.8874ZM8.14303 11.778L6.67116 13.2499L0.0419922 6.62074L1.51491 5.14782L2.98678 6.61969L2.98574 6.62074L8.14303 11.778Z'
      fill='#FFFFFF'
    />
  </svg>
  "
                  ></DangerousHTML>
                </div>
              </div>
            </div>
            <span class="app-match-text03">
              <span>Match</span>
              <br />
            </span>
          </div>
        </router-link>
        <router-link to="/app-games" class="app-match-navlink3">
          <div id="games" class="app-match-container14 left-menu-btn">
            <div class="icon-wrapper">
              <div>
                <div class="app-match-container17">
                  <DangerousHTML
                    html="<svg width='31' height='18' viewBox='0 0 31 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path d='M17.3316 14.3949H13.6779C11.786 15.9246 10.886 16.4 8.62022 16.6203C4.41169 16.6203 1 13.2086 1 9.0001C1 4.79157 4.41169 1.37988 8.62022 1.37988H22.6468' stroke='#5F5F5F'/>
  <path d='M22.4668 1.36792C26.6751 1.41753 30.0463 4.86919 29.9967 9.07743C29.9471 13.2857 26.4954 16.6569 22.2872 16.6073C20.2139 16.5829 18.1436 15.5417 16.7857 14.182' stroke='#5F5F5F'/>
  <line x1='7.76855' y1='6.93994' x2='7.76855' y2='11.8743' stroke='#5F5F5F' stroke-linecap='round'/>
  <line x1='5.64551' y1='9.6167' x2='10.5798' y2='9.6167' stroke='#5F5F5F' stroke-linecap='round'/>
  <circle cx='21.0958' cy='10.5509' r='1.34871' fill='#5F5F5F'/>
  <circle cx='24.3321' cy='7.58357' r='1.34871' fill='#5F5F5F'/>
  </svg>
  "
                  ></DangerousHTML>
                </div>
              </div>
            </div>
            <span class="app-match-text06">Games</span>
          </div>
        </router-link>
        <div class="app-match-separator"></div>
        <span class="app-match-text07">Settings</span>
        <router-link to="/app-profile" class="app-match-navlink4">
          <div id="profile" class="app-match-container18 left-menu-btn">
            <div class="icon-wrapper">
              <div>
                <div class="app-match-container21">
                  <DangerousHTML
                    html="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path fill-rule='evenodd' clip-rule='evenodd' d='M6.66699 7.50016C6.66699 9.34111 8.15938 10.8335 10.0003 10.8335C11.8413 10.8335 13.3337 9.34111 13.3337 7.50016C13.3337 5.65921 11.8413 4.16683 10.0003 4.16683C8.15938 4.16683 6.66699 5.65921 6.66699 7.50016ZM8.33366 7.50016C8.33366 8.42064 9.07985 9.16683 10.0003 9.16683C10.9208 9.16683 11.667 8.42064 11.667 7.50016C11.667 6.57969 10.9208 5.8335 10.0003 5.8335C9.07985 5.8335 8.33366 6.57969 8.33366 7.50016Z' fill='#5F5F5F'/>
  <path fill-rule='evenodd' clip-rule='evenodd' d='M10.0003 0.833496C15.0629 0.833496 19.167 4.93755 19.167 10.0002C19.167 15.0628 15.0629 19.1668 10.0003 19.1668C4.93772 19.1668 0.83366 15.0628 0.83366 10.0002C0.83366 4.93755 4.93772 0.833496 10.0003 0.833496ZM17.5003 10.0002C17.5003 11.742 16.9065 13.3452 15.9103 14.6182C14.54 12.8242 12.3784 11.6668 9.94659 11.6668C7.5463 11.6668 5.40936 12.7944 4.03658 14.5487C3.0728 13.287 2.50033 11.7105 2.50033 10.0002C2.50033 5.85803 5.85819 2.50016 10.0003 2.50016C14.1425 2.50016 17.5003 5.85803 17.5003 10.0002ZM10.0003 17.5002C11.7922 17.5002 13.4373 16.8718 14.7273 15.8234C13.6724 14.3178 11.9244 13.3335 9.94659 13.3335C7.99329 13.3335 6.26422 14.2936 5.20552 15.7675C6.50543 16.8494 8.17689 17.5002 10.0003 17.5002Z' fill='#5F5F5F'/>
  </svg>
  "
                  ></DangerousHTML>
                </div>
              </div>
            </div>
            <span class="app-match-text08">Profile</span>
          </div>
        </router-link>
        <div id="Notifications" class="left-menu-btn">
          <div class="icon-wrapper">
            <div>
              <div class="app-match-container25">
                <DangerousHTML
                  html="<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <g clip-path='url(#clip0_49_6427)'>
  <path d='M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17Z' fill='#5F5F5F'/>
  </g>
  <defs>
  <clipPath id='clip0_49_6427'>
  <rect width='24' height='24' fill='white'/>
  </clipPath>
  </defs>
  </svg>
  "
                ></DangerousHTML>
              </div>
            </div>
          </div>
          <span class="app-match-text09">Notifications</span>
        </div>
        <div id="Billing" class="left-menu-btn">
          <div class="icon-wrapper">
            <div>
              <div class="app-match-container29">
                <DangerousHTML
                  html="<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path d='M15.25 16.5H12.75C12.5938 16.5 12.5 16.625 12.5 16.75V17.25C12.5 17.4062 12.5938 17.5 12.75 17.5H15.25C15.375 17.5 15.5 17.4062 15.5 17.25V16.75C15.5 16.625 15.375 16.5 15.25 16.5ZM8.5 11.5V14.5C8.5 14.7812 8.71875 15 9 15H15C15.25 15 15.5 14.7812 15.5 14.5V11.5C15.5 11.25 15.25 11 15 11H9C8.71875 11 8.5 11.25 8.5 11.5ZM9.5 12H14.5V14H9.5V12ZM17.5312 7.0625L14.9062 4.46875C14.625 4.1875 14.25 4 13.875 4H7.46875C6.65625 4.03125 6 4.6875 6 5.53125V18.5312C6 19.3438 6.65625 20 7.46875 20H16.4688C17.3125 20 18 19.3438 18 18.5312V8.125C18 7.75 17.8125 7.34375 17.5312 7.0625ZM13.9688 5.625L16.375 8.03125H13.9688V5.625ZM16.5 18.5312H7.46875V5.53125H12.4688V8.78125C12.4688 9.1875 12.8125 9.53125 13.2188 9.53125H16.5V18.5312ZM8.75 7.5H11.25C11.375 7.5 11.5 7.40625 11.5 7.25V6.75C11.5 6.625 11.375 6.5 11.25 6.5H8.75C8.59375 6.5 8.5 6.625 8.5 6.75V7.25C8.5 7.40625 8.59375 7.5 8.75 7.5ZM8.75 9.5H11.25C11.375 9.5 11.5 9.40625 11.5 9.25V8.75C11.5 8.625 11.375 8.5 11.25 8.5H8.75C8.59375 8.5 8.5 8.625 8.5 8.75V9.25C8.5 9.40625 8.59375 9.5 8.75 9.5Z' fill='#5F5F5F'/>
  </svg>
  "
                ></DangerousHTML>
              </div>
            </div>
          </div>
          <span class="app-match-text10">
            <span>Billing</span>
            <br />
          </span>
        </div>
      </div>
      <div class="app-match-middle">
        <div class="app-match-body">
          <div class="app-match-container30">
            <div class="app-match-buttons">
              <img
                alt="pastedImage"
                src="/external/pastedimage-0b1-200h.png"
                class="app-match-pasted-image"
              />
              <div class="app-match-container31">
                <span class="app-match-text13">My Soft Skills Pattern</span>
                <span class="app-match-text14">
                  Your pattern is strong in Cognitive Abilities with an emphasis
                  on Memory, Manual Dexterity and Problem-Solving. Those
                  strengths, along with strengths in Coordinating with others, and
                  Stress Management, are a great fit for positions like criminal
                  justice law, piloting a commercial aircraft and an Engineering
                  Manager.
                </span>
              </div>
            </div>
            <div class="app-match-container32">
              <span class="app-match-text15">My Soft Skills Pattern</span>
              <app-select
                text="Financial Analyst"
                rootClassName="select-root-class-name7"
              ></app-select>
            </div>
            <div class="app-match-container33">
              <img
                alt="pastedImage"
                src="/external/pastedimage-yi-1500w.png"
                class="app-match-pasted-image1"
              />
            </div>
          </div>
          <div class="app-match-container34">
            <span class="app-match-text16">Match Suggestions</span>
            <div class="app-match-match-suggestions-wrapper">
              <div class="app-match-card-profession shadow">
                <div class="app-match-container35">
                  <img
                    alt="pastedImage"
                    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a207366c-eb3b-44aa-832a-3289025cd97f/c5e6cff0-230f-4f9f-a899-49dbed4f2d20?org_if_sml=140537"
                    class="app-match-pasted-image2"
                  />
                </div>
                <div class="app-match-container36">
                  <span class="app-match-text17">Financial Analyist</span>
                  <ul class="list">
                    <li class="app-match-li list-item">
                      <span class="app-match-text18">
                        Strong in Cognitive Skills
                      </span>
                    </li>
                    <li class="app-match-li1 list-item">
                      <span class="app-match-text19">
                        Less focused on Basic Skills
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="app-match-card-profession1 shadow">
                <div class="app-match-container37">
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-byh-200h.png"
                    class="app-match-pasted-image3"
                  />
                </div>
                <div class="app-match-container38">
                  <span class="app-match-text20">
                    <span>UX Designer</span>
                    <br />
                  </span>
                  <ul class="list">
                    <li class="app-match-li2 list-item">
                      <span class="app-match-text23">Strong in Basic Skills</span>
                    </li>
                    <li class="app-match-li3 list-item">
                      <span class="app-match-text24">
                        Less focused on Emotional Skills
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <span class="app-match-text25">Job Offers</span>
            <div class="app-match-card-profession2 shadow">
              <div class="app-match-container39">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-zmi-200h.png"
                  class="app-match-hp-logo"
                />
              </div>
              <div class="app-match-container40">
                <span class="app-match-text26">Full Stack Developer</span>
                <ul class="list">
                  <li class="app-match-li4 list-item">
                    <span class="app-match-text27">Based in NYC</span>
                  </li>
                  <li class="app-match-li5 list-item">
                    <span class="app-match-text28">
                      Need exceptional Cognitive Soft Skills
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="app-match-card-profession3 shadow">
              <div class="app-match-container41">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-fvk5-200h.png"
                  class="app-match-air-bn-b"
                />
              </div>
              <div class="app-match-container42">
                <span class="app-match-text29">Marketing Specialist</span>
                <ul class="list">
                  <li class="app-match-li6 list-item">
                    <span class="app-match-text30">Remote, from anywhere</span>
                  </li>
                  <li class="app-match-li7 list-item">
                    <span class="app-match-text31">
                      Requires strong Emotional Skills
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="app-match-container44">
          <DangerousHTML
            html="<script src='https://html-editor-teleport-dev.vercel.app/preview/54/index.js'></script>"
          ></DangerousHTML>
        </div>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name6"></app-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import HeaderApp from '../components/header-app'
import MobileScreen from '../components/mobile-screen'
import AppSelect from '../components/select'
import AppFooter from '../components/footer'

export default {
  name: 'AppMatch',
  components: {
    HeaderApp,
    MobileScreen,
    DangerousHTML,
    AppSelect,
    AppFooter,
  },
  metaInfo: {
    title: 'App-Match - SkillQuest',
    meta: [
      {
        property: 'og:title',
        content: 'App-Match - SkillQuest',
      },
    ],
  },
}
</script>

<style scoped>
.app-match-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app-match-left {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 262px;
  height: auto;
  display: flex;
  position: relative;
  padding-top: var(--dl-space-space-fourunits);
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-fourunits);
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
}
.app-match-text {
  color: var(--dl-color-app-primary);
  font-weight: 500;
  line-height: 175%;
}
.app-match-navlink {
  display: contents;
}
.app-match-container02 {
  text-decoration: none;
}
.app-match-container04 {
  flex: 0 0 auto;
}
.app-match-container05 {
  display: contents;
}
.app-match-text01 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-match-navlink1 {
  display: contents;
}
.app-match-container06 {
  text-decoration: none;
}
.app-match-container08 {
  flex: 0 0 auto;
}
.app-match-container09 {
  display: contents;
}
.app-match-text02 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-match-navlink2 {
  display: contents;
}
.app-match-container10 {
  text-decoration: none;
  background-color: var(--dl-color-app-primary);
}
.app-match-container13 {
  display: contents;
}
.app-match-text03 {
  color: var(--dl-color-app-white);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-match-navlink3 {
  display: contents;
}
.app-match-container14 {
  text-decoration: none;
}
.app-match-container17 {
  display: contents;
}
.app-match-text06 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-match-separator {
  flex: 0 0 auto;
  width: 165px;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 2px;
  padding-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.app-match-text07 {
  color: var(--dl-color-app-primary);
  font-weight: 500;
  line-height: 175%;
}
.app-match-navlink4 {
  display: contents;
}
.app-match-container18 {
  text-decoration: none;
}
.app-match-container21 {
  display: contents;
}
.app-match-text08 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-match-container25 {
  display: contents;
}
.app-match-text09 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-match-container29 {
  display: contents;
}
.app-match-text10 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-match-middle {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.app-match-body {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-right: 70px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
}
.app-match-container30 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.app-match-buttons {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.app-match-pasted-image {
  width: 193px;
  height: 193px;
}
.app-match-container31 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.app-match-text13 {
  color: rgba(5, 47, 156, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-match-text14 {
  color: rgba(5, 47, 156, 1);
  width: 474px;
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.app-match-container32 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.app-match-text15 {
  color: rgba(5, 47, 156, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-match-container33 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.app-match-pasted-image1 {
  width: 100%;
}
.app-match-container34 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 340px;
  height: 100%;
  display: flex;
  margin-top: 70px;
  flex-direction: column;
}
.app-match-text16 {
  color: rgba(5, 47, 156, 1);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-match-match-suggestions-wrapper {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
}
.app-match-card-profession {
  height: 140px;
}
.app-match-container35 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.app-match-pasted-image2 {
  width: 90px;
  height: 90px;
}
.app-match-container36 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-match-text17 {
  color: rgba(5, 47, 156, 1);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-match-li {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-match-text18 {
  font-weight: 300;
}
.app-match-li1 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-match-text19 {
  font-weight: 300;
}
.app-match-card-profession1 {
  height: 140px;
}
.app-match-container37 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.app-match-pasted-image3 {
  width: 90px;
  height: 90px;
}
.app-match-container38 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-match-text20 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-match-li2 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-match-text23 {
  font-weight: 300;
}
.app-match-li3 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-match-text24 {
  font-weight: 300;
}
.app-match-text25 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-match-card-profession2 {
  height: 140px;
}
.app-match-container39 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.app-match-hp-logo {
  width: 90px;
  height: 90px;
}
.app-match-container40 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-match-text26 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-match-li4 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-match-text27 {
  font-weight: 300;
}
.app-match-li5 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-match-text28 {
  font-weight: 300;
}
.app-match-card-profession3 {
  height: 140px;
}
.app-match-container41 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.app-match-air-bn-b {
  width: 90px;
  height: 90px;
}
.app-match-container42 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-match-text29 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-match-li6 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-match-text30 {
  font-weight: 300;
}
.app-match-li7 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-match-text31 {
  font-weight: 300;
}
.app-match-container44 {
  display: contents;
}
</style>
