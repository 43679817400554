<template>
  <div class="app-coach-profile-container">
    <header-app rootClassName="header-app-root-class-name5"></header-app>
    <mobile-screen rootClassName="mobile-screen-root-class-name"></mobile-screen>
    <div class="desktopApp">
      <div class="app-coach-profile-left">
        <div id="profile" class="app-coach-profile-container02 left-menu-btn">
          <div class="icon-wrapper">
            <div>
              <div class="app-coach-profile-container05">
                <DangerousHTML
                  html="<svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M6.66699 7.50016C6.66699 9.34111 8.15938 10.8335 10.0003 10.8335C11.8413 10.8335 13.3337 9.34111 13.3337 7.50016C13.3337 5.65921 11.8413 4.16683 10.0003 4.16683C8.15938 4.16683 6.66699 5.65921 6.66699 7.50016ZM8.33366 7.50016C8.33366 8.42064 9.07985 9.16683 10.0003 9.16683C10.9208 9.16683 11.667 8.42064 11.667 7.50016C11.667 6.57969 10.9208 5.8335 10.0003 5.8335C9.07985 5.8335 8.33366 6.57969 8.33366 7.50016Z'
      fill='#FFFFFF'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M10.0003 0.833496C15.0629 0.833496 19.167 4.93755 19.167 10.0002C19.167 15.0628 15.0629 19.1668 10.0003 19.1668C4.93772 19.1668 0.83366 15.0628 0.83366 10.0002C0.83366 4.93755 4.93772 0.833496 10.0003 0.833496ZM17.5003 10.0002C17.5003 11.742 16.9065 13.3452 15.9103 14.6182C14.54 12.8242 12.3784 11.6668 9.94659 11.6668C7.5463 11.6668 5.40936 12.7944 4.03658 14.5487C3.0728 13.287 2.50033 11.7105 2.50033 10.0002C2.50033 5.85803 5.85819 2.50016 10.0003 2.50016C14.1425 2.50016 17.5003 5.85803 17.5003 10.0002ZM10.0003 17.5002C11.7922 17.5002 13.4373 16.8718 14.7273 15.8234C13.6724 14.3178 11.9244 13.3335 9.94659 13.3335C7.99329 13.3335 6.26422 14.2936 5.20552 15.7675C6.50543 16.8494 8.17689 17.5002 10.0003 17.5002Z'
      fill='#FFFFFF'
    />
  </svg>
  "
                ></DangerousHTML>
              </div>
            </div>
          </div>
          <span class="app-coach-profile-text">Profile</span>
        </div>
        <div id="Notifications" class="left-menu-btn">
          <div class="icon-wrapper">
            <div>
              <div class="app-coach-profile-container09">
                <DangerousHTML
                  html="<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <g clip-path='url(#clip0_49_6427)'>
  <path d='M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17Z' fill='#5F5F5F'/>
  </g>
  <defs>
  <clipPath id='clip0_49_6427'>
  <rect width='24' height='24' fill='white'/>
  </clipPath>
  </defs>
  </svg>
  "
                ></DangerousHTML>
              </div>
            </div>
          </div>
          <span class="app-coach-profile-text01">Notifications</span>
        </div>
        <div id="Billing" class="left-menu-btn">
          <div class="icon-wrapper">
            <div>
              <div class="app-coach-profile-container13">
                <DangerousHTML
                  html="<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path d='M15.25 16.5H12.75C12.5938 16.5 12.5 16.625 12.5 16.75V17.25C12.5 17.4062 12.5938 17.5 12.75 17.5H15.25C15.375 17.5 15.5 17.4062 15.5 17.25V16.75C15.5 16.625 15.375 16.5 15.25 16.5ZM8.5 11.5V14.5C8.5 14.7812 8.71875 15 9 15H15C15.25 15 15.5 14.7812 15.5 14.5V11.5C15.5 11.25 15.25 11 15 11H9C8.71875 11 8.5 11.25 8.5 11.5ZM9.5 12H14.5V14H9.5V12ZM17.5312 7.0625L14.9062 4.46875C14.625 4.1875 14.25 4 13.875 4H7.46875C6.65625 4.03125 6 4.6875 6 5.53125V18.5312C6 19.3438 6.65625 20 7.46875 20H16.4688C17.3125 20 18 19.3438 18 18.5312V8.125C18 7.75 17.8125 7.34375 17.5312 7.0625ZM13.9688 5.625L16.375 8.03125H13.9688V5.625ZM16.5 18.5312H7.46875V5.53125H12.4688V8.78125C12.4688 9.1875 12.8125 9.53125 13.2188 9.53125H16.5V18.5312ZM8.75 7.5H11.25C11.375 7.5 11.5 7.40625 11.5 7.25V6.75C11.5 6.625 11.375 6.5 11.25 6.5H8.75C8.59375 6.5 8.5 6.625 8.5 6.75V7.25C8.5 7.40625 8.59375 7.5 8.75 7.5ZM8.75 9.5H11.25C11.375 9.5 11.5 9.40625 11.5 9.25V8.75C11.5 8.625 11.375 8.5 11.25 8.5H8.75C8.59375 8.5 8.5 8.625 8.5 8.75V9.25C8.5 9.40625 8.59375 9.5 8.75 9.5Z' fill='#5F5F5F'/>
  </svg>
  "
                ></DangerousHTML>
              </div>
            </div>
          </div>
          <span class="app-coach-profile-text02">
            <span>Billing</span>
            <br />
          </span>
        </div>
        <div class="app-coach-profile-container14">
          <router-link
            to="/app-coach-profile-teams"
            class="app-coach-profile-navlink"
          >
            <div id="Billing" class="app-coach-profile-container15 left-menu-btn">
              <div class="icon-wrapper">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-lm2r.svg"
                  class="app-coach-profile-pasted-image"
                />
              </div>
              <span class="app-coach-profile-text05">
                <span>Teams</span>
                <br />
              </span>
            </div>
          </router-link>
          <div id="Billing" class="app-coach-profile-container17 left-menu-btn">
            <span class="app-coach-profile-text08">
              <span>Manage</span>
              <br />
            </span>
          </div>
        </div>
        <div id="Billing" class="left-menu-btn">
          <span class="app-coach-profile-text11">
            <span>Soft Skills Groups</span>
            <br />
          </span>
        </div>
        <div id="Billing" class="left-menu-btn">
          <span class="app-coach-profile-text14">
            <span>Define Profession Pattern</span>
            <br />
          </span>
        </div>
      </div>
      <div class="app-coach-profile-right">
        <div class="app-coach-profile-profile">
          <img
            alt="pastedImage"
            src="/external/pastedimage-v0vs.svg"
            class="app-coach-profile-pasted-image1"
          />
          <img
            alt="pastedImage"
            src="/external/pastedimage-lwm-200h.png"
            class="app-coach-profile-pasted-image2"
          />
        </div>
        <app-value></app-value>
        <app-value label="Name" value="Betty Washington"></app-value>
        <app-value
          label="Profession/Field of Study"
          value="Full Stack Designer"
        ></app-value>
        <app-value label="Location" value="San Fransisco, CA, USA"></app-value>
        <app-value label="Birthday" value="05/20/1999"></app-value>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name11"></app-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import HeaderApp from '../components/header-app'
import MobileScreen from '../components/mobile-screen'
import AppValue from '../components/value'
import AppFooter from '../components/footer'

export default {
  name: 'AppCoachProfile',
  components: {
    HeaderApp,
    MobileScreen,
    DangerousHTML,
    AppValue,
    AppFooter,
  },
  metaInfo: {
    title: 'App-Coach-Profile - SkillQuest',
    meta: [
      {
        property: 'og:title',
        content: 'App-Coach-Profile - SkillQuest',
      },
    ],
  },
}
</script>

<style scoped>
.app-coach-profile-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app-coach-profile-left {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 262px;
  height: auto;
  display: flex;
  position: relative;
  padding-top: var(--dl-space-space-fourunits);
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-fourunits);
  flex-direction: column;
  background-color: var(--dl-color-app-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
}
.app-coach-profile-container02 {
  background-color: var(--dl-color-app-primary);
}
.app-coach-profile-container05 {
  display: contents;
}
.app-coach-profile-text {
  color: var(--dl-color-app-white);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-container09 {
  display: contents;
}
.app-coach-profile-text01 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-container13 {
  display: contents;
}
.app-coach-profile-text02 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-container14 {
  display: flex;
  flex-direction: column;
}
.app-coach-profile-navlink {
  display: contents;
}
.app-coach-profile-container15 {
  height: 44px;
  text-decoration: none;
  background-color: var(--dl-color-app-primary);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.app-coach-profile-pasted-image {
  width: 25px;
  height: 26px;
}
.app-coach-profile-text05 {
  color: var(--dl-color-app-white);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-container17 {
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.app-coach-profile-text08 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-text11 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-text14 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-right {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: flex-start;
}
.app-coach-profile-profile {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-app-lightblue);
}
.app-coach-profile-pasted-image1 {
  right: 0px;
  width: 30px;
  bottom: 0px;
  height: 30px;
  position: absolute;
}
.app-coach-profile-pasted-image2 {
  width: 200px;
  height: 200px;
}
</style>
