<template>
  <div class="footer-footer section-full" v-bind:class="rootClassName">
    <div class="footer-container">
      <div class="footer-navlinks">
        <router-link
          to="/privacy-policy"
          class="footer-privacy-policy footer-links"
        >
          Privacy Policy
        </router-link>
        <span class="footer-separator">|</span>
        <router-link
          to="/cookie-policy"
          class="footer-cookie-policy footer-links"
        >
          Cookie Policy
        </router-link>
        <span class="footer-separator2">|</span>
<!--
        <router-link to="/sign-in" class="footer-cookie-policy1 footer-links">
          Sign In
        </router-link>
        <span class="footer-separator3">|</span>
-->
        <a :href="externalDashboard" target="_blank" class="footer-cookie-policy1 footer-links"> App </a>
<!--        This was the old App link
        <router-link to="/app" class="footer-cookie-policy4 footer-links"> App </router-link>
-->
      </div>
      <div class="footer-rights">
        <span class="footer-copyright">
          © 2023 All rights reserved, SkillQuest
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
  return { externalDashboard: 'https://app.skillquest.ai/Dashboard/',};
  },
  name: 'Footer',
  props: {
    rootClassName: String,
  },
}
</script>

<style scoped>
.footer-footer {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-app-white);
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.footer-container {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.footer-navlinks {
  gap: var(--dl-space-space-unit);
  flex: 1;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
}
.footer-privacy-policy {
  text-decoration: none;
}
.footer-separator {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: right;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 42px;
  font-stretch: normal;
  text-decoration: none;
}
.footer-separator1 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: right;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 42px;
  font-stretch: normal;
  text-decoration: none;
}
.footer-cookie-policy {
  text-decoration: none;
}
.footer-separator2 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: right;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 42px;
  font-stretch: normal;
  text-decoration: none;
}
.footer-cookie-policy1 {
  text-decoration: none;
}
.footer-separator3 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: right;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 42px;
  font-stretch: normal;
  text-decoration: none;
}
.footer-cookie-policy2 {
  text-decoration: none;
}
.footer-separator4 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: right;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 42px;
  font-stretch: normal;
  text-decoration: none;
}
.footer-cookie-policy3 {
  text-decoration: none;
}
.footer-separator5 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Regular;
  text-align: right;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 42px;
  font-stretch: normal;
  text-decoration: none;
}
.footer-cookie-policy4 {
  text-decoration: none;
}
.footer-rights {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-copyright {
  font-size: 14px;
}

















@media(max-width: 767px) {
  .footer-footer {
    gap: var(--dl-space-space-twounits);
    align-items: center;
  }
  .footer-container {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .footer-footer {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .footer-navlinks {
    flex-wrap: wrap;
  }
}
</style>
