<template>
  <div class="select-container" v-bind:class="rootClassName">
    <span class="select-text">{{ text }}</span>
    <img
      alt="pastedImage"
      src="/external/pastedimage-jrgt.svg"
      class="select-pasted-image"
    />
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    rootClassName: String,
    text: {
      type: String,
      default: 'Cognitive Flexibility',
    },
  },
}
</script>

<style scoped>
.select-container {
  flex: 0 0 auto;
  width: 260px;
  height: 42px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.select-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.select-pasted-image {
  width: 12px;
  height: 8px;
}
.select-root-class-name {
  margin-left: var(--dl-space-space-oneandhalfunits);
}

.select-root-class-name2 {
  width: 460px;
  margin-left: var(--dl-space-space-threeunits);
}
.select-root-class-name3 {
  width: 460px;
  margin-left: var(--dl-space-space-threeunits);
}
.select-root-class-name4 {
  width: 460px;
  margin-left: var(--dl-space-space-threeunits);
}

.select-root-class-name6 {
  width: 460px;
  margin-left: var(--dl-space-space-threeunits);
}


.select-root-class-name9 {
  align-self: flex-start;
}

</style>
