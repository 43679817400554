<template>
  <div class="tabs-buttons" v-bind:class="rootClassName">
    <button class="tabs-button tab-all-selected tab-images">
      <span>All</span>
    </button>
    <button class="tab-images"><span>Cognitive Abilities</span></button>
    <button class="tab-images">
      <span class="tabs-text02">
        <span>Basic Skills</span>
        <br />
      </span>
    </button>
    <button class="tab-images">
      <span>
        <span>Cross-Functional Skills</span>
        <br />
      </span>
    </button>
    <button class="tab-images tabs-button4">
      <span class="tabs-text08">
        <span>Emotional Skills</span>
        <br />
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    rootClassName: String,
  },
}
</script>

<style scoped>
.tabs-buttons {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 70px;
  margin-bottom: 70px;
  flex-direction: row;
  justify-content: center;
}
.tabs-button {
  width: 60px;
}
.tabs-text02 {
  align-self: center;
}
.tabs-text08 {
  color: var(--dl-color-app-white);
  font-style: normal;
  font-weight: 600;
}

.tabs-root-class-name1 {
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.tabs-root-class-name2 {
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.tabs-root-class-name3 {
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
}
@media(max-width: 991px) {
  .tabs-button4 {
    align-items: center;
    justify-content: center;
  }
  .tabs-text08 {
    font-style: normal;
    text-align: center;
    font-weight: 600;
  }
}
</style>
