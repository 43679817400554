<template>
  <div class="sign-up-container">
    <app-header rootClassName="header-root-class-name5"></app-header>
    <div class="sign-up-container01">
      <div class="sign-up-container02">
        <app-h1 H1="Sign Up" rootClassName="h1-root-class-name6"></app-h1>
        <span class="sign-up-text">We&apos;re glad you&apos;re here</span>
      </div>
      <div class="sign-up-container03">
        <div class="sign-up-container04 section">
          <div class="sign-up-container05">
            <span class="sign-up-text01">First and last name</span>
            <input type="text" placeholder="Enter your name" class="sign-up-textinput input"/>
          </div>
          <div class="sign-up-container06">
            <div class="sign-up-container07">
              <span class="sign-up-text02">Email</span>
              <span class="sign-up-text03">*</span>
            </div>
            <input type="text" placeholder="Enter your email address" class="sign-up-textinput1 input"/>
          </div>
          <div class="sign-up-container08">
            <span class="sign-up-text04">Password</span>
            <div class="sign-up-container09">
              <input type="text" placeholder="Enter your password" class="sign-up-textinput2 input"/>
              <img alt="pastedImage" src="/external/pastedimage-cf5s.svg" class="sign-up-pasted-image"/>
            </div>
          </div>
          <div class="sign-up-container10">
            <span class="sign-up-text05"><span>Confirm Password</span><br/></span>
            <div class="sign-up-container11">
              <input type="text" placeholder="Enter your password" class="sign-up-textinput3 input"/>
              <img alt="pastedImage" src="/external/pastedimage-cf5s.svg" class="sign-up-pasted-image1"/>
            </div>
          </div>
          <div class="sign-up-container12">
            <span class="sign-up-text08">Location</span>
            <input type="text" placeholder="Enter your location" class="sign-up-textinput4 input"/>
          </div>
          <div class="sign-up-container13">
            <img alt="pastedImage" src="/external/pastedimage-zb1.svg" class="sign-up-checkbox"/>
            <span>I&apos;m 16 years or older</span>
          </div>
          <div class="sign-up-container14">
            <img alt="pastedImage" src="/external/pastedimage-zb1.svg" class="sign-up-checkbox1"/>
            <span>I have read the Privacy and Cookie Policy</span>
          </div>
          <router-link to="/app-coach-profile-teams" class="sign-up-navlink button"><span><span>ENTER</span><br/></span></router-link>
        </div>
        <div class="sign-up-container15">
          <router-link to="/sign-in" class="sign-up-navlink1">Already have an account?</router-link>
          <router-link to="/sign-in" class="sign-up-navlink2">Sign in</router-link>
        </div>
      </div>
      <div class="sign-up-container16">
        <mobile-only-msg rootClassName="mobile-only-msg-root-class-name"></mobile-only-msg>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name9"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppH1 from '../components/h1'
import MobileOnlyMsg from '../components/mobile-only-msg'
import AppFooter from '../components/footer'

export default {
  name: 'SignUp',
  components: {
    AppHeader,
    AppH1,
    MobileOnlyMsg,
    AppFooter,
  },
  metaInfo: {
    title: 'Sign-up - SkillQuest',
    meta: [{ property:'og:title', content:'Sign-up - SkillQuest' }],
  },
}
</script>

<style scoped>
.sign-up-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.sign-up-container01 {
  gap: 30px;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: flex-end;
}
.sign-up-container02 {
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.sign-up-text {
  color: var(--dl-color-app-neutral);
  font-size: 14px;
}
.sign-up-container03 {
  gap: 30px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.sign-up-container04 {
  gap: 20px;
  flex: 1;
  display: flex;
  max-width: 460px;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.sign-up-container05 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sign-up-text01 {
  font-style: normal;
  font-weight: 500;
}
.sign-up-textinput {
  width: 100%;
}
.sign-up-container06 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sign-up-container07 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.sign-up-text02 {
  font-style: normal;
  font-weight: 500;
}
.sign-up-text03 {
  color: var(--dl-color-app-orange);
  font-style: normal;
  font-weight: 500;
}
.sign-up-textinput1 {
  width: 100%;
}
.sign-up-container08 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sign-up-text04 {
  font-style: normal;
  font-weight: 500;
}
.sign-up-container09 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.sign-up-textinput2 {
  width: 100%;
}
.sign-up-pasted-image {
  top: 0px;
  right: var(--dl-space-space-unit);
  width: 21px;
  bottom: 0px;
  height: 16px;
  margin: auto;
  position: absolute;
}
.sign-up-container10 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sign-up-text05 {
  font-style: normal;
  font-weight: 500;
}
.sign-up-container11 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.sign-up-textinput3 {
  width: 100%;
}
.sign-up-pasted-image1 {
  top: 0px;
  right: var(--dl-space-space-unit);
  width: 21px;
  bottom: 0px;
  height: 16px;
  margin: auto;
  position: absolute;
}
.sign-up-container12 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sign-up-text08 {
  font-style: normal;
  font-weight: 500;
}
.sign-up-textinput4 {
  width: 100%;
}
.sign-up-container13 {
  gap: 8px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.sign-up-checkbox {
  width: 30px;
  height: 30px;
}
.sign-up-container14 {
  gap: 8px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.sign-up-checkbox1 {
  width: 30px;
  height: 30px;
}
.sign-up-navlink {
  color: var(--dl-color-app-white);
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-app-primary);
}
.sign-up-container15 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-up-navlink1 {
  font-size: 14px;
  align-self: center;
  text-decoration: none;
}
.sign-up-navlink2 {
  color: var(--dl-color-app-orange);
  font-size: 14px;
  align-self: center;
  text-decoration: none;
}
.sign-up-container16 {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  justify-content: flex-end;
}
@media(max-width: 991px) {
  .sign-up-container02 {
    gap: 5px;
    align-self: center;
    align-items: center;
  }
  .sign-up-text {
    color: var(--dl-color-app-neutral);
  }
}
</style>
