<template>
  <div class="button-with-icon-container" v-bind:class="rootClassName">
    <img alt="pastedImage" :src="icon" class="button-with-icon-plus" />
    <span class="button-with-icon-text">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'ButtonWithIcon',
  props: {
    label: {
      type: String,
      default: 'Create Team',
    },
    rootClassName: String,
    icon: {
      type: String,
      default: '/external/pastedimage-qovk.svg',
    },
  },
}
</script>

<style scoped>
.button-with-icon-container {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 200px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius10);
  flex-direction: row;
  background-color: var(--dl-color-app-primary);
}
.button-with-icon-plus {
  width: 24px;
  height: 24px;
}
.button-with-icon-text {
  color: var(--dl-color-app-white);
}



</style>
