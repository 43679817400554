<template>
  <div class="improvement-middle">
    <div v-if="tab === 1" class="improvement-tabs-all">
      <div class="improvement-container tab-selected tab"><span>All</span></div>
      <div @click="tab = 2" class="improvement-container01 tab">
        <span class="improvement-text001">Groups</span>
      </div>
      <div @click="tab = 3" class="improvement-container02 tab">
        <span class="improvement-text002">List</span>
      </div>
      <div @click="tab = 4" class="improvement-container03 tab">
        <span class="improvement-text003">About</span>
      </div>
    </div>
    <div v-if="tab === 2" class="improvement-tabs-groups">
      <div @click="tab = 1" class="improvement-container04 tab">
        <span class="improvement-text004">All</span>
      </div>
      <div class="improvement-container05 tab-selected tab">
        <span>Groups</span>
      </div>
      <div @click="tab = 3" class="improvement-container06 tab">
        <span class="improvement-text006">List</span>
      </div>
      <div @click="tab = 4" class="improvement-container07 tab">
        <span class="improvement-text007">About</span>
      </div>
    </div>
    <div v-if="tab === 3" class="improvement-tabs-list">
      <div @click="tab = 1" class="improvement-container08 tab">
        <span class="improvement-text008">All</span>
      </div>
      <div @click="tab = 2" class="improvement-container09 tab">
        <span class="improvement-text009">Groups</span>
      </div>
      <div class="improvement-container10 tab-selected tab">
        <span>List</span>
      </div>
      <div @click="tab = 4" class="improvement-container11 tab">
        <span class="improvement-text011">About</span>
      </div>
    </div>
    <div v-if="tab === 4" class="improvement-tabs-about">
      <div @click="tab = 1" class="improvement-container12 tab">
        <span class="improvement-text012">All</span>
      </div>
      <div @click="tab = 2" class="improvement-container13 tab">
        <span class="improvement-text013">Groups</span>
      </div>
      <div @click="tab = 3" class="improvement-container14 tab">
        <span class="improvement-text014">List</span>
      </div>
      <div class="improvement-container15 tab-selected tab">
        <span>About</span>
      </div>
    </div>
    <div v-if="tab === 1" class="improvement-body-all">
      <div class="improvement-container16">
        <app-tabs rootClassName="tabs-root-class-name3"></app-tabs>
        <img
          alt="pastedImage"
          src="/external/pastedimage-ypok-800w.png"
          class="improvement-pasted-image"
        />
        <div class="improvement-period">
          <div class="improvement-container17">
            <calendar-pick></calendar-pick>
            <calendar-pick date="January 24, 2019"></calendar-pick>
          </div>
        </div>
      </div>
      <div class="improvement-right">
        <div class="improvement-container18">
          <div class="improvement-container19">
            <span class="improvement-text016">
              <span>
                Improvement in
                <span v-html="raw0qxt"></span>
              </span>
              <br class="improvement-text018" />
              <span class="improvement-text019">Goal-Setting</span>
              <span>over the last 30 days</span>
            </span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-e61-200h.png"
              class="improvement-pasted-image01"
            />
          </div>
          <div class="improvement-container20">
            <span class="improvement-text021">
              <span>
                % of available points in
                <span v-html="rawp4vk"></span>
              </span>
              <span class="improvement-text023">Mental Flexibility </span>
              <span>in Cell to Singularity</span>
            </span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-x4x-200h.png"
              class="improvement-pasted-image02"
            />
          </div>
        </div>
        <div class="improvement-container21">
          <div class="improvement-container22">
            <span class="improvement-text025">Soft skill improved most</span>
            <span class="improvement-text026">Monitoring Self &amp; Others</span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-5s-200h.png"
              class="improvement-pasted-image03"
            />
            <span class="improvement-text027">
              <span>out of 100 points</span>
              <br />
            </span>
            <span class="improvement-text030">in the last 7 days</span>
          </div>
          <div class="improvement-container23">
            <span class="improvement-text031">Soft skill improved least</span>
            <span>Memory Span</span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-txbl-200h.png"
              class="improvement-pasted-image04"
            />
            <span class="improvement-text033">out of 250 points</span>
            <span class="improvement-text034">in the last 7 days</span>
          </div>
        </div>
        <span class="improvement-text035">Game Suggestions</span>
        <div class="shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-s64n-200h.png"
            class="improvement-pasted-image05"
          />
          <div class="improvement-container25">
            <span class="improvement-text036">Cities Skylines</span>
            <ul class="list">
              <li class="improvement-li list-item">
                <span class="improvement-text037">Self-discipline</span>
              </li>
              <li class="improvement-li01 list-item">
                <span class="improvement-text038">Logical Reasoning</span>
              </li>
              <li class="improvement-li02 list-item">
                <span class="improvement-text039">
                  Mgmnt of Material Resources
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="improvement-container26 shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-eqee-200h.png"
            class="improvement-pasted-image06"
          />
          <div class="improvement-container27">
            <span class="improvement-text040">Cell to Singularity</span>
            <ul class="list">
              <li class="improvement-li03 list-item">
                <span class="improvement-text041">Mental Flexibility</span>
              </li>
              <li class="improvement-li04 list-item">
                <span class="improvement-text042">People/Team Mgmnt</span>
              </li>
              <li class="improvement-li05 list-item">
                <span class="improvement-text043">
                  Mgmnt of Material Resources
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 2" class="improvement-body-groups">
      <div class="improvement-container28">
        <div class="improvement-period1">
          <img
            alt="pastedImage"
            src="/external/pastedimage-58ss-700w.png"
            class="improvement-pasted-image07"
          />
          <app-component></app-component>
          <app-tabs rootClassName="tabs-root-class-name"></app-tabs>
        </div>
      </div>
      <div class="improvement-right1">
        <div class="improvement-container29">
          <div class="improvement-container30">
            <span class="improvement-text044">
              <span>
                Improvement in
                <span v-html="raw2n3t"></span>
              </span>
              <br class="improvement-text046" />
              <span class="improvement-text047">Goal-Setting</span>
              <span>over the last 30 days</span>
            </span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-e61-200h.png"
              class="improvement-pasted-image08"
            />
          </div>
          <div class="improvement-container31">
            <span class="improvement-text049">
              <span>
                % of available points in
                <span v-html="rawcpra"></span>
              </span>
              <span class="improvement-text051">Mental Flexibility </span>
              <span>in Cell to Singularity</span>
            </span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-x4x-200h.png"
              class="improvement-pasted-image09"
            />
          </div>
        </div>
        <div class="improvement-container32">
          <div class="improvement-container33">
            <span class="improvement-text053">Soft skill improved most</span>
            <span class="improvement-text054">Monitoring Self &amp; Others</span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-5s-200h.png"
              class="improvement-pasted-image10"
            />
            <span class="improvement-text055">
              <span>out of 100 points</span>
              <br />
            </span>
            <span class="improvement-text058">in the last 7 days</span>
          </div>
          <div class="improvement-container34">
            <span class="improvement-text059">Soft skill improved least</span>
            <span>Memory Span</span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-txbl-200h.png"
              class="improvement-pasted-image11"
            />
            <span class="improvement-text061">out of 250 points</span>
            <span class="improvement-text062">in the last 7 days</span>
          </div>
        </div>
        <span class="improvement-text063">Game Suggestions</span>
        <div class="improvement-container35 shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-s64n-200h.png"
            class="improvement-pasted-image12"
          />
          <div class="improvement-container36">
            <span class="improvement-text064">Cities Skylines</span>
            <ul class="list">
              <li class="improvement-li06 list-item">
                <span class="improvement-text065">Self-discipline</span>
              </li>
              <li class="improvement-li07 list-item">
                <span class="improvement-text066">Logical Reasoning</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="improvement-container37 shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-eqee-200h.png"
            class="improvement-pasted-image13"
          />
          <div class="improvement-container38">
            <span class="improvement-text067">Cell to Singularity</span>
            <ul class="list">
              <li class="improvement-li08 list-item">
                <span class="improvement-text068">Mental Flexibility</span>
              </li>
              <li class="improvement-li09 list-item">
                <span class="improvement-text069">People/Team Mgmnt</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 3" class="improvement-body-each">
      <div class="improvement-container39">
        <app-tabs rootClassName="tabs-root-class-name1"></app-tabs>
        <div class="improvement-period2">
          <div class="improvement-container40">
            <span>From</span>
            <calendar-pick date="Feb 01, 2023"></calendar-pick>
            <span>To</span>
            <calendar-pick date="Feb 06, 2023"></calendar-pick>
            <app-select rootClassName="select-root-class-name"></app-select>
          </div>
          <img
            alt="pastedImage"
            src="/external/pastedimage-4b8-800w.png"
            class="improvement-pasted-image14"
          />
        </div>
      </div>
      <div class="improvement-right2">
        <div class="improvement-container41">
          <div class="improvement-container42">
            <span class="improvement-text072">
              <span>
                Improvement in
                <span v-html="raw84ck"></span>
              </span>
              <br class="improvement-text074" />
              <span class="improvement-text075">Goal-Setting</span>
              <span>over the last 30 days</span>
            </span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-e61-200h.png"
              class="improvement-pasted-image15"
            />
          </div>
          <div class="improvement-container43">
            <span class="improvement-text077">
              <span>
                % of available points in
                <span v-html="raw5ybv"></span>
              </span>
              <span class="improvement-text079">Mental Flexibility </span>
              <span>in Cell to Singularity</span>
            </span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-x4x-200h.png"
              class="improvement-pasted-image16"
            />
          </div>
        </div>
        <div class="improvement-container44">
          <div class="improvement-container45">
            <span class="improvement-text081">Soft skill improved most</span>
            <span class="improvement-text082">Monitoring Self &amp; Others</span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-5s-200h.png"
              class="improvement-pasted-image17"
            />
            <span class="improvement-text083">
              <span>out of 100 points</span>
              <br />
            </span>
            <span class="improvement-text086">in the last 7 days</span>
          </div>
          <div class="improvement-container46">
            <span class="improvement-text087">Soft skill improved least</span>
            <span>Memory Span</span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-txbl-200h.png"
              class="improvement-pasted-image18"
            />
            <span class="improvement-text089">out of 250 points</span>
            <span class="improvement-text090">in the last 7 days</span>
          </div>
        </div>
        <span class="improvement-text091">Game Suggestions</span>
        <div class="shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-s64n-200h.png"
            class="improvement-pasted-image19"
          />
          <div class="improvement-container48">
            <span class="improvement-text092">Cities Skylines</span>
            <ul class="list">
              <li class="improvement-li10 list-item">
                <span class="improvement-text093">Self-discipline</span>
              </li>
              <li class="improvement-li11 list-item">
                <span class="improvement-text094">Logical Reasoning</span>
              </li>
              <li class="improvement-li12 list-item">
                <span class="improvement-text095">
                  Mgmnt of Material Resources
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="improvement-container49 shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-eqee-200h.png"
            class="improvement-pasted-image20"
          />
          <div class="improvement-container50">
            <span class="improvement-text096">Cell to Singularity</span>
            <ul class="list">
              <li class="improvement-li13 list-item">
                <span class="improvement-text097">Mental Flexibility</span>
              </li>
              <li class="improvement-li14 list-item">
                <span class="improvement-text098">People/Team Mgmnt</span>
              </li>
              <li class="improvement-li15 list-item">
                <span class="improvement-text099">
                  Mgmnt of Material Resources
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 4" class="improvement-body-about">
      <div class="improvement-container51">
        <app-tabs rootClassName="tabs-root-class-name2"></app-tabs>
        <div class="improvement-period3">
          <app-select
            text="Minion Masters"
            rootClassName="select-root-class-name1"
          ></app-select>
          <img
            alt="pastedImage"
            src="/external/pastedimage-jp2-800w.png"
            class="improvement-pasted-image21"
          />
        </div>
      </div>
      <div class="improvement-right3">
        <div class="improvement-container52">
          <div class="improvement-container53">
            <span class="improvement-text100">
              <span>
                Improvement in
                <span v-html="rawdrzr"></span>
              </span>
              <br class="improvement-text102" />
              <span class="improvement-text103">Goal-Setting</span>
              <span>over the last 30 days</span>
            </span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-e61-200h.png"
              class="improvement-pasted-image22"
            />
          </div>
          <div class="improvement-container54">
            <span class="improvement-text105">
              <span>
                % of available points in
                <span v-html="rawmzaq"></span>
              </span>
              <span class="improvement-text107">Mental Flexibility </span>
              <span>in Cell to Singularity</span>
            </span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-x4x-200h.png"
              class="improvement-pasted-image23"
            />
          </div>
        </div>
        <div class="improvement-container55">
          <div class="improvement-container56">
            <span class="improvement-text109">Soft skill improved most</span>
            <span class="improvement-text110">Monitoring Self &amp; Others</span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-5s-200h.png"
              class="improvement-pasted-image24"
            />
            <span class="improvement-text111">
              <span>out of 100 points</span>
              <br />
            </span>
            <span class="improvement-text114">in the last 7 days</span>
          </div>
          <div class="improvement-container57">
            <span class="improvement-text115">Soft skill improved least</span>
            <span>Memory Span</span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-txbl-200h.png"
              class="improvement-pasted-image25"
            />
            <span class="improvement-text117">out of 250 points</span>
            <span class="improvement-text118">in the last 7 days</span>
          </div>
        </div>
        <span class="improvement-text119">Game Suggestions</span>
        <div class="improvement-container58 shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-s64n-200h.png"
            class="improvement-pasted-image26"
          />
          <div class="improvement-container59">
            <span class="improvement-text120">Cities Skylines</span>
            <ul class="list">
              <li class="improvement-li16 list-item">
                <span class="improvement-text121">Self-discipline</span>
              </li>
              <li class="improvement-li17 list-item">
                <span class="improvement-text122">Logical Reasoning</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="improvement-container60 shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-eqee-200h.png"
            class="improvement-pasted-image27"
          />
          <div class="improvement-container61">
            <span class="improvement-text123">Cell to Singularity</span>
            <ul class="list">
              <li class="improvement-li18 list-item">
                <span class="improvement-text124">Mental Flexibility</span>
              </li>
              <li class="improvement-li19 list-item">
                <span class="improvement-text125">People/Team Mgmnt</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTabs from './tabs'
import CalendarPick from './calendar-pick'
import AppComponent from './component'
import AppSelect from './select'

export default {
  name: 'Improvement',
  props: {},
  components: {
    AppTabs,
    CalendarPick,
    AppComponent,
    AppSelect,
  },
  data() {
    return {
      tab: 1,
      raw0qxt: ' ',
      rawp4vk: ' ',
      raw2n3t: ' ',
      rawcpra: ' ',
      raw84ck: ' ',
      raw5ybv: ' ',
      rawdrzr: ' ',
      rawmzaq: ' ',
    }
  },
}
</script>

<style scoped>
.improvement-middle {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-app-white);
}
.improvement-tabs-all {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.improvement-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-container01 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text001 {
  color: var(--dl-color-app-neutral);
}
.improvement-container02 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text002 {
  color: var(--dl-color-app-neutral);
}
.improvement-container03 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text003 {
  color: var(--dl-color-app-neutral);
}
.improvement-tabs-groups {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.improvement-container04 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text004 {
  color: var(--dl-color-app-neutral);
}
.improvement-container05 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-container06 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text006 {
  color: var(--dl-color-app-neutral);
}
.improvement-container07 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text007 {
  color: var(--dl-color-app-neutral);
}
.improvement-tabs-list {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.improvement-container08 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text008 {
  color: var(--dl-color-app-neutral);
}
.improvement-container09 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text009 {
  color: var(--dl-color-app-neutral);
}
.improvement-container10 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-container11 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text011 {
  color: var(--dl-color-app-neutral);
}
.improvement-tabs-about {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.improvement-container12 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text012 {
  color: var(--dl-color-app-neutral);
}
.improvement-container13 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text013 {
  color: var(--dl-color-app-neutral);
}
.improvement-container14 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-text014 {
  color: var(--dl-color-app-neutral);
}
.improvement-container15 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.improvement-body-all {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: 70px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
}
.improvement-container16 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.improvement-pasted-image {
  width: 800px;
  align-self: center;
}
.improvement-period {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.improvement-container17 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.improvement-right {
  gap: var(--dl-space-space-unit);
  width: 340px;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.improvement-container18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: row;
}
.improvement-container19 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text016 {
  font-size: 12px;
  text-align: center;
}
.improvement-text018 {
  font-weight: 700;
}
.improvement-text019 {
  font-weight: 700;
}
.improvement-pasted-image01 {
  width: 100px;
  height: 100px;
}
.improvement-container20 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text021 {
  font-size: 12px;
  text-align: center;
}
.improvement-text023 {
  font-weight: 700;
}
.improvement-pasted-image02 {
  width: 100px;
  height: 100px;
}
.improvement-container21 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: row;
}
.improvement-container22 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.improvement-text025 {
  font-size: 12px;
  text-align: center;
}
.improvement-text026 {
  text-align: center;
}
.improvement-pasted-image03 {
  height: 57px;
}
.improvement-text027 {
  font-size: 14px;
  text-align: center;
}
.improvement-text030 {
  font-size: 12px;
  text-align: center;
}
.improvement-container23 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.improvement-text031 {
  font-size: 12px;
  text-align: center;
}
.improvement-pasted-image04 {
  height: 90px;
}
.improvement-text033 {
  font-size: 14px;
  text-align: center;
}
.improvement-text034 {
  font-size: 12px;
  text-align: center;
}
.improvement-text035 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-pasted-image05 {
  width: 100px;
  height: 100px;
  align-self: flex-start;
}
.improvement-container25 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text036 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-li {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text037 {
  font-weight: 300;
}
.improvement-li01 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text038 {
  font-weight: 300;
}
.improvement-li02 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text039 {
  font-weight: 300;
}
.improvement-container26 {
  height: 160px;
}
.improvement-pasted-image06 {
  width: 100px;
  height: 100px;
  align-self: flex-start;
}
.improvement-container27 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text040 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-li03 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text041 {
  font-weight: 300;
}
.improvement-li04 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text042 {
  font-weight: 300;
}
.improvement-li05 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text043 {
  font-weight: 300;
}
.improvement-body-groups {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: 70px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.improvement-container28 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.improvement-period1 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: column;
}
.improvement-pasted-image07 {
  width: 635px;
}
.improvement-right1 {
  gap: var(--dl-space-space-unit);
  width: 340px;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.improvement-container29 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: row;
}
.improvement-container30 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text044 {
  font-size: 12px;
  text-align: center;
}
.improvement-text046 {
  font-weight: 700;
}
.improvement-text047 {
  font-weight: 700;
}
.improvement-pasted-image08 {
  width: 100px;
  height: 100px;
}
.improvement-container31 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text049 {
  font-size: 12px;
  text-align: center;
}
.improvement-text051 {
  font-weight: 700;
}
.improvement-pasted-image09 {
  width: 100px;
  height: 100px;
}
.improvement-container32 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: row;
}
.improvement-container33 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.improvement-text053 {
  font-size: 12px;
  text-align: center;
}
.improvement-text054 {
  text-align: center;
}
.improvement-pasted-image10 {
  height: 57px;
}
.improvement-text055 {
  font-size: 14px;
  text-align: center;
}
.improvement-text058 {
  font-size: 12px;
  text-align: center;
}
.improvement-container34 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.improvement-text059 {
  font-size: 12px;
  text-align: center;
}
.improvement-pasted-image11 {
  height: 90px;
}
.improvement-text061 {
  font-size: 14px;
  text-align: center;
}
.improvement-text062 {
  font-size: 12px;
  text-align: center;
}
.improvement-text063 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-container35 {
  height: 140px;
}
.improvement-pasted-image12 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.improvement-container36 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text064 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-li06 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text065 {
  font-weight: 300;
}
.improvement-li07 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text066 {
  font-weight: 300;
}
.improvement-container37 {
  height: 140px;
}
.improvement-pasted-image13 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.improvement-container38 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text067 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-li08 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text068 {
  font-weight: 300;
}
.improvement-li09 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text069 {
  font-weight: 300;
}
.improvement-body-each {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: 70px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
}
.improvement-container39 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.improvement-period2 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.improvement-container40 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.improvement-pasted-image14 {
  width: 738px;
}
.improvement-right2 {
  gap: var(--dl-space-space-unit);
  width: 340px;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.improvement-container41 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: row;
}
.improvement-container42 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text072 {
  font-size: 12px;
  text-align: center;
}
.improvement-text074 {
  font-weight: 700;
}
.improvement-text075 {
  font-weight: 700;
}
.improvement-pasted-image15 {
  width: 100px;
  height: 100px;
}
.improvement-container43 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text077 {
  font-size: 12px;
  text-align: center;
}
.improvement-text079 {
  font-weight: 700;
}
.improvement-pasted-image16 {
  width: 100px;
  height: 100px;
}
.improvement-container44 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: row;
}
.improvement-container45 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.improvement-text081 {
  font-size: 12px;
  text-align: center;
}
.improvement-text082 {
  text-align: center;
}
.improvement-pasted-image17 {
  height: 57px;
}
.improvement-text083 {
  font-size: 14px;
  text-align: center;
}
.improvement-text086 {
  font-size: 12px;
  text-align: center;
}
.improvement-container46 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.improvement-text087 {
  font-size: 12px;
  text-align: center;
}
.improvement-pasted-image18 {
  height: 90px;
}
.improvement-text089 {
  font-size: 14px;
  text-align: center;
}
.improvement-text090 {
  font-size: 12px;
  text-align: center;
}
.improvement-text091 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-pasted-image19 {
  width: 100px;
  height: 100px;
  align-self: flex-start;
}
.improvement-container48 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text092 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-li10 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text093 {
  font-weight: 300;
}
.improvement-li11 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text094 {
  font-weight: 300;
}
.improvement-li12 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text095 {
  font-weight: 300;
}
.improvement-container49 {
  height: 160px;
}
.improvement-pasted-image20 {
  width: 100px;
  height: 100px;
  align-self: flex-start;
}
.improvement-container50 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text096 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-li13 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text097 {
  font-weight: 300;
}
.improvement-li14 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text098 {
  font-weight: 300;
}
.improvement-li15 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text099 {
  font-weight: 300;
}
.improvement-body-about {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: 70px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
}
.improvement-container51 {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.improvement-period3 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-self: center;
  align-items: flex-end;
  flex-direction: column;
}
.improvement-pasted-image21 {
  width: 738px;
}
.improvement-right3 {
  gap: var(--dl-space-space-unit);
  width: 340px;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.improvement-container52 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: row;
}
.improvement-container53 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text100 {
  font-size: 12px;
  text-align: center;
}
.improvement-text102 {
  font-weight: 700;
}
.improvement-text103 {
  font-weight: 700;
}
.improvement-pasted-image22 {
  width: 100px;
  height: 100px;
}
.improvement-container54 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text105 {
  font-size: 12px;
  text-align: center;
}
.improvement-text107 {
  font-weight: 700;
}
.improvement-pasted-image23 {
  width: 100px;
  height: 100px;
}
.improvement-container55 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: row;
}
.improvement-container56 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.improvement-text109 {
  font-size: 12px;
  text-align: center;
}
.improvement-text110 {
  text-align: center;
}
.improvement-pasted-image24 {
  height: 57px;
}
.improvement-text111 {
  font-size: 14px;
  text-align: center;
}
.improvement-text114 {
  font-size: 12px;
  text-align: center;
}
.improvement-container57 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.improvement-text115 {
  font-size: 12px;
  text-align: center;
}
.improvement-pasted-image25 {
  height: 90px;
}
.improvement-text117 {
  font-size: 14px;
  text-align: center;
}
.improvement-text118 {
  font-size: 12px;
  text-align: center;
}
.improvement-text119 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-container58 {
  height: 140px;
}
.improvement-pasted-image26 {
  width: 100px;
  height: 100px;
  align-self: flex-start;
}
.improvement-container59 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text120 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-li16 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text121 {
  font-weight: 300;
}
.improvement-li17 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text122 {
  font-weight: 300;
}
.improvement-container60 {
  height: 140px;
}
.improvement-pasted-image27 {
  width: 100px;
  height: 100px;
  align-self: flex-start;
}
.improvement-container61 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.improvement-text123 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.improvement-li18 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text124 {
  font-weight: 300;
}
.improvement-li19 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.improvement-text125 {
  font-weight: 300;
}
</style>
