<template>
  <div class="h2-container" v-bind:class="rootClassName">
    <h2 class="h2-text">{{ H2 }}</h2>
  </div>
</template>

<script>
export default {
  name: 'H2',
  props: {
    rootClassName: String,
    H2: {
      type: String,
      default: 'Testimonials',
    },
  },
}
</script>

<style scoped>
.h2-container {
  display: flex;
}
.h2-text {
  font-size: 40px;
  font-weight: 600;
  line-height: 130%;
}
.h2-root-class-name {
  align-self: center;
}
.h2-root-class-name1 {
  align-self: center;
}












@media(max-width: 991px) {
  .h2-root-class-name2 {
    align-self: center;
    margin-left: var(--dl-space-space-sixunits);
  }
}
@media(max-width: 767px) {
  .h2-text {
    font-size: 30px;
    text-align: center;
  }
  .h2-root-class-name2 {
    margin-left: 0px;
  }
  .h2-root-class-name3 {
    align-self: flex-start;
  }
  .h2-root-class-name4 {
    align-self: flex-start;
  }
  .h2-root-class-name5 {
    align-self: flex-start;
  }
  .h2-root-class-name6 {
    align-self: flex-start;
  }
  .h2-root-class-name7 {
    align-self: flex-start;
  }
  .h2-root-class-name8 {
    align-self: flex-start;
  }
  .h2-root-class-name9 {
    align-self: flex-start;
  }
  .h2-root-class-name10 {
    align-self: flex-start;
  }
  .h2-root-class-name11 {
    align-self: flex-start;
  }
  .h2-root-class-name12 {
    align-self: flex-start;
  }
  .h2-root-class-name22 {
    align-self: flex-start;
  }
}
@media(max-width: 479px) {
  .h2-text {
    font-size: 32px;
  }
  .h2-root-class-name2 {
    align-self: center;
  }
}
</style>
