<template>
  <div class="header-header section-full" v-bind:class="rootClassName">
    <header data-thq="thq-navbar" class="header-navbar-interactive">
      <router-link to="/" class="header-navlink">
        <img :alt="Logo_alt" :src="Logo_src" class="header-logo" />
      </router-link>
      <div data-thq="thq-navbar-nav" data-role="Nav" class="header-desktop-menu">
        <nav data-thq="thq-navbar-nav-links" data-role="Nav" class="header-nav">
          <router-link to="/" class="header-text">{{ text }}</router-link>
          <router-link to="/how-it-works" class="header-text1">
            {{ text1 }}
          </router-link>
          <router-link to="/register" class="header-text2">
            {{ text2 }}
          </router-link>
        </nav>
      </div>
      <div data-thq="thq-navbar-btn-group" class="header-btn-group">
        <button class="header-login button">Login</button>
        <button class="button">Register</button>
      </div>
      <div data-thq="thq-burger-menu" class="header-burger-menu">
        <svg viewBox="0 0 1024 1024" class="header-icon">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
          ></path>
        </svg>
      </div>
      <div data-thq="thq-mobile-menu" class="header-mobile-menu">
        <div data-thq="thq-mobile-menu-nav" data-role="Nav" class="header-nav1">
          <div class="header-container">
            <img :alt="Logo_alt1" :src="Logo_src1" class="header-logo1" />
            <div data-thq="thq-close-menu" class="header-menu-close">
              <svg viewBox="0 0 1024 1024" class="header-icon02">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                ></path>
              </svg>
            </div>
          </div>
          <nav
            data-thq="thq-mobile-menu-nav-links"
            data-role="Nav"
            class="header-nav2"
          >
            <router-link to="/" class="header-text3">{{ text3 }}</router-link>
            <router-link to="/how-it-works" class="header-text4">
              {{ text11 }}
            </router-link>
            <router-link to="/register" class="header-text5">
              {{ text21 }}
            </router-link>
          </nav>
          <div class="header-container1">
            <button class="header-login1 button">Login</button>
            <button class="button">Register</button>
          </div>
        </div>
        <div class="header-icon-group">
          <svg viewBox="0 0 950.8571428571428 1024" class="header-icon04">
            <path
              d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
            ></path></svg
          ><svg viewBox="0 0 877.7142857142857 1024" class="header-icon06">
            <path
              d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
            ></path></svg
          ><svg viewBox="0 0 602.2582857142856 1024" class="header-icon08">
            <path
              d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
            ></path>
          </svg>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    Logo_alt3: {
      type: String,
      default: 'pastedImage',
    },
    text3: {
      type: String,
      default: 'Home',
    },
    Logo_alt: {
      type: String,
      default: 'pastedImage',
    },
    Logo_src3: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a207366c-eb3b-44aa-832a-3289025cd97f/18339f8d-1c8d-4ae1-8c98-87071e8b7525?org_if_sml=115831',
    },
    Logo_alt1: {
      type: String,
      default: 'pastedImage',
    },
    text2: {
      type: String,
      default: 'Register',
    },
    Logo_alt2: {
      type: String,
      default: 'pastedImage',
    },
    text1: {
      type: String,
      default: 'How it Works',
    },
    text11: {
      type: String,
      default: 'How it Works',
    },
    Logo_src1: {
      type: String,
      default: '/external/pastedimage-jos-1500w.png',
    },
    Logo_src2: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a207366c-eb3b-44aa-832a-3289025cd97f/18339f8d-1c8d-4ae1-8c98-87071e8b7525?org_if_sml=115831',
    },
    Logo_src: {
      type: String,
      default: '/external/skillquest-1500w.png',
    },
    text21: {
      type: String,
      default: 'Register',
    },
    rootClassName: String,
    text: {
      type: String,
      default: 'Home',
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
}
</script>

<style scoped>
.header-header {
  position: relative;
  margin-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.header-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.header-navlink {
  display: contents;
}
.header-logo {
  height: 52px;
  text-decoration: none;
}
.header-desktop-menu {
  display: flex;
}
.header-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header-text {
  height: auto;
  font-size: 22px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 45px;
  margin-bottom: 0;
  text-decoration: none;
}
.header-text1 {
  width: 141px;
  height: auto;
  font-size: 22px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 45px;
  margin-bottom: 0;
  text-decoration: none;
}
.header-text2 {
  height: auto;
  font-size: 22px;
  align-self: auto;
  font-style: Regular;
  text-align: right;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.header-btn-group {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-login {
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
}
.header-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.header-icon {
  fill: var(--dl-color-app-primary);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.header-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header-logo1 {
  height: 52px;
}
.header-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icon02 {
  fill: var(--dl-color-app-primary);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-nav2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-text3 {
  height: auto;
  font-size: 22px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 45px;
  margin-bottom: 0;
  text-decoration: none;
}
.header-text4 {
  width: 141px;
  height: auto;
  font-size: 22px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 45px;
  margin-bottom: 0;
  text-decoration: none;
}
.header-text5 {
  height: auto;
  font-size: 22px;
  align-self: auto;
  font-style: Regular;
  text-align: right;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.header-container1 {
  flex: 0 0 auto;
  width: auto;
  display: none;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.header-login1 {
  margin-right: var(--dl-space-space-twounits);
}
.header-icon-group {
  display: none;
}
.header-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-root-class-name {
  margin-top: var(--dl-space-space-halfunit);
}



.header-root-class-name4 {
  margin-top: var(--dl-space-space-halfunit);
}


.header-root-class-name7 {
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0px;
  margin-right: 0px;
}
@media(max-width: 767px) {
  .header-header {
    margin-top: var(--dl-space-space-unit);
  }
  .header-logo {
    height: 32px;
  }
  .header-desktop-menu {
    display: none;
  }
  .header-btn-group {
    display: none;
  }
  .header-burger-menu {
    display: flex;
  }
  .header-logo1 {
    height: 32px;
  }
}
@media(max-width: 479px) {
  .header-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .header-mobile-menu {
    padding: 16px;
  }
  .header-nav2 {
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .header-text3 {
    margin-right: 0px;
  }
  .header-text4 {
    margin-right: 0px;
  }
}
</style>
