<template>
  <div class="coach-teams-card-container">
    <div id="Billing" class="coach-teams-card-container1 left-menu-btn">
      <div class="icon-wrapper">
        <img
          alt="pastedImage"
          src="/external/pastedimage-lm2r.svg"
          class="coach-teams-card-pasted-image"
        />
      </div>
      <span class="coach-teams-card-text">
        <span>Teams</span>
        <br />
      </span>
    </div>
    <div id="Billing" class="coach-teams-card-container3 left-menu-btn">
      <span class="coach-teams-card-text3">
        <span>Manage</span>
        <br />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoachTeamsCard',
  props: {},
}
</script>

<style scoped>
.coach-teams-card-container {
  display: flex;
  position: relative;
  flex-direction: column;
}
.coach-teams-card-container1 {
  height: 44px;
  background-color: var(--dl-color-app-primary);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.coach-teams-card-pasted-image {
  width: 25px;
  height: 26px;
}
.coach-teams-card-text {
  color: var(--dl-color-app-white);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.coach-teams-card-container3 {
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.coach-teams-card-text3 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
</style>
