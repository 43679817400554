<template>
  <div class="filter-container"><span class="filter-text">{{ label }}</span></div>
</template>

<script>
export default {
  name: 'Filter',
  props: {
    label: {
      type: String,
      default: 'Text',
    },
  },
}
</script>

<style scoped>
.filter-container {
  flex: 0 0 auto;
  width: 200px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius10);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-app-white);
}
.filter-text {
  opacity: 50%;
}
</style>
