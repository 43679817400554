<template>
  <div class="mobile-only-msg-container" v-bind:class="rootClassName">
    <span class="mobile-only-msg-text">
      <span>
        This experience is currently only available for desktop, laptop, and console.
        <span v-html="raw3zkc"></span>
      </span>
      <br />
      <span>The mobile application will be available soon.</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'MobileOnlyMsg',
  props: {
    rootClassName: String,
  },
  data() {
    return {
      raw3zkc: ' ',
    }
  },
}
</script>

<style scoped>
.mobile-only-msg-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: 1000px;
  align-items: center;
  border-color: var(--dl-color-app-orange);
  border-style: solid;
  border-width: 4px;
  border-radius: 20px;
  flex-direction: column;
}
.mobile-only-msg-text {
  font-size: 26px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 39px;
}
.mobile-only-msg-root-class-name {
  align-self: center;
}

@media(max-width: 767px) {
  .mobile-only-msg-root-class-name1 {
    width: 90%;
  }
}
@media(max-width: 479px) {
  .mobile-only-msg-root-class-name1 {
    max-width: 90%;
  }
}
</style>
