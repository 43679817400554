<template>
  <!-- Games Scroller -->
  <section class="games_scroller_wrapper">
      <div class="games_scroller_container">
        <div class="games_scroller">
          <div v-for="item in gamesData" class="games_scroller_item">
            <a :href=item.href target="_blank">
              <img :alt=item.name :src=item.src class="games_scroller_image"/>
            </a>
          </div>
          <!-- The exact copy of previous items -->
          <div v-for="item in gamesData" class="games_scroller_item">
            <a :href=item.href target="_blank">
              <img :alt=item.name :src=item.src class="games_scroller_image"/>
            </a>
          </div>
		  </div>
		</div>
	</section>
</template>
  
<script>
export default {
  name: 'Games',
  data() {
    return {
      gamesData: [
        {appid:588430, name:'Fallout_Shelter', image:'falloutshelter4662-ynsx-200h.png'},
        {appid:977400, name:'Cell_to_Singularity__Evolution_Never_Ends', image:'celltosingularity4662-9h7o-200h.png'},
        {appid:563560, name:'Alien_Swarm_Reactive_Drop', image:'alienswarm4662-v0f9-200h.png'},
        {appid:440, name:'Team_Fortress_2', image:'teamfortress224662-qyso-200h.png'},
        {appid:489520, name:'Minion_Masters', image:'minionmasters14662-ov1-200h.png'},
        {appid:329110, name:'Gems_of_War__Puzzle_RPG', image:'gemsofwar4662-c4w-200h.png'},
        {appid:730, name:'CounterStrike_Global_Offensive', image:'csgo4663-14c9-200h.png'},
        {appid:305260, name:'Battle_Islands', image:'BattleIslands-bw.png'},
        {appid:1067310, name:'Gravitas', image:'Gravitas-bw.png'},
        //{appid:, name:'CitiesSkylines', image:'citiesskylines4662-gmyh-200h.png'},
        //{appid:, name:'Portal2', image:'portal224662-y1tv-200h.png'},
        //{appid:, name:'TrainValley', image:'trainvalley4662-2qtc-200h.png'},
        //{appid:, name:'Anomaly2', image:'anomaly4662-yieo-200h.png'},
      ].filter((g) => g.appid && g.name && g.image).map((g) => ({
        href:`https://store.steampowered.com/app/${g.appid}/${g.name}/`, 
        name: g.name,
        src:`/external/${g.image}`}))
    }
  },
}
</script>
  
<style scoped>
.games_scroller_wrapper {
  /* How long one slide is visible on screen (from entering screen to leaving it) */
  --scrolling-gallery-games-duration: 20s;
  /* How many items are to scroll */
  --scrolling-gallery-games-total: 9;
  /* How many items we want to see on screen at once */
  --scrolling-gallery-games-visible: var(--scrolling-gallery-games-total);
  /* How much items are wide */
  --scrolling-gallery-games-width: var(--dl-size-size-xlarge);
  /* How much items are spaced */
  --scrolling-gallery-games-margin: 1.8vw;
  overflow: hidden;
  will-change: transform;
}
.games_scroller {
  animation-duration: calc(var(--scrolling-gallery-games-duration, 1s) / var(--scrolling-gallery-games-visible) * var(--scrolling-gallery-games-total));
  animation-timing-function: linear;
  animation-name: games-scrolling-gallery;
  animation-iteration-count: infinite;
  display: flex;
  white-space: nowrap;
}
.games_scroller_container {
  /* Without this, scroll will jump on desktop if any vertical scrollbar is shown */
  width: 100vw;
}
.games_scroller_item {
  flex: 1 0 calc(100% / var(--scrolling-gallery-games-visible));
  /* Without this, block elements will take width from their contents and thus making wrong calculations,
     so this just force elements to take only exact part of the container (screen) and equal for all */
  width: 0px;
  /* If you want to have it continuous without any spaces, remove two lines below */
  box-sizing: border-box;
  margin-inline: var(--scrolling-gallery-games-margin);
}
.games_scroller_image {
  width: var(--scrolling-gallery-games-width);
  height: var(--scrolling-gallery-games-width);
  display: block;
  border-radius: 27px;
  object-fit: cover;
  object-position: center;
}
@keyframes games-scrolling-gallery {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(var(--scrolling-gallery-games-total) * (-100vw - var(--scrolling-gallery-games-margin) * var(--scrolling-gallery-games-total) * 2) / var(--scrolling-gallery-games-visible)));
  }
}
.games_scroller:hover,
.games_scroller:focus {
  animation-play-state: paused;
}
@media(max-width: 1440px) {
  .games_scroller_wrapper {
    --scrolling-gallery-games-visible: 8;
  }
}
@media(max-width: 1280px) {
  .games_scroller_wrapper {
    --scrolling-gallery-games-visible: 7;
  }
}
@media(max-width: 1080px) {
  .games_scroller_wrapper {
    --scrolling-gallery-games-visible: 5;
  }
}
@media(max-width: 860px) {
  .games_scroller_wrapper {
    --scrolling-gallery-games-visible: 6;
    --scrolling-gallery-games-width: var(--dl-size-size-large);
  }
}
@media(max-width: 700px) {
  .games_scroller_wrapper {
    --scrolling-gallery-games-visible: 4;
  }
}
@media(max-width: 520px) {
  .games_scroller_wrapper {
    --scrolling-gallery-games-visible: 3;
  }
}
@media(max-width: 440px) {
  .games_scroller_wrapper {
    --scrolling-gallery-games-visible: 4;
    --scrolling-gallery-games-width: var(--dl-size-size-medium);
  }
}
@media(max-width: 360px) {
  .games_scroller_wrapper {
    --scrolling-gallery-games-visible: 3;
  }
}
@media(max-width: 260px) {
  .games_scroller_wrapper {
    --scrolling-gallery-games-visible: 5;
    --scrolling-gallery-games-width: var(--dl-size-size-small);
  }
}
/*
--dl-size-size-xxlarge: 288px;
--dl-size-size-xlarge: 192px;
--dl-size-size-large: 144px;
--dl-size-size-medium: 96px;
--dl-size-size-small: 48px;
--dl-size-size-xsmall: 16px;
*/
</style>
