<template>
  <div class="soft-skills-tabs-middle" v-bind:class="rootClassName">
    <div v-if="isVisible === 1" class="soft-skills-tabs-tabs-all">
      <div class="soft-skills-tabs-container tab-selected tab">
        <span>All</span>
      </div>
      <div @click="isVisible = 2" class="soft-skills-tabs-container01 tab">
        <span class="soft-skills-tabs-text01">Groups</span>
      </div>
      <div @click="isVisible = 3" class="soft-skills-tabs-container02 tab">
        <span class="soft-skills-tabs-text02">List</span>
      </div>
      <div @click="isVisible = 4" class="soft-skills-tabs-container03 tab">
        <span class="soft-skills-tabs-text03">About</span>
      </div>
    </div>
    <div v-if="isVisible === 2" class="soft-skills-tabs-tabs-groups">
      <div @click="isVisible = 1" class="soft-skills-tabs-container04 tab">
        <span>All</span>
      </div>
      <div class="soft-skills-tabs-container05 tab-selected tab">
        <span>Groups</span>
      </div>
      <div @click="isVisible = 3" class="soft-skills-tabs-container06 tab">
        <span class="soft-skills-tabs-text06">List</span>
      </div>
      <div @click="isVisible = 4" class="soft-skills-tabs-container07 tab">
        <span class="soft-skills-tabs-text07">About</span>
      </div>
    </div>
    <div v-if="isVisible === 3" class="soft-skills-tabs-tabs-list">
      <div @click="isVisible = 1" class="soft-skills-tabs-container08 tab">
        <span>All</span>
      </div>
      <div @click="isVisible = 2" class="soft-skills-tabs-container09 tab">
        <span class="soft-skills-tabs-text09">Groups</span>
      </div>
      <div class="soft-skills-tabs-container10 tab-selected tab">
        <span>List</span>
      </div>
      <div @click="isVisible = 4" class="soft-skills-tabs-container11 tab">
        <span class="soft-skills-tabs-text11">About</span>
      </div>
    </div>
    <div v-if="isVisible === 4" class="soft-skills-tabs-tabs-about">
      <div @click="isVisible = 1" class="soft-skills-tabs-container12 tab">
        <span>All</span>
      </div>
      <div @click="isVisible = 2" class="soft-skills-tabs-container13 tab">
        <span class="soft-skills-tabs-text13">Groups</span>
      </div>
      <div @click="isVisible = 3" class="soft-skills-tabs-container14 tab">
        <span class="soft-skills-tabs-text14">List</span>
      </div>
      <div class="soft-skills-tabs-container15 tab-selected tab">
        <span>About</span>
      </div>
    </div>
    <div v-if="isVisible === 1" class="soft-skills-tabs-body-all">
      <div class="soft-skills-tabs-left"  style="overflow-y:scroll">
        <app-tabs></app-tabs>
        <div class="soft-skills-tabs-images">
          <div class="soft-skills-tabs-all show-content content">
		   <Graph/>               
          </div>
          <div class="soft-skills-tabs-cognitive content">
            <img
              alt="image"
              src="/soft-skills/cognitive-set-1500h.png"
              class="soft-skills-tabs-image01"
            />
          </div>
          <div class="soft-skills-tabs-basic-skills content">
            <img
              alt="image"
              src="/soft-skills/basic-set-1500h.png"
              class="soft-skills-tabs-image02"
            />
          </div>
          <div class="soft-skills-tabs-all1 content">
            <img
              alt="image"
              src="/soft-skills/cross-functional-set-1500h.png"
              class="soft-skills-tabs-image03"
            />
          </div>
          <div class="soft-skills-tabs-all2 content">
            <img
              alt="image"
              src="/soft-skills/emotional-set-1500h.png"
              class="soft-skills-tabs-image04"
            />
          </div>
        </div>
      </div>
      <img
        alt="pastedImage"
        src="/external/pastedimage-8dgr-400w.png"
        class="soft-skills-tabs-pasted-image"
      />
      <div>
        <div class="soft-skills-tabs-container17">
          <DangerousHTML
            html="<script src='https://html-editor-teleport-dev.vercel.app/preview/54/index.js'></script>
  "
          ></DangerousHTML>
        </div>
      </div>
    </div>
    <div v-if="isVisible === 2" class="soft-skills-tabs-body-groups">
      <div class="soft-skills-tabs-left1">
        <app-tabs></app-tabs>
        <div class="soft-skills-tabs-images1">
          <div class="soft-skills-tabs-all3 show-content content">
            <img
              alt="image"
              src="/soft-skills-groups/map-all-1500h.png"
              class="soft-skills-tabs-image05"
            />
          </div>
          <div class="soft-skills-tabs-cognitive1 content">
            <img
              alt="image"
              src="/soft-skills-groups/map1-1500h.png"
              class="soft-skills-tabs-image06"
            />
          </div>
          <div class="soft-skills-tabs-basic-skills1 content">
            <img
              alt="image"
              src="/soft-skills-groups/map2-1500h.png"
              class="soft-skills-tabs-image07"
            />
          </div>
          <div class="soft-skills-tabs-all4 content">
            <img
              alt="image"
              src="/soft-skills-groups/map3-1500h.png"
              class="soft-skills-tabs-image08"
            />
          </div>
          <div class="soft-skills-tabs-all5 content">
            <img
              alt="image"
              src="/soft-skills-groups/map4-1500h.png"
              class="soft-skills-tabs-image09"
            />
          </div>
        </div>
        <div>
          <div class="soft-skills-tabs-container19">
            <DangerousHTML
              html="<script src='https://html-editor-teleport-dev.vercel.app/preview/54/index.js'></script>
  "
            ></DangerousHTML>
          </div>
        </div>
      </div>
      <img
        alt="pastedImage"
        src="/external/pastedimage-8dgr-400w.png"
        class="soft-skills-tabs-pasted-image1"
      />
    </div>
    <div v-if="isVisible === 3" class="soft-skills-tabs-body-list">
      <div class="soft-skills-tabs-container20">
        <app-tabs></app-tabs>
        <div class="soft-skills-tabs-images2">
          <div class="soft-skills-tabs-all6 show-content content">
            <img
              alt="image"
              src="/skills-all-1300h.png"
              class="soft-skills-tabs-image10"
            />
          </div>
          <div class="soft-skills-tabs-cognitive2 content">
            <img
              alt="image"
              src="/skills-1-1300h.png"
              class="soft-skills-tabs-image11"
            />
          </div>
          <div class="soft-skills-tabs-basic-skills2 content">
            <img
              alt="image"
              src="/skills-2-1300h.png"
              class="soft-skills-tabs-image12"
            />
          </div>
          <div class="soft-skills-tabs-all7 content">
            <img
              alt="image"
              src="/skills-3-1300h.png"
              class="soft-skills-tabs-image13"
            />
          </div>
          <div class="soft-skills-tabs-all8 content">
            <img
              alt="image"
              src="/skills-4-1300h.png"
              class="soft-skills-tabs-image14"
            />
          </div>
        </div>
        <div>
          <div class="soft-skills-tabs-container22">
            <DangerousHTML
              html="<script src='https://html-editor-teleport-dev.vercel.app/preview/54/index.js'></script>
  "
            ></DangerousHTML>
          </div>
        </div>
      </div>
      <img
        alt="pastedImage"
        src="/external/pastedimage-8dgr-400w.png"
        class="soft-skills-tabs-pasted-image2"
      />
    </div>
    <div v-if="isVisible === 4" class="soft-skills-tabs-body-about">
      <div class="soft-skills-tabs-container23">
        <div class="soft-skills-tabs-buttons">
          <button class="soft-skills-tabs-button">
            <span class="soft-skills-tabs-text16">
              <span>Cognitive Abilities</span>
              <br />
            </span>
          </button>
          <button class="soft-skills-tabs-button1">
            <span class="soft-skills-tabs-text19">
              <span>Basic Skills</span>
              <br />
            </span>
          </button>
          <button class="soft-skills-tabs-button2">
            <span class="soft-skills-tabs-text22">
              <span>Cross-Functional Skills</span>
              <br />
            </span>
          </button>
          <button class="soft-skills-tabs-button3">
            <span class="soft-skills-tabs-text25">
              <span>Cognitive Abilities</span>
              <br />
            </span>
          </button>
        </div>
        <img
          alt="pastedImage"
          src="/external/pastedimage-1y9-200h.png"
          class="soft-skills-tabs-pasted-image3"
        />
        <div class="soft-skills-tabs-container24">
          <span class="soft-skills-tabs-text28">Cognitive Flexibility</span>
          <app-select rootClassName="select-root-class-name5"></app-select>
        </div>
        <span class="soft-skills-tabs-text29">
          The ability to see different patterns or generate different options to
          solve or analyze new situations. Along with other skills, Cognitive
          Flexibility plays an important factor in the ability to adapt to new
          situations as well as the ability to think &quot;outside the box&quot;
          and keep an open mind in front of new and unexpected events or problems.
        </span>
      </div>
      <img
        alt="pastedImage"
        src="/external/pastedimage-8dgr-400w.png"
        class="soft-skills-tabs-pasted-image4"
      />
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import AppTabs from './tabs'
import AppSelect from './select'
import Graph from './graph'

export default {
  name: 'SoftSkillsTabs',
  props: {
    rootClassName: String,
  },
  components: {
    AppTabs,
    DangerousHTML,
    AppSelect,
	Graph,
  },
  data() {
    return {
      tab: 1,
      isVisible: 1,
    }
  },
}
</script>

<style scoped>
.soft-skills-tabs-middle {
  flex: 1;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-app-white);
}
.soft-skills-tabs-tabs-all {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.soft-skills-tabs-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-container01 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-text01 {
  color: var(--dl-color-app-neutral);
}
.soft-skills-tabs-container02 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-text02 {
  color: var(--dl-color-app-neutral);
}
.soft-skills-tabs-container03 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-text03 {
  color: var(--dl-color-app-neutral);
}
.soft-skills-tabs-tabs-groups {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.soft-skills-tabs-container04 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-container05 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-container06 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-text06 {
  color: var(--dl-color-app-neutral);
}
.soft-skills-tabs-container07 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-text07 {
  color: var(--dl-color-app-neutral);
}
.soft-skills-tabs-tabs-list {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.soft-skills-tabs-container08 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-container09 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-text09 {
  color: var(--dl-color-app-neutral);
}
.soft-skills-tabs-container10 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-container11 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-text11 {
  color: var(--dl-color-app-neutral);
}
.soft-skills-tabs-tabs-about {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.soft-skills-tabs-container12 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-container13 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-text13 {
  color: var(--dl-color-app-neutral);
}
.soft-skills-tabs-container14 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-text14 {
  color: var(--dl-color-app-neutral);
}
.soft-skills-tabs-container15 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.soft-skills-tabs-body-all {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
}
.soft-skills-tabs-left {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-images {
  width: 800px;
  height: 607.9140625px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.soft-skills-tabs-all {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image {
  width: 100%;
  align-self: center;
  object-fit: cover;
}
.soft-skills-tabs-cognitive {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image01 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-basic-skills {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image02 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-all1 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image03 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-all2 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image04 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-pasted-image {
  width: 340px;
  margin-top: 70px;
}
.soft-skills-tabs-container17 {
  display: contents;
}
.soft-skills-tabs-body-groups {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
}
.soft-skills-tabs-left1 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-images1 {
  width: 800px;
  height: 607.9140625px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-all3 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image05 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-cognitive1 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image06 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-basic-skills1 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image07 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-all4 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image08 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-all5 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image09 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-container19 {
  display: contents;
}
.soft-skills-tabs-pasted-image1 {
  width: 340px;
  margin-top: 70px;
}
.soft-skills-tabs-body-list {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: 70px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
}
.soft-skills-tabs-container20 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-images2 {
  width: 800px;
  height: 1300px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-all6 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image10 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-cognitive2 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image11 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-basic-skills2 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image12 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-all7 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image13 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-all8 {
  top: 0px;
  left: 0px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-image14 {
  width: 100%;
  object-fit: cover;
}
.soft-skills-tabs-container22 {
  display: contents;
}
.soft-skills-tabs-pasted-image2 {
  width: 340px;
  margin-top: 70px;
}
.soft-skills-tabs-body-about {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 70px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: 70px;
}
.soft-skills-tabs-container23 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.soft-skills-tabs-buttons {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  display: none;
  margin-top: 70px;
  align-items: flex-start;
  margin-bottom: 70px;
  flex-direction: row;
}
.soft-skills-tabs-button {
  width: 180px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--dl-color-app-lightblue);
}
.soft-skills-tabs-text16 {
  font-style: normal;
  font-weight: 600;
}
.soft-skills-tabs-button1 {
  color: var(--dl-color-app-white);
  width: 180px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--dl-color-app-primary);
}
.soft-skills-tabs-text19 {
  font-style: normal;
  font-weight: 600;
}
.soft-skills-tabs-button2 {
  width: 180px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--dl-color-app-green);
}
.soft-skills-tabs-text22 {
  color: var(--dl-color-app-white);
  font-style: normal;
  font-weight: 600;
}
.soft-skills-tabs-button3 {
  width: 180px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--dl-color-app-orange);
}
.soft-skills-tabs-text25 {
  color: var(--dl-color-app-white);
  font-style: normal;
  font-weight: 600;
}
.soft-skills-tabs-pasted-image3 {
  width: 100%;
  display: none;
  max-width: 800px;
}
.soft-skills-tabs-container24 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.soft-skills-tabs-text28 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.soft-skills-tabs-text29 {
  font-size: 20px;
  line-height: 170%;
}
.soft-skills-tabs-pasted-image4 {
  width: 340px;
}
.soft-skills-tabs-root-class-name {
  top: 0px;
  flex: 1;
  left: 0px;
  background-color: transparent;
}
@media(max-width: 991px) {
  .soft-skills-tabs-button {
    align-items: center;
    justify-content: center;
  }
  .soft-skills-tabs-text16 {
    font-style: normal;
    text-align: center;
    font-weight: 600;
  }
  .soft-skills-tabs-button1 {
    align-items: center;
    justify-content: center;
  }
  .soft-skills-tabs-text19 {
    font-style: normal;
    text-align: center;
    font-weight: 600;
  }
  .soft-skills-tabs-button2 {
    align-items: center;
    justify-content: center;
  }
  .soft-skills-tabs-text22 {
    font-style: normal;
    text-align: center;
    font-weight: 600;
  }
  .soft-skills-tabs-button3 {
    align-items: center;
    justify-content: center;
  }
  .soft-skills-tabs-text25 {
    font-style: normal;
    text-align: center;
    font-weight: 600;
  }
}
</style>
