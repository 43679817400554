<template>
  <div class="coach-details-container">
    <div class="coach-details-container1">
      <span class="coach-details-text">Rachel Smith</span>
      <img
        alt="pastedImage"
        src="/external/pastedimage-1z8o-200h.png"
        class="coach-details-pasted-image"
      />
      <span>0052</span>
      <span>smithyR@google.com</span>
      <span>001</span>
    </div>
    <button-with-icon
      icon="/external/print.svg"
      label="Print"
      rootClassName="button-with-icon-root-class-name"
    ></button-with-icon>
  </div>
</template>

<script>
import ButtonWithIcon from './button-with-icon'

export default {
  name: 'CoachDetails',
  props: {},
  components: {
    ButtonWithIcon,
  },
}
</script>

<style scoped>
.coach-details-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.coach-details-container1 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.coach-details-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.coach-details-pasted-image {
  width: 50px;
}
</style>
