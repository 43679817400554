<template>
  <div class="h3-container"><h3 class="h3-text">{{ h3 }}</h3></div>
</template>

<script>
export default {
  name: 'H3',
  props: {
    h3: {
      type: String,
      default: 'heading 3',
    },
  },
}
</script>

<style scoped>
.h3-container {
  display: flex;
  position: relative;
}
.h3-text {
  font-style: normal;
  font-weight: 600;
}
</style>
