<template>
  <div class="step-container" v-bind:class="rootClassName">
    <div class="step-circle"><span class="step-text">{{ number }}</span></div>
    <p class="step-text1 p32">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'Step',
  props: {
    number: {
      type: String,
      default: '1',
    },
    description: {
      type: String,
      default: 'Click the widget on our REGISTER page',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.step-container {
  flex: 0 0 auto;
  width: 320px;
  display: flex;
  position: relative;
  min-height: 200px;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 30px;
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.step-circle {
  top: -50px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-app-lightblue);
}
.step-text {
  font-size: 50px;
}
.step-text1 {
  text-align: center;
}







@media(max-width: 767px) {
  .step-container {
    width: 100%;
  }
  .step-text {
    font-size: 40px;
  }
  .step-root-class-name {
    min-height: var(--dl-size-size-large);
  }
  .step-root-class-name1 {
    min-height: var(--dl-size-size-large);
  }
  .step-root-class-name2 {
    width: 100%;
    align-self: center;
    min-height: var(--dl-size-size-large);
  }
  .step-root-class-name3 {
    width: 400px;
    align-self: center;
    min-height: var(--dl-size-size-large);
  }
  .step-root-class-name4 {
    width: 400px;
    align-self: center;
    min-height: var(--dl-size-size-large);
  }
  .step-root-class-name5 {
    width: 400px;
    align-self: center;
    min-height: var(--dl-size-size-large);
  }
  .step-root-class-name6 {
    width: 400px;
    min-height: var(--dl-size-size-large);
  }
}
</style>
