<template>
    <!-- Testimonials Scroller -->
    <section class="testimonials_scroller_wrapper">
        <div class="testimonials_scroller_container">
            <div class="testimonials_scroller">
                <div v-for="item in testimonialsData" class="testimonials_scroller_item">
                    <span class="testimonials-text">{{ item.text }}</span>
                    <span class="testimonials-author">{{ item.author }}</span>
                </div>
                <!-- Copy of the previous items -->
                <div v-for="item in testimonialsData" class="testimonials_scroller_item">
                    <span class="testimonials-text">{{ item.text }}</span>
                    <span class="testimonials-author">{{ item.author }}</span>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
  name: 'Testimonials',
  data() {
    return {
      testimonialsData: [
        {
            text:`“I find the Wisepath approach for developing Soft Skills using gaming and AI as very interesting. There are several areas in today's society where the tools offered by Wisepath really can make a difference. One of the things fascinating me is that as more I learn about the product, as wider usage I see. Ranging from being a useful tool to find the right employee for the right position to be a tool to prevent school dropout, I am very sure we have just seen the beginning of a very interesting journey.”`, 
            author:`Geir Bjørndal, General Manager EMEA Future TV`
        },{
            text:`“The work that you and your team have done in helping companies, universities, and individual learners to understand 21st century skills are more pertinent than ever given the warnings we read daily, justified and imagined, around “AI will take everyone's job.”`, 
            author:`William R. Cockayne, Ph.D., Award-winning author of “Building Moonshots”`
        },{
            text:`“In the era of soft skills, SkillQuest represent a valid and reliable tool to improve individuals' soft skills through online gaming. Its robust scientific background, its easy-to-use interface, and its game based approach promote the assessment and the development of soft skills which in turn help people develop their personal and work skills. Wherever you are, whenever you want, SkillQuest is a friendly tool that promotes your development.”`, 
            author:`Alessandro Gennaro, Ph.D. in Psychology and Associate Professor`
        },{
            text:`“The emergence of powerful generative AI modelling creates an environment of enormous potential, but also enormous uncertainty. As individuals, as teams, as corporations, we all need to be exploring how we can capitalize on the impending dramatic shift in the way we work and the way we manage a workforce. The SkillQuest platform provides a proven method to develop a clear understanding of an individual's personality. Whether it is developing the right personality skills, hiring, and retaining the ideal team, finding the ideal job, or even profiling your customer base, SkillQuest offers a groundbreaking solution to achieve your objectives.”`,
            author:`David Hurley - VP Sales Operations and GTM Transformation OpenText`
        },
      ],
    }
  },
}
</script>
  
<style scoped>
.testimonials_scroller_wrapper {
  /* How long one slide is visible on screen (from entering screen to leaving it) */
  --scrolling-gallery-testimonials-duration: 5s;
  /* How many items are to scroll */
  --scrolling-gallery-testimonials-total: 4;
  /* How many items we want to see on screen at once */
  --scrolling-gallery-testimonials-visible: 3;
  /* How much items are wide */
  --scrolling-gallery-testimonials-width: var(--dl-size-size-xlarge);
  /* How much items are spaced */
  --scrolling-gallery-testimonials-margin: 1.7vw;
  overflow: hidden;
  will-change: transform;
}
.testimonials_scroller {
  animation-duration: calc(var(--scrolling-gallery-testimonials-duration, 1s) / var(--scrolling-gallery-testimonials-visible) * var(--scrolling-gallery-testimonials-total));
  animation-timing-function: linear;
  animation-name: testimonials-scrolling-gallery;
  animation-iteration-count: infinite;
  display: flex;
  white-space: nowrap;
}
.testimonials_scroller_container {
  /* Without this, scroll will jump on desktop if any vertical scrollbar is shown */
  width: 100vw;
  padding-bottom: 5vh;
}
.testimonials_scroller_item {
  flex: 1 0 calc(100% / var(--scrolling-gallery-testimonials-visible));
  /* Without this, block elements will take width from their contents and thus making wrong calculations,
     so this just force elements to take only exact part of the container (screen) and equal for all */
  width: 0px;
  /* If you want to have it continuous without any spaces, remove two lines below */
  box-sizing: border-box;
  margin-top: 5vh;
  margin-inline: var(--scrolling-gallery-testimonials-margin);
  min-width: var(--scrolling-gallery-testimonials-width);
  min-height: 15vh;
  display: flex;
  box-shadow: 0px 0px 4em 0px rgba(136, 201, 241, 0.5);
  border-radius: 60px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
  padding: var(--dl-space-space-twounits);
}
@keyframes testimonials-scrolling-gallery {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(var(--scrolling-gallery-testimonials-total) * (-96.5vw - var(--scrolling-gallery-testimonials-margin) * var(--scrolling-gallery-testimonials-total) * 2) / var(--scrolling-gallery-testimonials-visible)));
  }
}
.testimonials_scroller:hover,
.testimonials_scroller:focus {
  animation-play-state: paused;
}
.testimonials-text {
  color: rgba(5, 47, 156, 1);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
  white-space: break-spaces;
}
.testimonials-author {
  color: rgba(5, 47, 156, 1);
  height: auto;
  font-size: 22px;
  align-self: flex-end;
  margin-top: var(--dl-space-space-twounits);
  text-align: right;
  font-weight: 600;
  white-space: break-spaces;
}
@media(max-width: 1440px) {
  .testimonials_scroller_wrapper {
    --scrolling-gallery-testimonials-visible: 2;
  }
  .testimonials-text {
    font-size: 18px;
  }
  .testimonials-author {
    font-size: 20px;
  }
}
@media(max-width: 1080px) {
  .testimonials_scroller_wrapper {
    --scrolling-gallery-testimonials-visible: 1;
  }
  .testimonials-text {
    font-size: 16px;
  }
  .testimonials-author {
    font-size: 18px;
  }
}
@media(max-width: 860px) {
}
@media(max-width: 700px) {
  .testimonials-text {
    font-size: 13px;
  }
  .testimonials-author {
    font-size: 15px;
  }
}
@media(max-width: 520px) {
  .testimonials-text {
    font-size: 11px;
  }
  .testimonials-author {
    font-size: 13px;
  }
}
/*
--dl-size-size-xxlarge: 288px;
--dl-size-size-xlarge: 192px;
--dl-size-size-large: 144px;
--dl-size-size-medium: 96px;
--dl-size-size-small: 48px;
--dl-size-size-xsmall: 16px;
*/
</style>
