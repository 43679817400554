<template>
  <div class="app-coach-profile-member-games-container">
    <header-app rootClassName="header-app-root-class-name8"></header-app>
    <mobile-screen rootClassName="mobile-screen-root-class-name1"></mobile-screen>
    <div class="desktopApp">
      <div class="app-coach-profile-member-games-left">
        <router-link
          to="/app-coach-profile"
          class="app-coach-profile-member-games-navlink"
        >
          <div
            id="profile"
            class="app-coach-profile-member-games-container02 left-menu-btn"
          >
            <div class="icon-wrapper">
              <div>
                <div class="app-coach-profile-member-games-container05">
                  <DangerousHTML
                    html="<svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M6.66699 7.50016C6.66699 9.34111 8.15938 10.8335 10.0003 10.8335C11.8413 10.8335 13.3337 9.34111 13.3337 7.50016C13.3337 5.65921 11.8413 4.16683 10.0003 4.16683C8.15938 4.16683 6.66699 5.65921 6.66699 7.50016ZM8.33366 7.50016C8.33366 8.42064 9.07985 9.16683 10.0003 9.16683C10.9208 9.16683 11.667 8.42064 11.667 7.50016C11.667 6.57969 10.9208 5.8335 10.0003 5.8335C9.07985 5.8335 8.33366 6.57969 8.33366 7.50016Z'
      fill='#5F5F5F'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M10.0003 0.833496C15.0629 0.833496 19.167 4.93755 19.167 10.0002C19.167 15.0628 15.0629 19.1668 10.0003 19.1668C4.93772 19.1668 0.83366 15.0628 0.83366 10.0002C0.83366 4.93755 4.93772 0.833496 10.0003 0.833496ZM17.5003 10.0002C17.5003 11.742 16.9065 13.3452 15.9103 14.6182C14.54 12.8242 12.3784 11.6668 9.94659 11.6668C7.5463 11.6668 5.40936 12.7944 4.03658 14.5487C3.0728 13.287 2.50033 11.7105 2.50033 10.0002C2.50033 5.85803 5.85819 2.50016 10.0003 2.50016C14.1425 2.50016 17.5003 5.85803 17.5003 10.0002ZM10.0003 17.5002C11.7922 17.5002 13.4373 16.8718 14.7273 15.8234C13.6724 14.3178 11.9244 13.3335 9.94659 13.3335C7.99329 13.3335 6.26422 14.2936 5.20552 15.7675C6.50543 16.8494 8.17689 17.5002 10.0003 17.5002Z'
      fill='#5F5F5F'
    />
  </svg>
  "
                  ></DangerousHTML>
                </div>
              </div>
            </div>
            <span class="app-coach-profile-member-games-text">Profile</span>
          </div>
        </router-link>
        <div id="Notifications" class="left-menu-btn">
          <div class="icon-wrapper">
            <div>
              <div class="app-coach-profile-member-games-container09">
                <DangerousHTML
                  html="<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <g clip-path='url(#clip0_49_6427)'>
  <path d='M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17Z' fill='#5F5F5F'/>
  </g>
  <defs>
  <clipPath id='clip0_49_6427'>
  <rect width='24' height='24' fill='white'/>
  </clipPath>
  </defs>
  </svg>
  "
                ></DangerousHTML>
              </div>
            </div>
          </div>
          <span class="app-coach-profile-member-games-text01">Notifications</span>
        </div>
        <div id="Billing" class="left-menu-btn">
          <div class="icon-wrapper">
            <div>
              <div class="app-coach-profile-member-games-container13">
                <DangerousHTML
                  html="<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path d='M15.25 16.5H12.75C12.5938 16.5 12.5 16.625 12.5 16.75V17.25C12.5 17.4062 12.5938 17.5 12.75 17.5H15.25C15.375 17.5 15.5 17.4062 15.5 17.25V16.75C15.5 16.625 15.375 16.5 15.25 16.5ZM8.5 11.5V14.5C8.5 14.7812 8.71875 15 9 15H15C15.25 15 15.5 14.7812 15.5 14.5V11.5C15.5 11.25 15.25 11 15 11H9C8.71875 11 8.5 11.25 8.5 11.5ZM9.5 12H14.5V14H9.5V12ZM17.5312 7.0625L14.9062 4.46875C14.625 4.1875 14.25 4 13.875 4H7.46875C6.65625 4.03125 6 4.6875 6 5.53125V18.5312C6 19.3438 6.65625 20 7.46875 20H16.4688C17.3125 20 18 19.3438 18 18.5312V8.125C18 7.75 17.8125 7.34375 17.5312 7.0625ZM13.9688 5.625L16.375 8.03125H13.9688V5.625ZM16.5 18.5312H7.46875V5.53125H12.4688V8.78125C12.4688 9.1875 12.8125 9.53125 13.2188 9.53125H16.5V18.5312ZM8.75 7.5H11.25C11.375 7.5 11.5 7.40625 11.5 7.25V6.75C11.5 6.625 11.375 6.5 11.25 6.5H8.75C8.59375 6.5 8.5 6.625 8.5 6.75V7.25C8.5 7.40625 8.59375 7.5 8.75 7.5ZM8.75 9.5H11.25C11.375 9.5 11.5 9.40625 11.5 9.25V8.75C11.5 8.625 11.375 8.5 11.25 8.5H8.75C8.59375 8.5 8.5 8.625 8.5 8.75V9.25C8.5 9.40625 8.59375 9.5 8.75 9.5Z' fill='#5F5F5F'/>
  </svg>
  "
                ></DangerousHTML>
              </div>
            </div>
          </div>
          <span class="app-coach-profile-member-games-text02">
            <span>Billing</span>
            <br />
          </span>
        </div>
        <coach-teams-card></coach-teams-card>
        <div id="Billing" class="left-menu-btn">
          <span class="app-coach-profile-member-games-text05">
            <span>Soft Skills Groups</span>
            <br />
          </span>
        </div>
        <div id="Billing" class="left-menu-btn">
          <span class="app-coach-profile-member-games-text08">
            <span>Define Profession Pattern</span>
            <br />
          </span>
        </div>
      </div>
      <div class="app-coach-profile-member-games-right">
        <coach-details></coach-details>
        <div class="app-coach-profile-member-games-tabs">
          <router-link
            to="/app-coach-profile-member-skills"
            class="app-coach-profile-member-games-navlink1"
          >
            <div class="app-coach-profile-member-games-container16">
              <span class="app-coach-profile-member-games-text11">
                Soft Skills
              </span>
            </div>
          </router-link>
          <router-link
            to="/app-coach-profile-member-improvement"
            class="app-coach-profile-member-games-navlink2"
          >
            <div class="app-coach-profile-member-games-container17">
              <span class="app-coach-profile-member-games-improvement">
                Improvement
              </span>
            </div>
          </router-link>
          <div class="app-coach-profile-member-games-container18">
            <span class="app-coach-profile-member-games-text12">Games</span>
          </div>
        </div>
        <div class="app-coach-profile-member-games-games">
          <div class="app-coach-profile-member-games-blue-border"></div>
          <span class="app-coach-profile-member-games-text13">Date began</span>
          <span class="app-coach-profile-member-games-text14">Date began</span>
          <span class="app-coach-profile-member-games-text15">Hours played</span>
          <span class="app-coach-profile-member-games-text16">Achievements</span>
          <div class="app-coach-profile-member-games-container19 shadow">
            <img
              alt="pastedImage"
              src="/external/pastedimage-e89-200h.png"
              class="app-coach-profile-member-games-pasted-image"
            />
            <div class="app-coach-profile-member-games-container20">
              <span class="app-coach-profile-member-games-text17">Gravitas</span>
              <ul class="list">
                <li class="app-coach-profile-member-games-li list-item">
                  <span class="app-coach-profile-member-games-text18">
                    Cognitive Flexibility
                  </span>
                </li>
                <li class="app-coach-profile-member-games-li01 list-item">
                  <span class="app-coach-profile-member-games-text19">
                    People/Team Mgmnt
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <span class="app-coach-profile-member-games-text20">03/06/23</span>
          <span class="app-coach-profile-member-games-text21">
            16 hours played
          </span>
          <div class="app-coach-profile-member-games-container21"></div>
          <div class="app-coach-profile-member-games-game-train-valley shadow">
            <img
              alt="pastedImage"
              src="/external/pastedimage-fvkc-200h.png"
              class="app-coach-profile-member-games-pasted-image1"
            />
            <div class="app-coach-profile-member-games-container22">
              <span class="app-coach-profile-member-games-text22">
                Train Valley
              </span>
              <ul class="list">
                <li class="app-coach-profile-member-games-li02 list-item">
                  <span class="app-coach-profile-member-games-text23">
                    Organizational Skills
                  </span>
                </li>
                <li class="app-coach-profile-member-games-li03 list-item">
                  <span class="app-coach-profile-member-games-text24">
                    Critical Thinking
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <span class="app-coach-profile-member-games-text25">03/06/23</span>
          <span class="app-coach-profile-member-games-text26">
            16 hours played
          </span>
          <div class="app-coach-profile-member-games-container23"></div>
          <div class="app-coach-profile-member-games-game-gems-of-war shadow">
            <img
              alt="pastedImage"
              src="/external/pastedimage-xgsh-200h.png"
              class="app-coach-profile-member-games-pasted-image2"
            />
            <div class="app-coach-profile-member-games-container24">
              <span class="app-coach-profile-member-games-text27">
                Gems of War
              </span>
              <ul class="list">
                <li class="app-coach-profile-member-games-li04 list-item">
                  <span class="app-coach-profile-member-games-text28">
                    Organizational Skills
                  </span>
                </li>
                <li class="app-coach-profile-member-games-li05 list-item">
                  <span class="app-coach-profile-member-games-text29">
                    Critical Thinking
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <span class="app-coach-profile-member-games-text30">03/06/23</span>
          <span class="app-coach-profile-member-games-text31">
            16 hours played
          </span>
          <div class="app-coach-profile-member-games-container25"></div>
          <div class="app-coach-profile-member-games-game-cities-skylines shadow">
            <img
              alt="pastedImage"
              src="/external/pastedimage-s64n-200h.png"
              class="app-coach-profile-member-games-pasted-image3"
            />
            <div class="app-coach-profile-member-games-container26">
              <span class="app-coach-profile-member-games-text32">
                Cities Skylines
              </span>
              <ul class="list">
                <li class="app-coach-profile-member-games-li06 list-item">
                  <span class="app-coach-profile-member-games-text33">
                    Self-discipline
                  </span>
                </li>
                <li class="app-coach-profile-member-games-li07 list-item">
                  <span class="app-coach-profile-member-games-text34">
                    Logical Reasoning
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <span class="app-coach-profile-member-games-text35">03/06/23</span>
          <span class="app-coach-profile-member-games-text36">
            16 hours played
          </span>
          <div class="app-coach-profile-member-games-container27"></div>
          <div
            class="app-coach-profile-member-games-game-cell-to-singularity shadow"
          >
            <img
              alt="pastedImage"
              src="/external/pastedimage-eqee-200h.png"
              class="app-coach-profile-member-games-pasted-image4"
            />
            <div class="app-coach-profile-member-games-container28">
              <span class="app-coach-profile-member-games-text37">
                Cell to Singularity
              </span>
              <ul class="list">
                <li class="app-coach-profile-member-games-li08 list-item">
                  <span class="app-coach-profile-member-games-text38">
                    Mental Flexibility
                  </span>
                </li>
                <li class="app-coach-profile-member-games-li09 list-item">
                  <span class="app-coach-profile-member-games-text39">
                    People/Team Mgmnt
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <span class="app-coach-profile-member-games-text40">03/06/23</span>
          <span class="app-coach-profile-member-games-text41">
            16 hours played
          </span>
          <div class="app-coach-profile-member-games-container29"></div>
          <div class="app-coach-profile-member-games-game-minion-masters shadow">
            <div class="app-coach-profile-member-games-container30">
              <img
                alt="pastedImage"
                src="/external/pastedimage-gwwc-200h.png"
                class="app-coach-profile-member-games-pasted-image5"
              />
            </div>
            <div class="app-coach-profile-member-games-container31">
              <span class="app-coach-profile-member-games-text42">
                Minion Maters
              </span>
              <ul class="list">
                <li class="app-coach-profile-member-games-li10 list-item">
                  <span class="app-coach-profile-member-games-text43">
                    Cognitive Flexibility
                  </span>
                </li>
                <li class="app-coach-profile-member-games-li11 list-item">
                  <span class="app-coach-profile-member-games-text44">
                    Self-Confidence
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <span class="app-coach-profile-member-games-text45">03/06/23</span>
          <span class="app-coach-profile-member-games-text46">
            16 hours played
          </span>
          <div class="app-coach-profile-member-games-container32"></div>
        </div>
        <img
          alt="pastedImage"
          src="/external/pastedimage-og2f-1100w.png"
          class="app-coach-profile-member-games-pasted-image6"
        />
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name14"></app-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import HeaderApp from '../components/header-app'
import MobileScreen from '../components/mobile-screen'
import CoachTeamsCard from '../components/coach-teams-card'
import CoachDetails from '../components/coach-details'
import AppFooter from '../components/footer'

export default {
  name: 'AppCoachProfileMemberGames',
  components: {
    HeaderApp,
    MobileScreen,
    DangerousHTML,
    CoachTeamsCard,
    CoachDetails,
    AppFooter,
  },
  metaInfo: {
    title: 'App-Coach-Profile-Member-Games - SkillQuest',
    meta: [
      {
        property: 'og:title',
        content: 'App-Coach-Profile-Member-Games - SkillQuest',
      },
    ],
  },
}
</script>

<style scoped>
.app-coach-profile-member-games-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app-coach-profile-member-games-left {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 262px;
  height: auto;
  display: flex;
  position: relative;
  padding-top: var(--dl-space-space-fourunits);
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-fourunits);
  flex-direction: column;
  background-color: var(--dl-color-app-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
}
.app-coach-profile-member-games-navlink {
  display: contents;
}
.app-coach-profile-member-games-container02 {
  text-decoration: none;
}
.app-coach-profile-member-games-container05 {
  display: contents;
}
.app-coach-profile-member-games-text {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-member-games-container09 {
  display: contents;
}
.app-coach-profile-member-games-text01 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-member-games-container13 {
  display: contents;
}
.app-coach-profile-member-games-text02 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-member-games-text05 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-member-games-text08 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-member-games-right {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: flex-start;
}
.app-coach-profile-member-games-tabs {
  gap: var(--dl-space-space-sixunits);
  flex: 0 0 auto;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 2px;
  flex-direction: row;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
}
.app-coach-profile-member-games-navlink1 {
  display: contents;
}
.app-coach-profile-member-games-container16 {
  height: 42px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.app-coach-profile-member-games-text11 {
  color: var(--dl-color-app-neutral);
  font-size: 18px;
}
.app-coach-profile-member-games-navlink2 {
  display: contents;
}
.app-coach-profile-member-games-container17 {
  height: 42px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.app-coach-profile-member-games-improvement {
  color: var(--dl-color-app-neutral);
  font-size: 18px;
}
.app-coach-profile-member-games-container18 {
  height: 42px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-primary);
  border-width: 2px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.app-coach-profile-member-games-text12 {
  color: var(--dl-color-app-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.app-coach-profile-member-games-games {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.app-coach-profile-member-games-blue-border {
  top: 64px;
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  flex-direction: column;
}
.app-coach-profile-member-games-text13 {
  color: transparent;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.app-coach-profile-member-games-text14 {
  height: 32px;
  display: flex;
  font-size: 18px;
  font-style: normal;
  margin-top: 16px;
  text-align: center;
  align-items: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-twounits);
}
.app-coach-profile-member-games-text15 {
  height: 32px;
  display: flex;
  font-size: 18px;
  font-style: normal;
  margin-top: 16px;
  text-align: center;
  align-items: center;
  font-weight: 500;
  margin-bottom: 16px;
}
.app-coach-profile-member-games-text16 {
  height: 32px;
  display: flex;
  font-size: 18px;
  font-style: normal;
  margin-top: 16px;
  text-align: center;
  align-items: center;
  font-weight: 500;
  margin-bottom: 16px;
}
.app-coach-profile-member-games-container19 {
  height: 140px;
}
.app-coach-profile-member-games-pasted-image {
  width: 100px;
  height: 100px;
  align-self: center;
}
.app-coach-profile-member-games-container20 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-coach-profile-member-games-text17 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-coach-profile-member-games-li {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text18 {
  font-weight: 300;
}
.app-coach-profile-member-games-li01 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text19 {
  font-weight: 300;
}
.app-coach-profile-member-games-text20 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-text21 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-container21 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-game-train-valley {
  height: 140px;
}
.app-coach-profile-member-games-pasted-image1 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.app-coach-profile-member-games-container22 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-coach-profile-member-games-text22 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-coach-profile-member-games-li02 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text23 {
  font-weight: 300;
}
.app-coach-profile-member-games-li03 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text24 {
  font-weight: 300;
}
.app-coach-profile-member-games-text25 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-text26 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-container23 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-game-gems-of-war {
  height: 140px;
}
.app-coach-profile-member-games-pasted-image2 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.app-coach-profile-member-games-container24 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-coach-profile-member-games-text27 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-coach-profile-member-games-li04 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text28 {
  font-weight: 300;
}
.app-coach-profile-member-games-li05 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text29 {
  font-weight: 300;
}
.app-coach-profile-member-games-text30 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-text31 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-container25 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-game-cities-skylines {
  height: 140px;
}
.app-coach-profile-member-games-pasted-image3 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.app-coach-profile-member-games-container26 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-coach-profile-member-games-text32 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-coach-profile-member-games-li06 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text33 {
  font-weight: 300;
}
.app-coach-profile-member-games-li07 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text34 {
  font-weight: 300;
}
.app-coach-profile-member-games-text35 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-text36 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-container27 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-game-cell-to-singularity {
  height: 140px;
}
.app-coach-profile-member-games-pasted-image4 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.app-coach-profile-member-games-container28 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-coach-profile-member-games-text37 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-coach-profile-member-games-li08 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text38 {
  font-weight: 300;
}
.app-coach-profile-member-games-li09 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text39 {
  font-weight: 300;
}
.app-coach-profile-member-games-text40 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-text41 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-container29 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-game-minion-masters {
  height: 140px;
}
.app-coach-profile-member-games-container30 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.app-coach-profile-member-games-pasted-image5 {
  width: 96px;
}
.app-coach-profile-member-games-container31 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-coach-profile-member-games-text42 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: center;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.app-coach-profile-member-games-li10 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text43 {
  font-weight: 300;
}
.app-coach-profile-member-games-li11 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.app-coach-profile-member-games-text44 {
  font-weight: 300;
}
.app-coach-profile-member-games-text45 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-text46 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-container32 {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.app-coach-profile-member-games-pasted-image6 {
  width: 1099px;
  align-self: center;
}
</style>
