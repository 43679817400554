<template>
  <div class="app-coach-profile-teams-container">
    <header-app rootClassName="header-app-root-class-name4"></header-app>
    <mobile-screen rootClassName="mobile-screen-root-class-name4"></mobile-screen>
    <div class="desktopApp">
      <div class="app-coach-profile-teams-left">
        <router-link
          to="/app-coach-profile"
          class="app-coach-profile-teams-navlink"
        >
          <div
            id="profile"
            class="app-coach-profile-teams-container02 left-menu-btn"
          >
            <div class="icon-wrapper">
              <div>
                <div class="app-coach-profile-teams-container05">
                  <DangerousHTML
                    html="<svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M6.66699 7.50016C6.66699 9.34111 8.15938 10.8335 10.0003 10.8335C11.8413 10.8335 13.3337 9.34111 13.3337 7.50016C13.3337 5.65921 11.8413 4.16683 10.0003 4.16683C8.15938 4.16683 6.66699 5.65921 6.66699 7.50016ZM8.33366 7.50016C8.33366 8.42064 9.07985 9.16683 10.0003 9.16683C10.9208 9.16683 11.667 8.42064 11.667 7.50016C11.667 6.57969 10.9208 5.8335 10.0003 5.8335C9.07985 5.8335 8.33366 6.57969 8.33366 7.50016Z'
      fill='#5F5F5F'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M10.0003 0.833496C15.0629 0.833496 19.167 4.93755 19.167 10.0002C19.167 15.0628 15.0629 19.1668 10.0003 19.1668C4.93772 19.1668 0.83366 15.0628 0.83366 10.0002C0.83366 4.93755 4.93772 0.833496 10.0003 0.833496ZM17.5003 10.0002C17.5003 11.742 16.9065 13.3452 15.9103 14.6182C14.54 12.8242 12.3784 11.6668 9.94659 11.6668C7.5463 11.6668 5.40936 12.7944 4.03658 14.5487C3.0728 13.287 2.50033 11.7105 2.50033 10.0002C2.50033 5.85803 5.85819 2.50016 10.0003 2.50016C14.1425 2.50016 17.5003 5.85803 17.5003 10.0002ZM10.0003 17.5002C11.7922 17.5002 13.4373 16.8718 14.7273 15.8234C13.6724 14.3178 11.9244 13.3335 9.94659 13.3335C7.99329 13.3335 6.26422 14.2936 5.20552 15.7675C6.50543 16.8494 8.17689 17.5002 10.0003 17.5002Z'
      fill='#5F5F5F'
    />
  </svg>
  "
                  ></DangerousHTML>
                </div>
              </div>
            </div>
            <span class="app-coach-profile-teams-text">Profile</span>
          </div>
        </router-link>
        <div id="Notifications" class="left-menu-btn">
          <div class="icon-wrapper">
            <div>
              <div class="app-coach-profile-teams-container09">
                <DangerousHTML
                  html="<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <g clip-path='url(#clip0_49_6427)'>
  <path d='M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17Z' fill='#5F5F5F'/>
  </g>
  <defs>
  <clipPath id='clip0_49_6427'>
  <rect width='24' height='24' fill='white'/>
  </clipPath>
  </defs>
  </svg>
  "
                ></DangerousHTML>
              </div>
            </div>
          </div>
          <span class="app-coach-profile-teams-text01">Notifications</span>
        </div>
        <div id="Billing" class="left-menu-btn">
          <div class="icon-wrapper">
            <div>
              <div class="app-coach-profile-teams-container13">
                <DangerousHTML
                  html="<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path d='M15.25 16.5H12.75C12.5938 16.5 12.5 16.625 12.5 16.75V17.25C12.5 17.4062 12.5938 17.5 12.75 17.5H15.25C15.375 17.5 15.5 17.4062 15.5 17.25V16.75C15.5 16.625 15.375 16.5 15.25 16.5ZM8.5 11.5V14.5C8.5 14.7812 8.71875 15 9 15H15C15.25 15 15.5 14.7812 15.5 14.5V11.5C15.5 11.25 15.25 11 15 11H9C8.71875 11 8.5 11.25 8.5 11.5ZM9.5 12H14.5V14H9.5V12ZM17.5312 7.0625L14.9062 4.46875C14.625 4.1875 14.25 4 13.875 4H7.46875C6.65625 4.03125 6 4.6875 6 5.53125V18.5312C6 19.3438 6.65625 20 7.46875 20H16.4688C17.3125 20 18 19.3438 18 18.5312V8.125C18 7.75 17.8125 7.34375 17.5312 7.0625ZM13.9688 5.625L16.375 8.03125H13.9688V5.625ZM16.5 18.5312H7.46875V5.53125H12.4688V8.78125C12.4688 9.1875 12.8125 9.53125 13.2188 9.53125H16.5V18.5312ZM8.75 7.5H11.25C11.375 7.5 11.5 7.40625 11.5 7.25V6.75C11.5 6.625 11.375 6.5 11.25 6.5H8.75C8.59375 6.5 8.5 6.625 8.5 6.75V7.25C8.5 7.40625 8.59375 7.5 8.75 7.5ZM8.75 9.5H11.25C11.375 9.5 11.5 9.40625 11.5 9.25V8.75C11.5 8.625 11.375 8.5 11.25 8.5H8.75C8.59375 8.5 8.5 8.625 8.5 8.75V9.25C8.5 9.40625 8.59375 9.5 8.75 9.5Z' fill='#5F5F5F'/>
  </svg>
  "
                ></DangerousHTML>
              </div>
            </div>
          </div>
          <span class="app-coach-profile-teams-text02">
            <span>Billing</span>
            <br />
          </span>
        </div>
        <coach-teams-card></coach-teams-card>
        <div id="Billing" class="left-menu-btn">
          <span class="app-coach-profile-teams-text05">
            <span>Soft Skills Groups</span>
            <br />
          </span>
        </div>
        <div id="Billing" class="left-menu-btn">
          <span class="app-coach-profile-teams-text08">
            <span>Define Profession Pattern</span>
            <br />
          </span>
        </div>
      </div>
      <div class="app-coach-profile-teams-right">
        <div class="app-coach-profile-teams-create-teams">
          <span class="app-coach-profile-teams-text11">Create Teams</span>
          <div class="app-coach-profile-teams-container16">
            <span class="app-coach-profile-teams-text12">Name of Teams</span>
            <div class="app-coach-profile-teams-container17">
              <app-filter label="Name of group"></app-filter>
              <app-filter label="Owner of group"></app-filter>
              <app-filter label="Email address"></app-filter>
              <div class="app-coach-profile-teams-container18">
                <button-with-icon></button-with-icon>
              </div>
            </div>
            <span class="app-coach-profile-teams-text13">Teams</span>
            <div class="app-coach-profile-teams-container19">
              <span class="cell-head">ID#</span>
              <span class="cell-head">Name</span>
              <span class="cell-head">
                <span>Owner</span>
                <br />
              </span>
              <span class="cell-head">Owner&apos;s email</span>
              <span class="cell-head">Date Created</span>
              <span class="cell">001</span>
              <span class="cell">Operations</span>
              <span class="cell">Betty McDonald</span>
              <span class="cell">gianluca.ferremi@wisepath.ai</span>
              <div class="app-coach-profile-teams-container20 cell"></div>
              <span class="cell">002</span>
              <span class="cell">Engineering</span>
              <span class="cell">Shareev Gupad</span>
              <span class="cell">abell1989@google.com</span>
              <div class="app-coach-profile-teams-container21 cell"></div>
            </div>
          </div>
        </div>
        <div class="app-coach-profile-teams-invite-new-user">
          <span class="app-coach-profile-teams-text29">Invite New User</span>
          <div class="app-coach-profile-teams-container22">
            <span class="app-coach-profile-teams-text30">Invite by email</span>
            <div class="app-coach-profile-teams-container23">
              <app-filter label="Email address"></app-filter>
              <app-filter label="Name"></app-filter>
              <div class="app-coach-profile-teams-container24">
                <app-filter label="Team"></app-filter>
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-jrgt.svg"
                  class="app-coach-profile-teams-pasted-image"
                />
              </div>
              <div class="app-coach-profile-teams-container25">
                <div class="app-coach-profile-teams-container26">
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-qw.svg"
                    class="app-coach-profile-teams-pasted-image01"
                  />
                  <span class="app-coach-profile-teams-text31">Invite</span>
                </div>
              </div>
            </div>
            <span class="app-coach-profile-teams-text32">Invite by link</span>
            <span class="app-coach-profile-teams-text33">
              https://softskills.games/en/sign-up/group?etcetera_ectectera
            </span>
            <div class="app-coach-profile-teams-container27">
              <span class="app-coach-profile-teams-text34">
                <span>Invitations</span>
                <br />
              </span>
              <div class="app-coach-profile-teams-container28">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-qsfw.svg"
                  class="app-coach-profile-teams-pasted-image02"
                />
                <span>Download invitations</span>
              </div>
            </div>
            <div class="app-coach-profile-teams-container29">
              <span class="cell-head">ID#</span>
              <span class="cell-head">Name</span>
              <span class="cell-head">
                <span>Email</span>
                <br />
              </span>
              <span class="cell-head">Date Sent</span>
              <span class="cell-head">Date Accepted</span>
              <span class="cell">517</span>
              <span class="cell">Gianluca</span>
              <span class="cell">gianluca.ferremi@wisepath.ai </span>
              <span class="cell">03/06/23</span>
              <div class="app-coach-profile-teams-container30 cell"></div>
              <span class="cell">518</span>
              <span class="cell">Annabell</span>
              <span class="cell">abell1989@google.com</span>
              <span class="cell">03/06/23</span>
              <div class="app-coach-profile-teams-container31 cell"></div>
            </div>
          </div>
        </div>
        <div class="app-coach-profile-teams-manage-team-members">
          <span class="app-coach-profile-teams-text53">Manage Team Members</span>
          <router-link
            to="/app-coach-profile-member-skills"
            class="app-coach-profile-teams-navlink1"
          >
            <div class="app-coach-profile-teams-container32">
              <div class="app-coach-profile-teams-container33">
                <div class="app-coach-profile-teams-select">
                  <app-filter label="Operations"></app-filter>
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-jrgt.svg"
                    class="app-coach-profile-teams-pasted-image03"
                  />
                </div>
                <div class="app-coach-profile-teams-container34">
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-qsfw.svg"
                    class="app-coach-profile-teams-pasted-image04"
                  />
                  <span>Download team members</span>
                </div>
              </div>
              <span class="app-coach-profile-teams-text55">
                <span>Operations</span>
                <br />
              </span>
              <div class="app-coach-profile-teams-container35">
                <span class="cell-head">ID#</span>
                <span class="cell-head">Name</span>
                <span class="cell-head">Email</span>
                <span class="cell-head">Date</span>
                <div class="cell-head"></div>
                <span class="cell">051</span>
                <span class="cell">Marty McFly</span>
                <span class="cell">marty.mcfly@hotmail.com</span>
                <span class="cell">03/06/23</span>
                <div class="app-coach-profile-teams-container37 cell">
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-ye7r.svg"
                    class="app-coach-profile-teams-pasted-image05"
                  />
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-h4ik.svg"
                    class="app-coach-profile-teams-pasted-image06"
                  />
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-yutc.svg"
                    class="app-coach-profile-teams-pasted-image07"
                  />
                </div>
                <span class="cell">052</span>
                <span class="cell">Rachel Smith</span>
                <span class="cell">smithyR@google.com</span>
                <span class="cell">03/06/23</span>
                <div class="app-coach-profile-teams-container38 cell">
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-ye7r.svg"
                    class="app-coach-profile-teams-pasted-image08"
                  />
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-h4ik.svg"
                    class="app-coach-profile-teams-pasted-image09"
                  />
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-yutc.svg"
                    class="app-coach-profile-teams-pasted-image10"
                  />
                </div>
                <span class="cell">052</span>
                <span class="cell">James Earl Jones</span>
                <span class="cell">deepvoice@JEJ.com</span>
                <span class="cell">03/06/23</span>
                <div class="app-coach-profile-teams-container39 cell">
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-ye7r.svg"
                    class="app-coach-profile-teams-pasted-image11"
                  />
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-h4ik.svg"
                    class="app-coach-profile-teams-pasted-image12"
                  />
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-yutc.svg"
                    class="app-coach-profile-teams-pasted-image13"
                  />
                </div>
                <span class="cell">052</span>
                <span class="cell">Luke Skywalker</span>
                <span class="cell">LukeVader@resistance.com</span>
                <span class="cell">03/06/23</span>
                <div class="app-coach-profile-teams-container40 cell">
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-ye7r.svg"
                    class="app-coach-profile-teams-pasted-image14"
                  />
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-h4ik.svg"
                    class="app-coach-profile-teams-pasted-image15"
                  />
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-yutc.svg"
                    class="app-coach-profile-teams-pasted-image16"
                  />
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="app-coach-profile-teams-user-activity">
          <span class="app-coach-profile-teams-text78">User Activity</span>
          <img
            alt="pastedImage"
            src="/external/pastedimage-yqzl-1200w.png"
            class="app-coach-profile-teams-pasted-image17"
          />
        </div>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name10"></app-footer>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import HeaderApp from '../components/header-app'
import MobileScreen from '../components/mobile-screen'
import CoachTeamsCard from '../components/coach-teams-card'
import AppFilter from '../components/filter'
import ButtonWithIcon from '../components/button-with-icon'
import AppFooter from '../components/footer'

export default {
  name: 'AppCoachProfileTeams',
  components: {
    HeaderApp,
    MobileScreen,
    DangerousHTML,
    CoachTeamsCard,
    AppFilter,
    ButtonWithIcon,
    AppFooter,
  },
  metaInfo: {
    title: 'App-Coach-Profile-Teams - SkillQuest',
    meta: [
      {
        property: 'og:title',
        content: 'App-Coach-Profile-Teams - SkillQuest',
      },
    ],
  },
}
</script>

<style scoped>
.app-coach-profile-teams-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app-coach-profile-teams-left {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 262px;
  height: auto;
  display: flex;
  position: relative;
  padding-top: var(--dl-space-space-fourunits);
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-fourunits);
  flex-direction: column;
  background-color: var(--dl-color-app-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
}
.app-coach-profile-teams-navlink {
  display: contents;
}
.app-coach-profile-teams-container02 {
  text-decoration: none;
}
.app-coach-profile-teams-container05 {
  display: contents;
}
.app-coach-profile-teams-text {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-teams-container09 {
  display: contents;
}
.app-coach-profile-teams-text01 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-teams-container13 {
  display: contents;
}
.app-coach-profile-teams-text02 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-teams-text05 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-teams-text08 {
  color: var(--dl-color-app-neutral);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  line-height: 175%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.app-coach-profile-teams-right {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: flex-start;
}
.app-coach-profile-teams-create-teams {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-coach-profile-teams-text11 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.app-coach-profile-teams-container16 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  border-radius: 15px;
  flex-direction: column;
  background-color: rgba(95, 95, 95, 0.07);
}
.app-coach-profile-teams-text12 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.app-coach-profile-teams-container17 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.app-coach-profile-teams-container18 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.app-coach-profile-teams-text13 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.app-coach-profile-teams-container19 {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.app-coach-profile-teams-container20 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.app-coach-profile-teams-container21 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.app-coach-profile-teams-invite-new-user {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-coach-profile-teams-text29 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.app-coach-profile-teams-container22 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  border-radius: 15px;
  flex-direction: column;
  background-color: rgba(95, 95, 95, 0.07);
}
.app-coach-profile-teams-text30 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.app-coach-profile-teams-container23 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.app-coach-profile-teams-container24 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.app-coach-profile-teams-pasted-image {
  top: 0px;
  right: var(--dl-space-space-unit);
  width: 12px;
  bottom: 0px;
  height: 8px;
  margin: auto;
  position: absolute;
}
.app-coach-profile-teams-container25 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.app-coach-profile-teams-container26 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 200px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius10);
  flex-direction: row;
  background-color: var(--dl-color-app-primary);
}
.app-coach-profile-teams-pasted-image01 {
  width: 24px;
  height: 24px;
}
.app-coach-profile-teams-text31 {
  color: var(--dl-color-app-white);
}
.app-coach-profile-teams-text32 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.app-coach-profile-teams-text33 {
  color: var(--dl-color-app-orange);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-left: var(--dl-space-space-unit);
}
.app-coach-profile-teams-container27 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.app-coach-profile-teams-text34 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.app-coach-profile-teams-container28 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-app-primary);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius10);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
}
.app-coach-profile-teams-pasted-image02 {
  width: 24px;
  height: 24px;
}
.app-coach-profile-teams-container29 {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 300px 1fr 1fr;
}
.app-coach-profile-teams-container30 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.app-coach-profile-teams-container31 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.app-coach-profile-teams-manage-team-members {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-coach-profile-teams-text53 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.app-coach-profile-teams-navlink1 {
  display: contents;
}
.app-coach-profile-teams-container32 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  border-radius: 15px;
  flex-direction: column;
  text-decoration: none;
  background-color: rgba(95, 95, 95, 0.07);
}
.app-coach-profile-teams-container33 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.app-coach-profile-teams-select {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.app-coach-profile-teams-pasted-image03 {
  top: 0px;
  right: var(--dl-space-space-unit);
  width: 12px;
  bottom: 0px;
  height: 8px;
  margin: auto;
  position: absolute;
}
.app-coach-profile-teams-container34 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-app-primary);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius10);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
}
.app-coach-profile-teams-pasted-image04 {
  width: 24px;
  height: 24px;
}
.app-coach-profile-teams-text55 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.app-coach-profile-teams-container35 {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 300px 1fr 1fr;
}
.app-coach-profile-teams-container37 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.app-coach-profile-teams-pasted-image05 {
  width: 30px;
  height: 30px;
}
.app-coach-profile-teams-pasted-image06 {
  width: 30px;
  height: 30px;
}
.app-coach-profile-teams-pasted-image07 {
  width: 40px;
  height: 40px;
}
.app-coach-profile-teams-container38 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.app-coach-profile-teams-pasted-image08 {
  width: 30px;
  height: 30px;
}
.app-coach-profile-teams-pasted-image09 {
  width: 30px;
  height: 30px;
}
.app-coach-profile-teams-pasted-image10 {
  width: 40px;
  height: 40px;
}
.app-coach-profile-teams-container39 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.app-coach-profile-teams-pasted-image11 {
  width: 30px;
  height: 30px;
}
.app-coach-profile-teams-pasted-image12 {
  width: 30px;
  height: 30px;
}
.app-coach-profile-teams-pasted-image13 {
  width: 40px;
  height: 40px;
}
.app-coach-profile-teams-container40 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.app-coach-profile-teams-pasted-image14 {
  width: 30px;
  height: 30px;
}
.app-coach-profile-teams-pasted-image15 {
  width: 30px;
  height: 30px;
}
.app-coach-profile-teams-pasted-image16 {
  width: 40px;
  height: 40px;
}
.app-coach-profile-teams-user-activity {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app-coach-profile-teams-text78 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.app-coach-profile-teams-pasted-image17 {
  width: 1119px;
}
</style>
