<template>
  <div class="h1-container" v-bind:class="rootClassName">
    <h1 class="h1-text">{{ H1 }}</h1>
  </div>
</template>

<script>
export default {
  name: 'H1',
  props: {
    H1: {
      type: String,
      default:
        'With our easy-to-use platform, you can browse through a wide variety of games and choose the ones that best match your needs',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.h1-container {
  display: flex;
  position: relative;
  max-width: 800px;
}
.h1-text {
  font-size: 46px;
  text-align: center;
  font-weight: 600;
  line-height: 110%;
}
.h1-root-class-name {
  max-width: 900px;
  align-self: center;
}
.h1-root-class-name1 {
  top: 0px;
  left: 200px;
  position: static;
}
.h1-root-class-name2 {
  align-self: center;
}

@media(max-width: 991px) {
  .h1-root-class-name {
    max-width: 900px;
  }
}
@media(max-width: 767px) {
  .h1-text {
    font-size: 32px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .h1-root-class-name {
    max-width: auto;
  }
  .h1-root-class-name1 {
    max-width: 600px;
    align-self: center;
  }
}
@media(max-width: 479px) {
  .h1-text {
    height: 103px;
    font-size: 30px;
  }
  .h1-root-class-name {
    max-width: var(--dl-size-size-xxlarge);
  }
  .h1-root-class-name1 {
    max-width: 400px;
  }
  .h1-root-class-name2 {
    max-width: 400px;
  }
  .h1-root-class-name5 {
    max-width: 400px;
  }
}
</style>
