<template>
  <div class="privacy-policy-container">
    <app-header rootClassName="header-root-class-name2"></app-header>
    <h1>Privacy Policy</h1>
    <span class="privacy-policy-text001">
      <span>
        SkillQuest considers your privacy to be of fundamental importance and
        tries to ensure that the processing of your personal data is carried out
        in compliance with the rights, fundamental freedoms, as well as the
        dignity of the interested party, with particular reference to
        confidentiality, personal identity and the right to the protection of
        personal data.
      </span>
      <br />
      <br />
      <span>
        In this regard, SkillQuest has adopted and implemented a Privacy Policy
        concerning all the methods of managing the activities that involve the
        processing of your personal data.
      </span>
      <br />
      <br />
      <span>
        This Privacy Policy explains how and why SkillQuest collects, records and
        uses information about you and the choices you can make about how that
        information is collected and used.
      </span>
      <br />
      <br />
      <span>REGISTRATION</span>
      <br />
      <br />
      <span>
        In order to use the Service or part of it, Users must register by
        truthfully and completely providing all the data requested in the relevant
        registration form and fully accept the privacy policy and these general
        conditions. The user has the responsibility to guard and keep his access
        credentials confidential. It is understood that under no circumstances
        will SkillQuest be held responsible in the event of loss, dissemination,
        theft or unauthorized use by third parties, for any reason whatsoever, of
        the Users&apos; access credentials.
      </span>
      <br />
      <br />
      <span>
        To create an account on SkillQuest you must be at least 16 years old. In
        order to verify the existence of the minimum age, SkillQuest reserves the
        right to request, during the registration process, to enter one&apos;s
        date of birth.
      </span>
      <br />
      <br />
      <br />
      <span>OWNER OF THE TREATMENT</span>
      <br />
      <br />
      <span>
        The data controller is Wisepath Corp., with headquarters in Dallas, TX,
        75252, in the person of its pro tempore legal representative, Mr. Gianluca
        Ferremi. In this document it will be referred to as SkillQuest, which is
        the distinctive sign under which it carries out any activity on the Site.
        The data controller can be contacted to the email address info@wisepath.ai
      </span>
      <br />
      <br />
      <span>A Data Protection Officer (DPO) has not been appointed yet.</span>
      <br />
      <br />
      <span>TYPES OF DATA COLLECTED</span>
      <br />
      <br />
      <span>
        Among the Personal Data collected, independently or through third parties,
        or freely provided by the User, there are:
      </span>
      <br />
      <br />
      <span>
        Navigation data The computer systems and software procedures used to
        operate this website acquire, during their normal operation, some personal
        data whose transmission is implicit in the use of Internet communication
        protocols. This category of data includes: IP addresses, the type of
        browser used, the operating system, the domain name and website addresses
        from which access was made, information on the pages visited by users
        within of the site, the time of access, the permanence on the single page,
        the analysis of the internal path and other parameters relating to the
        operating system and the user&apos;s IT environment.
        <span v-html="raw290k"></span>
      </span>
      <br />
      <br />
      <br />
      <span>
        Additional Data Categories This is all Personal Data provided by the User
        through the site, for example, by registering and/or accessing a reserved
        area and/or a service and/or participating in one of the initiatives
        provided by SkillQuest or in partnership with third parties or by writing
        to an e-mail address to request information or by subscribing to the
        newsletter.
      </span>
      <br />
      <br />
      <span>
        The Personal Data collected is used to provide services to the User and to
        send communications by e-mail, the address of which is provided at the
        time of registration.
      </span>
      <br />
      <br />
      <span>
        If access to the site takes place under the profile of &quot;Mentor&quot;,
        the user agrees that the data provided at the time of registration will be
        used by the platform for the guidance services to be provided and for the
        possibility of being contacted by other users. The newsletter service
        consists in sending electronic communications following the express
        request of the recipient, for which it is not necessary to acquire a
        further consent, other than that provided by the recipient when completing
        and sending the newsletter subscription form. However, it is necessary
        that the user can see the privacy information (possibly even if linked)
        and click on the newsletter subscription button, after filling in the
        required fields.
      </span>
      <br />
      <br />
      <span>
        Any use of Cookies - or other tracking tools - by SkillsQuest unless
        otherwise specified, has the purpose of providing the Service requested by
        the User, in addition to the other purposes described in the Cookie
        Policy.
      </span>
      <br />
      <br />
      <span>MANDATORY DATA AND OPTIONAL DATA</span>
      <br />
      <br />
      <span>
        Unless otherwise specified, all Personal Data requested by www.wisepath.ai
        are mandatory. If the User refuses to communicate them, it may be
        impossible for SkillQuest to provide the Service. In cases where
        www.wisepath.ai indicates some Data as optional, Users are free to refrain
        from communicating such Data, without this having any consequence on the
        availability of the Service or on its operation. Users who have doubts
        about which Data are mandatory are encouraged to contact the Owner.
      </span>
      <br />
      <br />
      <span>LEGALITY AND PURPOSE OF TREATMENT</span>
      <br />
      <br />
      <span>
        The processing of personal data takes place in accordance with the
        provisions of the General Data Protection Regulation (GDPR) and any other
        applicable data protection legislation. The details are provided below:
      </span>
      <br />
      <br />
      <span>
        * Purposes aimed at fulfilling a legal obligation (Article 6, paragraph 1
        (c) of the GDPR)
      </span>
      <br />
      <br />
      <span>
        a. Fulfillment of obligations established by Laws, Regulations and
        European Union legislation, or by provisions issued by Authorities or by
        Supervisory and Control Bodies in relation to or in any case connected to
        the existing and/or future legal relationship. The retention period of
        personal data, in relation to the purposes referred to in this section, is
        10 years, from the end of the legal or commercial relationship
        established. These times may be extended in the event of a dispute or
        legal provision.
      </span>
      <br />
      <br />
      <br />
      <span>
        * Purposes aimed at the execution of a contract or pre-contractual
        measures (Article 6, section 1 (b) of the GDPR) to. fulfillment of
        contractual obligations and deriving from legal transactions and in
        particular of the finalized agreement, i.e. registration on the Site. b.
        Conclusion of a sale and protection of contractual rights or in any case
        deriving from the relationships between the parties. The retention period
        of personal data, in relation to the purposes referred to in this section,
        is:For the purposes: a) until the cancellation of the profile, and for a
        subsequent period not exceeding one quarter from such cancellation or from
        the deactivation of the profile;
      </span>
      <br />
      <br />
      <span>
        For the purpose: b) 10 years, from the end of the established legal or
        commercial relationship. These times may be extended in the event of a
        dispute or due to special contractual provisions.
      </span>
      <br />
      <br />
      <span>
        * Purposes covered by the consent of the interested party (Article 6,
        paragraph 1 (a) of the GDPR) The purposes may require the consent
        expressed by the interested party.
      </span>
      <br />
      <br />
      <span>
        The purposes that require consent are: a. carrying out advertising or
        promotional activities, in the broadest sense of the term (for example
        orientation services, communication of the user&apos;s profile following
        the completion of the questionnaire, sending of newsletters and
        information material, requesting brochures, organizing of events, etc.)
        and other marketing activities, through telephone contacts, e-mail,
        ordinary mail and SMS, various instant messaging systems, social network
        accounts and any tool or form of electronic communication, including
        reporting of all the initiatives dedicated to registered users;b. the
        performance of profiling activities, such as the analysis of the
        user&apos;s consumption habits or choices, mainly using the data entered
        during registration on the Site, data relating to his participation in
        surveys, questionnaires and market research in general performed, as well
        as data relating to the services used, and other behaviors and data that
        the Data Controller should know in any way, including any data deriving
        from any analysis of web browsing or from the analysis of any cookies
        installed through its website.
      </span>
      <br />
      <br />
      <span>
        b. the performance of profiling activities, such as the analysis of the
        user&apos;s consumption habits or choices, mainly using the data entered
        during registration on the Site, data relating to his participation in
        surveys, questionnaires and market research in general performed, as well
        as data relating to the services used, and other behaviors and data that
        the Data Controller should know in any way, including any data deriving
        from any analysis of web browsing or from the analysis of any cookies
        installed through its website.
      </span>
      <br />
      <br />
      <br />
      <span>
        c. reporting of contacts of different users registrations on the platform.
        The retention period of personal data, in relation to the purposes
        referred to in this section, is at least 24 months from the issue of
        consent, unless revoked.
      </span>
      <br />
      <br />
      <br />
      <span>
        The interested party can withdraw his consent at any time, with the
        understanding that the withdrawal of consent has effect only for the
        future. Any data processing that took place before the revocation will not
        be affected and will retain its legitimacy.
      </span>
      <br />
      <br />
      <br />
      <span>
        However, it is always possible to ask the Data Controller to clarify the
        concrete legal basis of each treatment and in particular to specify
        whether the treatment is based on the law, provided for by a contract or
        necessary to conclude a contract.
      </span>
      <br />
      <br />
      <span>METHOD AND PLACE OF THE PROCESSING OF THE COLLECTED DATA</span>
      <br />
      <br />
      <span>
        The Data Controller adopts the appropriate security measures to prevent
        unauthorized access, disclosure, modification or destruction of Personal
        Data.
      </span>
      <br />
      <br />
      <span>
        The processing is carried out using IT and/or telematic tools (and storage
        via cloud made available on the Amazon web Services platform), with
        organizational methods and with logic strictly related to the purposes
        indicated. In addition to the Data Controller, in some cases, other
        subjects involved in the SkillQuest organization (administrative,
        commercial, marketing, legal, system administrators) or external subjects
        (such as third party technical service providers, postal couriers, hosting
        providers, IT companies, communication agencies) also appointed, if
        necessary, as Data Processors by the Data Controller.
      </span>
      <br />
      <br />
      <span>
        The updated list of Managers can always be requested from the Data
        Controller.
      </span>
      <br />
      <br />
      <span>
        The Data is processed at the Data Controller&apos;s operating offices and
        in any other place where the parties involved in the processing are
        located. For more information, contact the Owner.
      </span>
      <br />
      <br />
      <span>
        The Data Controller informs you that he has no intention of transferring
        his data to countries outside the EU and the EEA for the purposes
        indicated above.
      </span>
      <br />
      <br />
      <span>INTERACTIONS WITH OTHER USERS</span>
      <br />
      <br />
      <span>
        The communication services between Users allow forms of interaction and
        contact, depending on the settings decided by the Owner, anonymously or
        publicly. If there is an email among the Personal Data released by the
        User, this could be used to send notifications of comments, interactions
        and contacts. Users are solely responsible for the content of their
        comments, posts and interactions. YOUR RIGHTS Users can exercise certain
        rights with reference to the Data processed by the Owner. In particular,
        the User has the right to:
      </span>
      <br />
      <br />
      <span>
        revoke consent at all times. The User can revoke the previously expressed
        consent to the processing of his Personal Data.
      </span>
      <br />
      <br />
      <span>
        oppose to the processing of their Data. The User can object to the
        processing of their Data when it occurs on a legal basis other than
        consent. Further details on the right to object are set out in the section
        below.
      </span>
      <br />
      <br />
      <br />
      <br />
      <span>
        log into to their Data. The User has the right to obtain information on
        the Data processed by the Data Controller, on certain aspects of the
        processing and to receive a copy of the Data processed.
      </span>
      <br />
      <br />
      <span>
        check and ask for the rectification. The User can verify the correctness
        of his Data and request its updating or correction.
      </span>
      <br />
      <br />
      <span>
        obtain the limitation of the treatment. When certain conditions are met,
        the User can request the limitation of the processing of their Data. In
        this case, the Data Controller will not process the Data for any other
        purpose than their conservation.
      </span>
      <br />
      <br />
      <span>
        obtain the cancellation or removal of their Personal Data. When certain
        conditions are met, the User can request the cancellation of their Data by
        the Owner.
      </span>
      <br />
      <br />
      <span>
        propose a complaint. The User can lodge a complaint with the competent
        personal data protection supervisory authority or take legal action.
        Details on the right to object When Personal Data is processed in the
        public interest, in the exercise of public powers vested in the Data
        Controller or to pursue a legitimate interest of the Data Controller,
        Users have the right to object to the processing for reasons related to
        their particular situation.
      </span>
      <br />
      <br />
      <br />
      <span>
        Users are reminded that, should their Data be processed for direct
        marketing purposes, they may object to the processing without providing
        any reason. To find out if the Data Controller processes data for direct
        marketing purposes, Users can refer to the respective sections of this
        document.
      </span>
      <br />
      <br />
      <br />
      <span>
        To exercise the User&apos;s rights, Users can address a request to the
        contact details of the Owner at mail info@wisepath.ai. Requests are filed
        free of charge and processed by the Data Controller as soon as possible,
        in any case within a month.
      </span>
      <br />
      <br />
      <br />
      <span>RIGHT TO PROPOSE A COMPLAINT</span>
      <br />
      <br />
      <span>
        If the User believes that their rights have been compromised, they have
        the right to lodge a complaint with the Guarantor Authority for the
        protection of personal data, according to the methods indicated by the
        same Authority at the following Internet address:
        http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/4535524
        or by sending a written communication to the Personal Data Protection
        Authority, Piazza Montecitorio n.121, 00186 Rome .
      </span>
      <br />
      <br />
      <br />
      <span>CONSENT PURSUANT TO ART. 7 OF EU REGULATION 2016/679</span>
      <br />
      <br />
      <span>
        In compliance with the provisions of art. 7 of the EU Regulation, the User
        declares to have fully understood the information provided by the Data
        Controller and:
      </span>
      <br />
      <br />
      <span>
        in relation to: carrying out advertising or promotional activities, in the
        broadest sense of the term (for example orientation services,
        communication of the user&apos;s profile following completion of the
        questionnaire, sending of newsletters and information material, request
        for brochures, organization of events, etc.) and other marketing
        activities, through telephone contacts, e-mails, ordinary mail and SMS,
        various instant messaging systems, social network accounts and any tool or
        form of communication electronic, including reporting of all initiatives
        dedicated to registered users; in relation to: the performance of
        profiling activities, such as the analysis of the user&apos;s consumption
        habits or choices, mainly using the data entered during registration on
        the Site, the data relating to his participation in surveys,
        questionnaires and in market research carried out, as well as data
        relating to the services used, and other behaviors and data that the Data
        Controller should know in any way, including any data deriving from any
        analysis of web browsing or from the analysis of any cookies installed
        through the own site.
      </span>
      <br />
      <br />
      <span>
        in relation to: reporting of contacts of various users registrations on
        the platform.
      </span>
      <br />
      <br />
      <span>
        The Owner, without prejudice to the full freedom of the User, and in
        compliance with the rights granted to him, to provide his free consent,
        informs that in relation to the purposes indicated above, even a partial
        consent may not guarantee the pursuit of the single purpose, for which
        consent was partially denied.
      </span>
    </span>
    <app-footer rootClassName="footer-root-class-name2"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppFooter from '../components/footer'

export default {
  name: 'PrivacyPolicy',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      raw290k: ' ',
    }
  },
  metaInfo: {
    title: 'Privacy-Policy - SkillQuest',
    meta: [
      {
        property: 'og:title',
        content: 'Privacy-Policy - SkillQuest',
      },
    ],
  },
}
</script>

<style scoped>
.privacy-policy-container {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.privacy-policy-text001 {
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
}
@media(max-width: 767px) {
  .privacy-policy-container {
    gap: var(--dl-space-space-sixunits);
  }
}
@media(max-width: 479px) {
  .privacy-policy-text001 {
    width: 100%;
  }
}
</style>
