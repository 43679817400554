<template>
  <div class="middle-middle">
    <div v-if="tab === 1" class="middle-tabs-recently-played">
      <div class="tab-selected tab"><span>Recently Played</span></div>
      <div @click="tab = 2" class="middle-container001 tab">
        <span class="middle-text001">by Soft Skill</span>
      </div>
      <div @click="tab = 3" class="middle-container002 tab">
        <span class="middle-text002">Points</span>
      </div>
      <div @click="tab = 4" class="middle-container003 tab">
        <span class="middle-text003">About</span>
      </div>
    </div>
    <div v-if="tab === 2" class="middle-tabs-by-soft-skill">
      <div @click="tab = 1" class="tab">
        <span class="middle-text004">Recently Played</span>
      </div>
      <div class="middle-container005 tab-selected tab">
        <span>by Soft Skill</span>
      </div>
      <div @click="tab = 3" class="middle-container006 tab">
        <span class="middle-text006">Points</span>
      </div>
      <div @click="tab = 4" class="middle-container007 tab">
        <span class="middle-text007">About</span>
      </div>
    </div>
    <div v-if="tab === 3" class="middle-tabs-points">
      <div @click="tab = 1" class="tab">
        <span class="middle-text008">Recently Played</span>
      </div>
      <div @click="tab = 2" class="middle-container009 tab">
        <span class="middle-text009">by Soft Skill</span>
      </div>
      <div class="tab-selected tab"><span>Points</span></div>
      <div @click="tab = 4" class="middle-container011 tab">
        <span class="middle-text011">About</span>
      </div>
    </div>
    <div v-if="tab === 4" class="middle-tabs-about">
      <div @click="tab = 1" class="tab">
        <span class="middle-text012">Recently Played</span>
      </div>
      <div @click="tab = 2" class="middle-container013 tab">
        <span class="middle-text013">by Soft Skill</span>
      </div>
      <div @click="tab = 3" class="middle-container014 tab">
        <span class="middle-text014">Points</span>
      </div>
      <div class="middle-container015 tab-selected tab"><span>About</span></div>
    </div>
    <div v-if="tab === 1" class="middle-body-recently-played">
      <div class="middle-container016">
        <span class="middle-title">You are currently playing</span>
        <div class="middle-container017">
          <div class="middle-container018">
            <div class="middle-container019">
              <div class="middle-container020 shadow">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-e89-200h.png"
                  class="middle-pasted-image"
                />
                <div class="middle-container021">
                  <span class="middle-text016">Gravitas</span>
                  <ul class="list">
                    <li class="middle-li list-item">
                      <span class="middle-text017">Cognitive Flexibility</span>
                    </li>
                    <li class="middle-li01 list-item">
                      <span class="middle-text018">People/Team Mgmnt</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="middle-container022 shadow">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-fvkc-200h.png"
                  class="middle-pasted-image01"
                />
                <div class="middle-container023">
                  <span class="middle-text019">Train Valley</span>
                  <ul class="list">
                    <li class="middle-li02 list-item">
                      <span class="middle-text020">Organizational Skills</span>
                    </li>
                    <li class="middle-li03 list-item">
                      <span class="middle-text021">Critical Thinking</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="middle-container024 shadow">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-xgsh-200h.png"
                  class="middle-pasted-image02"
                />
                <div class="middle-container025">
                  <span class="middle-text022">Gems of War</span>
                  <ul class="list">
                    <li class="middle-li04 list-item">
                      <span class="middle-text023">Organizational Skills</span>
                    </li>
                    <li class="middle-li05 list-item">
                      <span class="middle-text024">Critical Thinking</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="middle-container026">
            <div class="middle-container027">
              <div class="middle-container028 shadow">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-s64n-200h.png"
                  class="middle-pasted-image03"
                />
                <div class="middle-container029">
                  <span class="middle-text025">Cities Skylines</span>
                  <ul class="list">
                    <li class="middle-li06 list-item">
                      <span class="middle-text026">Self-discipline</span>
                    </li>
                    <li class="middle-li07 list-item">
                      <span class="middle-text027">Logical Reasoning</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="middle-container030 shadow">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-eqee-200h.png"
                  class="middle-pasted-image04"
                />
                <div class="middle-container031">
                  <span class="middle-text028">Cell to Singularity</span>
                  <ul class="list">
                    <li class="middle-li08 list-item">
                      <span class="middle-text029">Mental Flexibility</span>
                    </li>
                    <li class="middle-li09 list-item">
                      <span class="middle-text030">People/Team Mgmnt</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="middle-container032 shadow">
                <div class="middle-container033">
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-gwwc-200h.png"
                    class="middle-pasted-image05"
                  />
                </div>
                <div class="middle-container034">
                  <span class="middle-text031">Minion Masters</span>
                  <ul class="list">
                    <li class="middle-li10 list-item">
                      <span class="middle-text032">Cognitive Flexibility</span>
                    </li>
                    <li class="middle-li11 list-item">
                      <span class="middle-text033">Self-Confidence</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle-container035">
        <div class="middle-match-suggestions-wrapper">
          <div class="middle-container036">
            <div class="middle-container037">
              <span class="middle-text034">Total # of Achievements</span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-lrk-200w.png"
                class="middle-pasted-image06"
              />
              <span class="middle-text035">over the last 7 days</span>
            </div>
            <div class="middle-container038">
              <span class="middle-text036">Total # of Achievements</span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-b9dq-200w.png"
                class="middle-pasted-image07"
              />
              <span class="middle-text037">for all games</span>
            </div>
          </div>
          <div class="middle-container039">
            <div class="middle-container040">
              <span class="middle-text038">
                <span>
                  % of available points in
                  <span v-html="rawiaks"></span>
                </span>
                <span class="middle-text040">Creativity</span>
                <span>in Minion Masters</span>
              </span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-n29x-200h.png"
                class="middle-pasted-image08"
              />
            </div>
            <div class="middle-container041">
              <span class="middle-text042">
                <span>
                  % of available points in
                  <span v-html="rawgfgm"></span>
                </span>
                <span class="middle-text044">Self-Motivation </span>
                <span>in Paladins</span>
              </span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-nzym-200h.png"
                class="middle-pasted-image09"
              />
            </div>
          </div>
        </div>
        <span class="middle-text046">Game Suggestions</span>
        <div class="middle-card-profession shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-1g98-200h.png"
            class="middle-pasted-image10"
          />
          <div class="middle-container042">
            <span class="middle-text047">Portal 2</span>
            <ul class="list">
              <li class="middle-li12 list-item">
                <span class="middle-text048">Mental Flexibility</span>
              </li>
              <li class="middle-li13 list-item">
                <span class="middle-text049">People/Team Mgmnt</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="middle-card-profession1 shadow">
          <div class="middle-container043">
            <img
              alt="pastedImage"
              src="/external/pastedimage-gekt-200h.png"
              class="middle-pasted-image11"
            />
          </div>
          <div class="middle-container044">
            <span class="middle-text050">Anomaly 2</span>
            <ul class="list">
              <li class="middle-li14 list-item">
                <span class="middle-text051">Critical Thinking</span>
              </li>
              <li class="middle-li15 list-item">
                <span class="middle-text052">Goal-setting</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 2" class="middle-body-by-soft-skill">
      <div class="middle-container045">
        <div class="middle-container046">
          <span class="middle-title1">Games that train...</span>
          <app-select rootClassName="select-root-class-name8"></app-select>
        </div>
        <div class="middle-container047">
          <div class="middle-container048">
            <div class="middle-container049">
              <div class="middle-card-profession2 shadow">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-1g98-200h.png"
                  class="middle-pasted-image12"
                />
                <div class="middle-container050">
                  <span class="middle-text053">Portal 2</span>
                  <ul class="list">
                    <li class="middle-li16 list-item">
                      <span class="middle-text054">Mental Flexibility</span>
                    </li>
                    <li class="middle-li17 list-item">
                      <span class="middle-text055">People/Team Mgmnt</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="middle-container051 shadow">
                <div class="middle-container052">
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-gwwc-200h.png"
                    class="middle-pasted-image13"
                  />
                </div>
                <div class="middle-container053">
                  <span class="middle-text056">Minion Maters</span>
                  <ul class="list">
                    <li class="middle-li18 list-item">
                      <span class="middle-text057">Cognitive Flexibility</span>
                    </li>
                    <li class="middle-li19 list-item">
                      <span class="middle-text058">Self-Confidence</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="middle-container054 shadow">
                <div class="middle-container055">
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-kcoe-200h.png"
                    class="middle-pasted-image14"
                  />
                </div>
                <div class="middle-container056">
                  <span class="middle-text059">Team Fortress 2</span>
                  <ul class="list">
                    <li class="middle-li20 list-item">
                      <span class="middle-text060">Organizational Skills</span>
                    </li>
                    <li class="middle-li21 list-item">
                      <span class="middle-text061">Impulse Control</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="middle-container057">
            <div class="middle-container058">
              <div class="middle-container059 shadow">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-s64n-200h.png"
                  class="middle-pasted-image15"
                />
                <div class="middle-container060">
                  <span class="middle-text062">Cities Skylines</span>
                  <ul class="list">
                    <li class="middle-li22 list-item">
                      <span class="middle-text063">Self-discipline</span>
                    </li>
                    <li class="middle-li23 list-item">
                      <span class="middle-text064">Logical Reasoning</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="middle-container061 shadow">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-fvkc-200h.png"
                  class="middle-pasted-image16"
                />
                <div class="middle-container062">
                  <span class="middle-text065">Train Valley</span>
                  <ul class="list">
                    <li class="middle-li24 list-item">
                      <span class="middle-text066">Organizational Skills</span>
                    </li>
                    <li class="middle-li25 list-item">
                      <span class="middle-text067">Critical Thinking</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="middle-container063 shadow">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-eqee-200h.png"
                  class="middle-pasted-image17"
                />
                <div class="middle-container064">
                  <span class="middle-text068">Cell to Singularity</span>
                  <ul class="list">
                    <li class="middle-li26 list-item">
                      <span class="middle-text069">Mental Flexibility</span>
                    </li>
                    <li class="middle-li27 list-item">
                      <span class="middle-text070">People/Team Mgmnt</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle-container065">
        <div class="middle-match-suggestions-wrapper1">
          <div class="middle-container066">
            <div class="middle-container067">
              <span class="middle-text071">Total # of Achievements</span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-lrk-200w.png"
                class="middle-pasted-image18"
              />
              <span class="middle-text072">over the last 7 days</span>
            </div>
            <div class="middle-container068">
              <span class="middle-text073">Total # of Achievements</span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-b9dq-200w.png"
                class="middle-pasted-image19"
              />
              <span class="middle-text074">for all games</span>
            </div>
          </div>
          <div class="middle-container069">
            <div class="middle-container070">
              <span class="middle-text075">
                <span>
                  % of available points in
                  <span v-html="raww6l8"></span>
                </span>
                <span class="middle-text077">Creativity</span>
                <span>in Minion Masters</span>
              </span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-n29x-200h.png"
                class="middle-pasted-image20"
              />
            </div>
            <div class="middle-container071">
              <span class="middle-text079">
                <span>
                  % of available points in
                  <span v-html="rawgqg1"></span>
                </span>
                <span class="middle-text081">Self-Motivation </span>
                <span>in Paladins</span>
              </span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-nzym-200h.png"
                class="middle-pasted-image21"
              />
            </div>
          </div>
        </div>
        <span class="middle-text083">Game Suggestions</span>
        <div class="middle-card-profession3 shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-1g98-200h.png"
            class="middle-pasted-image22"
          />
          <div class="middle-container072">
            <span class="middle-text084">Portal 2</span>
            <ul class="list">
              <li class="middle-li28 list-item">
                <span class="middle-text085">Mental Flexibility</span>
              </li>
              <li class="middle-li29 list-item">
                <span class="middle-text086">People/Team Mgmnt</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="middle-card-profession4 shadow">
          <div class="middle-container073">
            <img
              alt="pastedImage"
              src="/external/pastedimage-gekt-200h.png"
              class="middle-pasted-image23"
            />
          </div>
          <div class="middle-container074">
            <span class="middle-text087">Anomaly 2</span>
            <ul class="list">
              <li class="middle-li30 list-item">
                <span class="middle-text088">Critical Thinking</span>
              </li>
              <li class="middle-li31 list-item">
                <span class="middle-text089">Goal-setting</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 3" class="middle-body-points">
      <div class="middle-container075">
        <div class="middle-container076">
          <div class="middle-container077">
            <img
              alt="pastedImage"
              src="/external/pastedimage-gwwc-200h.png"
              class="middle-pasted-image24"
            />
            <app-select
              text="Minion Masters"
              rootClassName="select-root-class-name9"
            ></app-select>
          </div>
        </div>
        <img
          alt="pastedImage"
          src="/external/pastedimage-19vk-800w.png"
          class="middle-pasted-image25"
        />
      </div>
      <div class="middle-container078">
        <div class="middle-match-suggestions-wrapper2">
          <div class="middle-container079">
            <div class="middle-container080">
              <span class="middle-text090">Total # of Achievements</span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-lrk-200w.png"
                class="middle-pasted-image26"
              />
              <span class="middle-text091">over the last 7 days</span>
            </div>
            <div class="middle-container081">
              <span class="middle-text092">Total # of Achievements</span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-b9dq-200w.png"
                class="middle-pasted-image27"
              />
              <span class="middle-text093">for all games</span>
            </div>
          </div>
          <div class="middle-container082">
            <div class="middle-container083">
              <span class="middle-text094">
                <span>
                  % of available points in
                  <span v-html="raw1rqz"></span>
                </span>
                <span class="middle-text096">Creativity</span>
                <span>in Minion Masters</span>
              </span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-n29x-200h.png"
                class="middle-pasted-image28"
              />
            </div>
            <div class="middle-container084">
              <span class="middle-text098">
                <span>
                  % of available points in
                  <span v-html="rawg23l"></span>
                </span>
                <span class="middle-text100">Self-Motivation </span>
                <span>in Paladins</span>
              </span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-nzym-200h.png"
                class="middle-pasted-image29"
              />
            </div>
          </div>
        </div>
        <span class="middle-text102">Game Suggestions</span>
        <div class="middle-card-profession5 shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-1g98-200h.png"
            class="middle-pasted-image30"
          />
          <div class="middle-container085">
            <span class="middle-text103">Portal 2</span>
            <ul class="list">
              <li class="middle-li32 list-item">
                <span class="middle-text104">Mental Flexibility</span>
              </li>
              <li class="middle-li33 list-item">
                <span class="middle-text105">People/Team Mgmnt</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="middle-card-profession6 shadow">
          <div class="middle-container086">
            <img
              alt="pastedImage"
              src="/external/pastedimage-gekt-200h.png"
              class="middle-pasted-image31"
            />
          </div>
          <div class="middle-container087">
            <span class="middle-text106">Anomaly 2</span>
            <ul class="list">
              <li class="middle-li34 list-item">
                <span class="middle-text107">Critical Thinking</span>
              </li>
              <li class="middle-li35 list-item">
                <span class="middle-text108">Goal-setting</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 4" class="middle-body-about">
      <div class="middle-container088">
        <div class="middle-container089">
          <img
            alt="pastedImage"
            src="/external/pastedimage-zena-1500w.png"
            class="middle-pasted-image32"
          />
          <app-select
            text="Minion Masters"
            rootClassName="select-root-class-name10"
          ></app-select>
        </div>
        <div class="middle-container090">
          <span class="middle-text109">
            Minion Masters is a game very similar to the famous Clash Royal video
            game, where players will have to manage an energy bar to buy and
            deploy units into the playing field and destroy enemy buildings.
            What’s more, this fun game hides a card collection mechanic where
            creativity is unleashed when creating the different &quot;decks&quot;
            of units to fight either alone or with another player.
          </span>
          <span class="middle-text110">Available on: Windows PC &amp; Mac</span>
          <div class="middle-container091">
            <span class="middle-text111">Skills you develop with the game:</span>
            <ul class="middle-ul18 list">
              <li class="middle-li36 list-item">
                <span class="middle-text112">Cognitive Flexibility</span>
              </li>
              <li class="middle-li37 list-item">
                <span class="middle-text113">Complex Problem Solving</span>
              </li>
              <li class="middle-li38 list-item">
                <span class="middle-text114">Creativity</span>
              </li>
              <li class="middle-li39 list-item">
                <span class="middle-text115">Goal-setting</span>
              </li>
              <li class="middle-li40 list-item">
                <span class="middle-text116">Judgement and Decision Making</span>
              </li>
              <li class="middle-li41 list-item">
                <span class="middle-text117">Logical Reasoning</span>
              </li>
              <li class="middle-li42 list-item">
                <span class="middle-text118">
                  Management of Material Resources
                </span>
              </li>
              <li class="middle-li43 list-item">
                <span class="middle-text119">Monitoring Self &amp; Others</span>
              </li>
              <li class="middle-li44 list-item">
                <span class="middle-text120">Self-confidence</span>
              </li>
              <li class="middle-li45 list-item">
                <span class="middle-text121">Self-discipline</span>
              </li>
              <li class="middle-li46 list-item">
                <span class="middle-text122">Self-motivation</span>
              </li>
              <li class="middle-li47 list-item">
                <span class="middle-text123">Spatial Relations</span>
              </li>
              <li class="middle-li48 list-item">
                <span class="middle-text124">Speed of Reasoning</span>
              </li>
              <li class="middle-li49 list-item">
                <span class="middle-text125">Stress Management</span>
              </li>
              <li class="middle-li50 list-item">
                <span class="middle-text126">Time Management</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="middle-container092">
        <div class="middle-match-suggestions-wrapper3">
          <div class="middle-container093">
            <div class="middle-container094">
              <span class="middle-text127">Total # of Achievements</span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-lrk-200w.png"
                class="middle-pasted-image33"
              />
              <span class="middle-text128">over the last 7 days</span>
            </div>
            <div class="middle-container095">
              <span class="middle-text129">Total # of Achievements</span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-b9dq-200w.png"
                class="middle-pasted-image34"
              />
              <span class="middle-text130">for all games</span>
            </div>
          </div>
          <div class="middle-container096">
            <div class="middle-container097">
              <span class="middle-text131">
                <span>
                  % of available points in
                  <span v-html="raw1zty"></span>
                </span>
                <span class="middle-text133">Creativity</span>
                <span>in Minion Masters</span>
              </span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-n29x-200h.png"
                class="middle-pasted-image35"
              />
            </div>
            <div class="middle-container098">
              <span class="middle-text135">
                <span>
                  % of available points in
                  <span v-html="rawbqbl"></span>
                </span>
                <span class="middle-text137">Self-Motivation </span>
                <span>in Paladins</span>
              </span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-nzym-200h.png"
                class="middle-pasted-image36"
              />
            </div>
          </div>
        </div>
        <span class="middle-text139">Game Suggestions</span>
        <div class="middle-card-profession7 shadow">
          <img
            alt="pastedImage"
            src="/external/pastedimage-1g98-200h.png"
            class="middle-pasted-image37"
          />
          <div class="middle-container099">
            <span class="middle-text140">Portal 2</span>
            <ul class="list">
              <li class="middle-li51 list-item">
                <span class="middle-text141">Mental Flexibility</span>
              </li>
              <li class="middle-li52 list-item">
                <span class="middle-text142">People/Team Mgmnt</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="middle-card-profession8 shadow">
          <div class="middle-container100">
            <img
              alt="pastedImage"
              src="/external/pastedimage-gekt-200h.png"
              class="middle-pasted-image38"
            />
          </div>
          <div class="middle-container101">
            <span class="middle-text143">Anomaly 2</span>
            <ul class="list">
              <li class="middle-li53 list-item">
                <span class="middle-text144">Critical Thinking</span>
              </li>
              <li class="middle-li54 list-item">
                <span class="middle-text145">Goal-setting</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSelect from './select'

export default {
  name: 'Middle',
  props: {},
  components: {
    AppSelect,
  },
  data() {
    return {
      tab: 1,
      rawiaks: ' ',
      rawgfgm: ' ',
      raww6l8: ' ',
      rawgqg1: ' ',
      raw1rqz: ' ',
      rawg23l: ' ',
      raw1zty: ' ',
      rawbqbl: ' ',
    }
  },
}
</script>

<style scoped>
.middle-middle {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-app-white);
}
.middle-tabs-recently-played {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.middle-container001 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-text001 {
  color: var(--dl-color-app-neutral);
}
.middle-container002 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-text002 {
  color: var(--dl-color-app-neutral);
}
.middle-container003 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-text003 {
  color: var(--dl-color-app-neutral);
}
.middle-tabs-by-soft-skill {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.middle-text004 {
  color: var(--dl-color-app-neutral);
}
.middle-container005 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-container006 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-text006 {
  color: var(--dl-color-app-neutral);
}
.middle-container007 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-text007 {
  color: var(--dl-color-app-neutral);
}
.middle-tabs-points {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.middle-text008 {
  color: var(--dl-color-default-grey);
}
.middle-container009 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-text009 {
  color: var(--dl-color-app-neutral);
}
.middle-container011 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-text011 {
  color: var(--dl-color-app-neutral);
}
.middle-tabs-about {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.middle-text012 {
  color: var(--dl-color-default-grey);
}
.middle-container013 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-text013 {
  color: var(--dl-color-app-neutral);
}
.middle-container014 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-text014 {
  color: var(--dl-color-app-neutral);
}
.middle-container015 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-body-recently-played {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: 70px;
  flex-direction: row;
}
.middle-container016 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-title {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  margin-top: var(--dl-space-space-threeunits);
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.middle-container017 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.middle-container018 {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.middle-container019 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-container020 {
  height: 140px;
}
.middle-pasted-image {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container021 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text016 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text017 {
  font-weight: 300;
}
.middle-li01 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text018 {
  font-weight: 300;
}
.middle-container022 {
  height: 140px;
}
.middle-pasted-image01 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container023 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text019 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li02 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text020 {
  font-weight: 300;
}
.middle-li03 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text021 {
  font-weight: 300;
}
.middle-container024 {
  height: 160px;
}
.middle-pasted-image02 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container025 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text022 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li04 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text023 {
  font-weight: 300;
}
.middle-li05 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text024 {
  font-weight: 300;
}
.middle-container026 {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.middle-container027 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-container028 {
  height: 140px;
}
.middle-pasted-image03 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container029 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text025 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li06 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text026 {
  font-weight: 300;
}
.middle-li07 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text027 {
  font-weight: 300;
}
.middle-container030 {
  height: 140px;
}
.middle-pasted-image04 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container031 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text028 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li08 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text029 {
  font-weight: 300;
}
.middle-li09 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text030 {
  font-weight: 300;
}
.middle-container032 {
  height: 160px;
}
.middle-container033 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.middle-pasted-image05 {
  width: 96px;
}
.middle-container034 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text031 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li10 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text032 {
  font-weight: 300;
}
.middle-li11 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text033 {
  font-weight: 300;
}
.middle-container035 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 340px;
  height: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.middle-match-suggestions-wrapper {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
}
.middle-container036 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-container037 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text034 {
  font-size: 14px;
  text-align: center;
}
.middle-pasted-image06 {
  width: 95px;
}
.middle-text035 {
  font-size: 14px;
  text-align: center;
}
.middle-container038 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text036 {
  font-size: 14px;
  text-align: center;
}
.middle-pasted-image07 {
  width: 95px;
}
.middle-text037 {
  font-size: 14px;
  text-align: center;
}
.middle-container039 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-container040 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text038 {
  font-size: 12px;
  text-align: center;
}
.middle-text040 {
  font-weight: 700;
}
.middle-pasted-image08 {
  width: 137px;
  height: 137px;
}
.middle-container041 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text042 {
  font-size: 12px;
  text-align: center;
}
.middle-text044 {
  font-weight: 700;
}
.middle-pasted-image09 {
  width: 137px;
  height: 137px;
}
.middle-text046 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-card-profession {
  height: 140px;
}
.middle-pasted-image10 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container042 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text047 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li12 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text048 {
  font-weight: 300;
}
.middle-li13 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text049 {
  font-weight: 300;
}
.middle-card-profession1 {
  height: 140px;
}
.middle-container043 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.middle-pasted-image11 {
  width: 100px;
  height: 100px;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
}
.middle-container044 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text050 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li14 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text051 {
  font-weight: 300;
}
.middle-li15 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text052 {
  font-weight: 300;
}
.middle-body-by-soft-skill {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: 70px;
  flex-direction: row;
}
.middle-container045 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-container046 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.middle-title1 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 20px;
  align-self: auto;
  font-style: Medium;
  margin-top: var(--dl-space-space-threeunits);
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.middle-container047 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.middle-container048 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-container049 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-card-profession2 {
  height: 140px;
}
.middle-pasted-image12 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container050 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text053 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li16 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text054 {
  font-weight: 300;
}
.middle-li17 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text055 {
  font-weight: 300;
}
.middle-container051 {
  height: 140px;
}
.middle-container052 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.middle-pasted-image13 {
  width: 96px;
}
.middle-container053 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text056 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li18 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text057 {
  font-weight: 300;
}
.middle-li19 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text058 {
  font-weight: 300;
}
.middle-container054 {
  height: 140px;
}
.middle-container055 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.middle-pasted-image14 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container056 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text059 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li20 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text060 {
  font-weight: 300;
}
.middle-li21 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text061 {
  font-weight: 300;
}
.middle-container057 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-container058 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-container059 {
  height: 140px;
}
.middle-pasted-image15 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container060 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text062 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li22 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text063 {
  font-weight: 300;
}
.middle-li23 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text064 {
  font-weight: 300;
}
.middle-container061 {
  height: 140px;
}
.middle-pasted-image16 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container062 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text065 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li24 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text066 {
  font-weight: 300;
}
.middle-li25 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text067 {
  font-weight: 300;
}
.middle-container063 {
  height: 140px;
}
.middle-pasted-image17 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container064 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text068 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li26 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text069 {
  font-weight: 300;
}
.middle-li27 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text070 {
  font-weight: 300;
}
.middle-container065 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 340px;
  height: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.middle-match-suggestions-wrapper1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
}
.middle-container066 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-container067 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text071 {
  font-size: 14px;
  text-align: center;
}
.middle-pasted-image18 {
  width: 95px;
}
.middle-text072 {
  font-size: 14px;
  text-align: center;
}
.middle-container068 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text073 {
  font-size: 14px;
  text-align: center;
}
.middle-pasted-image19 {
  width: 95px;
}
.middle-text074 {
  font-size: 14px;
  text-align: center;
}
.middle-container069 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-container070 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text075 {
  font-size: 12px;
  text-align: center;
}
.middle-text077 {
  font-weight: 700;
}
.middle-pasted-image20 {
  width: 137px;
  height: 137px;
}
.middle-container071 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text079 {
  font-size: 12px;
  text-align: center;
}
.middle-text081 {
  font-weight: 700;
}
.middle-pasted-image21 {
  width: 137px;
  height: 137px;
}
.middle-text083 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-card-profession3 {
  height: 140px;
}
.middle-pasted-image22 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container072 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text084 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li28 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text085 {
  font-weight: 300;
}
.middle-li29 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text086 {
  font-weight: 300;
}
.middle-card-profession4 {
  height: 140px;
}
.middle-container073 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.middle-pasted-image23 {
  width: 100px;
  height: 100px;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
}
.middle-container074 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text087 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li30 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text088 {
  font-weight: 300;
}
.middle-li31 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text089 {
  font-weight: 300;
}
.middle-body-points {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: 70px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
}
.middle-container075 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-container076 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.middle-container077 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: space-between;
}
.middle-pasted-image24 {
  width: 96px;
}
.middle-pasted-image25 {
  width: 756px;
}
.middle-container078 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 340px;
  height: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.middle-match-suggestions-wrapper2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
}
.middle-container079 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-container080 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text090 {
  font-size: 14px;
  text-align: center;
}
.middle-pasted-image26 {
  width: 95px;
}
.middle-text091 {
  font-size: 14px;
  text-align: center;
}
.middle-container081 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text092 {
  font-size: 14px;
  text-align: center;
}
.middle-pasted-image27 {
  width: 95px;
}
.middle-text093 {
  font-size: 14px;
  text-align: center;
}
.middle-container082 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-container083 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text094 {
  font-size: 12px;
  text-align: center;
}
.middle-text096 {
  font-weight: 700;
}
.middle-pasted-image28 {
  width: 137px;
  height: 137px;
}
.middle-container084 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text098 {
  font-size: 12px;
  text-align: center;
}
.middle-text100 {
  font-weight: 700;
}
.middle-pasted-image29 {
  width: 137px;
  height: 137px;
}
.middle-text102 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-card-profession5 {
  height: 140px;
}
.middle-pasted-image30 {
  width: 100px;
  height: 100px;
  align-self: flex-start;
}
.middle-container085 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text103 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li32 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text104 {
  font-weight: 300;
}
.middle-li33 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text105 {
  font-weight: 300;
}
.middle-card-profession6 {
  height: 140px;
}
.middle-container086 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.middle-pasted-image31 {
  width: 100px;
  height: 100px;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
}
.middle-container087 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text106 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li34 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text107 {
  font-weight: 300;
}
.middle-li35 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text108 {
  font-weight: 300;
}
.middle-body-about {
  gap: 70px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-twounits);
  padding-right: 70px;
  flex-direction: row;
}
.middle-container088 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-container089 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.middle-pasted-image32 {
  height: 100px;
}
.middle-container090 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-text109 {
  font-size: 18px;
  line-height: 180%;
}
.middle-text110 {
  font-size: 18px;
  line-height: 180%;
}
.middle-container091 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.middle-text111 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.middle-ul18 {
  margin-top: 0px;
  line-height: 180%;
}
.middle-li36 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text112 {
  font-weight: 300;
}
.middle-li37 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text113 {
  font-weight: 300;
}
.middle-li38 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text114 {
  font-weight: 300;
}
.middle-li39 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text115 {
  font-weight: 300;
}
.middle-li40 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text116 {
  font-weight: 300;
}
.middle-li41 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text117 {
  font-weight: 300;
}
.middle-li42 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text118 {
  font-weight: 300;
}
.middle-li43 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text119 {
  font-weight: 300;
}
.middle-li44 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text120 {
  font-weight: 300;
}
.middle-li45 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text121 {
  font-weight: 300;
}
.middle-li46 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text122 {
  font-weight: 300;
}
.middle-li47 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text123 {
  font-weight: 300;
}
.middle-li48 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text124 {
  font-weight: 300;
}
.middle-li49 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text125 {
  font-weight: 300;
}
.middle-li50 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text126 {
  font-weight: 300;
}
.middle-container092 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 340px;
  height: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.middle-match-suggestions-wrapper3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
}
.middle-container093 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-container094 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text127 {
  font-size: 14px;
  text-align: center;
}
.middle-pasted-image33 {
  width: 95px;
}
.middle-text128 {
  font-size: 14px;
  text-align: center;
}
.middle-container095 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text129 {
  font-size: 14px;
  text-align: center;
}
.middle-pasted-image34 {
  width: 95px;
}
.middle-text130 {
  font-size: 14px;
  text-align: center;
}
.middle-container096 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.middle-container097 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text131 {
  font-size: 12px;
  text-align: center;
}
.middle-text133 {
  font-weight: 700;
}
.middle-pasted-image35 {
  width: 137px;
  height: 137px;
}
.middle-container098 {
  flex: 0 0 auto;
  width: 160px;
  height: 200px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.middle-text135 {
  font-size: 12px;
  text-align: center;
}
.middle-text137 {
  font-weight: 700;
}
.middle-pasted-image36 {
  width: 137px;
  height: 137px;
}
.middle-text139 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: Regular;
  margin-top: var(--dl-space-space-fiveunits);
  text-align: center;
  font-family: Fredoka;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-card-profession7 {
  height: 140px;
}
.middle-pasted-image37 {
  width: 100px;
  height: 100px;
  align-self: center;
}
.middle-container099 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text140 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li51 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text141 {
  font-weight: 300;
}
.middle-li52 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text142 {
  font-weight: 300;
}
.middle-card-profession8 {
  height: 140px;
}
.middle-container100 {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}
.middle-pasted-image38 {
  width: 100px;
  height: 100px;
  align-self: center;
  border-color: var(--dl-color-app-lightblue);
  border-width: 1px;
}
.middle-container101 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.middle-text143 {
  color: rgb(5, 47, 156);
  height: auto;
  font-size: 18px;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Fredoka;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.middle-li53 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text144 {
  font-weight: 300;
}
.middle-li54 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.middle-text145 {
  font-weight: 300;
}
</style>
