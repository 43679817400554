import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import AppGames from './views/app-games'
import OnBoardFromSteam from './views/on-board-from-steam'
import PrivacyPolicy from './views/privacy-policy'
import AppProfile from './views/app-profile'
import AppCoachProfileMemberImprovement from './views/app-coach-profile-member-improvement'
import Register from './views/register'
import CookiePolicy from './views/cookie-policy'
import AppCoachProfileMemberGames from './views/app-coach-profile-member-games'
import AppImprovement from './views/app-improvement'
import AppCoachProfile from './views/app-coach-profile'
import AppCoachProfileTeams from './views/app-coach-profile-teams'
import HowItWorks from './views/how-it-works'
import AppMatch from './views/app-match'
import SignUp from './views/sign-up'
import App from './views/app'
import AppCoachProfileMemberSkills from './views/app-coach-profile-member-skills'
import SignIn from './views/sign-in'
import Home from './views/home'
import NotFound from './views/not-found'
import './style.css'

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'App-Games',
      path: '/app-games',
      component: AppGames,
    },
    {
      name: 'On-Board-from-Steam',
      path: '/on-board-from-steam',
      component: OnBoardFromSteam,
    },
    {
      name: 'Privacy-Policy',
      path: '/privacy-policy',
      component: PrivacyPolicy,
    },
    {
      name: 'App-Profile',
      path: '/app-profile',
      component: AppProfile,
    },
    {
      name: 'App-Coach-Profile-Member-Improvement',
      path: '/app-coach-profile-member-improvement',
      component: AppCoachProfileMemberImprovement,
    },
    {
      name: 'Register',
      path: '/register',
      component: Register,
    },
    {
      name: 'Cookie-Policy',
      path: '/cookie-policy',
      component: CookiePolicy,
    },
    {
      name: 'App-Coach-Profile-Member-Games',
      path: '/app-coach-profile-member-games',
      component: AppCoachProfileMemberGames,
    },
    {
      name: 'App-Improvement',
      path: '/app-improvement',
      component: AppImprovement,
    },
    {
      name: 'App-Coach-Profile',
      path: '/app-coach-profile',
      component: AppCoachProfile,
    },
    {
      name: 'App-Coach-Profile-Teams',
      path: '/app-coach-profile-teams',
      component: AppCoachProfileTeams,
    },
    {
      name: 'How-it-works',
      path: '/how-it-works',
      component: HowItWorks,
    },
    {
      name: 'App-Match',
      path: '/app-match',
      component: AppMatch,
    },
    {
      name: 'Sign-up',
      path: '/sign-up',
      component: SignUp,
    },
    {
      name: 'App',
      path: '/app',
      component: App,
    },
    {
      name: 'App-Coach-Profile-Member-Skills',
      path: '/app-coach-profile-member-skills',
      component: AppCoachProfileMemberSkills,
    },
    {
      name: 'Sign-In',
      path: '/sign-in',
      component: SignIn,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to && to.hash) // Available when the route is like "component#anchor"
      return {
          selector: to.hash,
          offset: { x: 0, y: 80 }, // Avoid blocking the view when having fixed components
          behavior: 'auto'
        };
    if (savedPosition) return savedPosition; // Only available if this is a popstate navigation (triggered by the browser's back/forward buttons)
    return { x: 0, y: 0, behavior:'auto' };
  }
})
