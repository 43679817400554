import { render, staticRenderFns } from "./button-with-icon.vue?vue&type=template&id=310e7abc&scoped=true"
import script from "./button-with-icon.vue?vue&type=script&lang=js"
export * from "./button-with-icon.vue?vue&type=script&lang=js"
import style0 from "./button-with-icon.vue?vue&type=style&index=0&id=310e7abc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310e7abc",
  null
  
)

export default component.exports