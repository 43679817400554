var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"on-board-from-steam-container"},[_c('app-header',{attrs:{"rootClassName":"header-root-class-name4"}}),_c('div',{staticClass:"on-board-from-steam-container01"},[_c('div',{staticClass:"on-board-from-steam-container02"},[_c('app-h1',{attrs:{"H1":"Profile","rootClassName":"h1-root-class-name5"}}),_c('span',{staticClass:"on-board-from-steam-text"},[_vm._v("Tell us a bit about yourself")])],1),_c('div',{staticClass:"on-board-from-steam-container03"},[_c('div',{staticClass:"on-board-from-steam-container04 section"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('router-link',{staticClass:"on-board-from-steam-navlink button",attrs:{"to":"/app"}},[_c('span',[_c('span',[_vm._v("ENTER")]),_c('br')])])],1)])]),_c('app-footer',{attrs:{"rootClassName":"footer-root-class-name8"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"on-board-from-steam-container05"},[_c('span',{staticClass:"on-board-from-steam-text01"},[_vm._v("First and last name")]),_c('input',{staticClass:"on-board-from-steam-textinput input",attrs:{"type":"text","placeholder":"Enter your name"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"on-board-from-steam-container06"},[_c('div',{staticClass:"on-board-from-steam-container07"},[_c('span',{staticClass:"on-board-from-steam-text02"},[_vm._v("Email")]),_c('span',{staticClass:"on-board-from-steam-text03"},[_vm._v("*")])]),_c('input',{staticClass:"on-board-from-steam-textinput1 input",attrs:{"type":"text","placeholder":"Enter your email address"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"on-board-from-steam-container08"},[_c('span',{staticClass:"on-board-from-steam-text04"},[_vm._v("Location")]),_c('input',{staticClass:"on-board-from-steam-textinput2 input",attrs:{"type":"text","placeholder":"Enter your location"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"on-board-from-steam-container09"},[_c('span',{staticClass:"on-board-from-steam-text05"},[_vm._v("Birthday")]),_c('input',{staticClass:"on-board-from-steam-textinput3 input",attrs:{"type":"text","placeholder":"Enter your birthday: MM/DD/YYYY"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"on-board-from-steam-container10"},[_c('span',{staticClass:"on-board-from-steam-text06"},[_vm._v(" Profession/Filed of study ")]),_c('input',{staticClass:"on-board-from-steam-textinput4 input",attrs:{"type":"text","placeholder":"Enter your profession or field of study"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"on-board-from-steam-container11"},[_c('span',{staticClass:"on-board-from-steam-text07"},[_vm._v(" Why do you want to improve your soft skills? ")]),_c('div',{staticClass:"on-board-from-steam-container12"},[_c('span',{staticClass:"on-board-from-steam-text08"},[_vm._v(" Cognitive Flexibility ")]),_c('img',{staticClass:"on-board-from-steam-pasted-image",attrs:{"alt":"pastedImage","src":"/external/pastedimage-fv4-200h.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"on-board-from-steam-container13"},[_c('img',{staticClass:"on-board-from-steam-checkbox",attrs:{"alt":"pastedImage","src":"/external/pastedimage-zb1.svg"}}),_c('span',[_vm._v("I'm 16 years or older")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"on-board-from-steam-container14"},[_c('img',{staticClass:"on-board-from-steam-checkbox1",attrs:{"alt":"pastedImage","src":"/external/pastedimage-zb1.svg"}}),_c('span',[_vm._v("I have read the Privacy and Cookie Policy")])])
}]

export { render, staticRenderFns }