<template>
  <div class="mobile-screen-container" v-bind:class="rootClassName">
    <span class="mobile-screen-text">
      The App, at this time, is available only for desktop.
    </span>
  </div>
</template>

<script>
export default {
  name: 'MobileScreen',
  props: {
    rootClassName: String,
  },
}
</script>

<style scoped>
.mobile-screen-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: none;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: center;
}









@media(max-width: 991px) {
  .mobile-screen-container {
    display: flex;
  }
  .mobile-screen-text {
    font-size: 22px;
  }
}
@media(max-width: 479px) {
  .mobile-screen-text {
    font-size: 22px;
  }
}
</style>
