<template>
  <div class="value-container">
    <span class="value-text">{{ label }}</span>
    <div class="value-container1">
      <span class="value-text1">{{ value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Value',
  props: {
    label: {
      type: String,
      default: 'Email',
    },
    value: {
      type: String,
      default: 'bettyboopwashington@somemail.com',
    },
  },
}
</script>

<style scoped>
.value-container {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 700px;
  align-items: flex-start;
  flex-direction: column;
}
.value-text {
  font-size: 24px;
}
.value-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-app-primary);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.value-text1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
}
</style>
