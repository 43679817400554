<template>
  <div class="cookie-policy-container">
    <app-header rootClassName="header-root-class-name6"></app-header>
    <div class="cookie-policy-container1 section">
      <div id="cookie-policy" class="cookie-policy-container2">
        <app-h2 H2="COOKIE POLICY" rootClassName="h2-root-class-name22"></app-h2>
      </div>
      <app-h3 h3="Use of Cookies"></app-h3>
      <p class="p16">
        When you visit any website, it may store or retrieve information on your
        browser, mostly in the form of cookies. This information could be about
        you, your preferences or your device and is mostly used to make the site
        work according to your expectations. The information does not usually
        directly identify you, but it can provide a more personalized web
        experience. Because we respect your right to the protection of your
        personal data, you can choose not to allow some types of cookies. However,
        the inhibition of some types of cookies can have consequences on your
        navigation on the site and on the use of the related services that we are
        able to offer. Further information The following information is provided
        to the user in implementation of the provision of the Guarantor for the
        protection of personal data of 8 May 2014 &quot;Identification of the
        simplified procedures for the information and the acquisition of consent
        for the use of cookies&quot; and in compliance to the GDPR EU Reg.
        2016/679. What are Cookies? And which cookies do we use? Cookies are small
        text strings that a website can send, while browsing, to your device (be
        it a PC, a notebook, a smartphone, a tablet; usually they are stored
        directly on the browser used for browsing) . The same website that
        transmitted them can read and record the cookies that are located on the
        same device to obtain various types of information. Which? For each type
        of cookie there is a well-defined role.
      </p>
      <p class="p16">
        We use cookies and pixel tags to track our Users&apos; use of the Sites
        and to understand their preferences (such as country and language
        choices). This allows us to provide services and improve their online
        experience. We also use cookies and pixel tags to obtain aggregate data
        about site traffic and site interaction, to identify trends and obtain
        statistics so that we can always improve our Sites.
      </p>
      <p class="p16">
        In general, SkillQuest collects and records information from your browser
        as you use the site, which may also include personal data. We use a
        variety of methods, such as cookies and pixel tags, to collect this
        information, which may include: (i) your IP address; (ii) unique cookie
        identifier, cookie information, and information about whether or not
        device software is available to access certain features; (iii) unique
        device identifier and device type; (iv) domain, browser type and language,
        (v) operating system and system settings; (vi) country and time zone;
        (vii) previously visited websites; (viii) information about your
        interaction with our Sites, such as clicks made, purchases and preferences
        indicated;
      </p>
      <p class="p16">
        Third parties may also collect information from the Sites through cookies,
        third-party plug-ins and widgets. The third parties collect data directly
        from your web browser and the processing and treatment of the
        aforementioned data is subject to their respective privacy regulations
        <span v-html="rawu7r0"></span>
      </p>
      <app-h3 h3="How many types of cookies are there?"></app-h3>
      <p class="p16">
        There are two macro-categories, with different characteristics: technical
        cookies and profiling cookies.
        <span v-html="rawhfjx"></span>
      </p>
      <ul class="cookie-policy-ul list">
        <li class="cookie-policy-li">
          <p class="p16">
            Technical cookies are generally necessary for the correct functioning
            of the website and to allow navigation; without them you may not be
            able to view the pages correctly or use some services. For example, a
            technical cookie is essential to keep the user connected throughout
            the visit to a website, or to memorize the language settings, display
            settings, and so on. The technical cookies used on this site can be
            further divided into:
          </p>
        </li>
        <li class="cookie-policy-li01">
          <p class="p16">
            Necessary cookies These cookies are necessary for the site to
            function, with particular reference to normal navigation and use of
            the same, and cannot be deactivated in our systems. They are usually
            only set in response to actions you take that constitute a request for
            services, such as setting your privacy preferences, logging in or
            filling out forms. You can set your browser to block these cookies,
            but some or all parts of the site will not work as a result. These
            cookies do not store personal information.
          </p>
        </li>
        <li class="cookie-policy-li02">
          <p class="p16">
            Analytical cookies These cookies allow visits and traffic sources to
            be counted in order to evaluate and improve the performance of the
            site. All information collected by cookies is aggregated and therefore
            anonymous. If you do not authorize these cookies, we will not be able
            to evaluate navigation and the collective behavior of our users and
            consequently improve the performance of the site.
          </p>
        </li>
        <li class="cookie-policy-li03">
          <p class="p16">
            Functionality cookies These cookies allow the site to provide advanced
            features and customization, which allow navigation according to a
            series of selected criteria (for example, the language, the products
            selected for purchases) in order to improve the service. They can be
            set by us or by third-party providers whose services have been added
            to our pages. If you do not authorize these cookies, some or all of
            these services may not work properly.
          </p>
        </li>
        <li class="cookie-policy-li04">
          <p class="p16">
            Profiling cookies have the task of profiling the user and are used in
            order to send advertising messages in line with the preferences
            expressed by the same during his navigation
          </p>
        </li>
      </ul>
      <p class="p16">
        These cookies can also be set, through our site, only by our advertising
        partners and therefore are third-party cookies. These cookies are based
        solely on identifying your browser and internet device. If you do not
        allow these cookies, you will not be shown or sent the aforementioned
        advertisements, nor will profiles of your interests be created. The
        refusal or withdrawal of consent to the use of one or more profiling
        cookies will not interfere with the possibility of accessing the Site or
        browsing on it.
      </p>
      <app-h3
        h3="The use of these cookies requires the prior acquisition of the user's free consent"
      ></app-h3>
      <p class="p16">
        You can always change your preference by visiting the Cookie Policy at the
        bottom of each page of our Sites. Interaction with social networks and
        external platforms Social media related cookies offer the ability to
        connect to social networks and share content from our site through social
        media. Advertising cookies (of third parties) collect information to help
        better tailor ads to your interests, both on and off our Sites. In some
        cases, these cookies involve the processing of your personal data.
        Rejecting these cookies may result in the display of advertisements that
        are not relevant to the user or the inability to connect effectively via
        Facebook, Twitter or other social networks and/or to share content on
        social media. You are not expected to interact with such platforms.
        <span v-html="rawsjqw"></span>
      </p>
      <app-h3 h3="Statistics"></app-h3>
      <p class="p16">
        The services contained in this section allow the Data Controller to
        monitor and analyze traffic data and are used to keep track of User
        behavior.Google Analytics (Google) Google Analytics is a web analytics
        service provided by Google Inc. (&quot;Google&quot;). Google uses the
        Personal Data collected for the purpose of tracking and examining the use
        of this Application, compiling reports and sharing them with other
        services developed by Google. Google may use the Personal Data to
        contextualize and personalize the advertisements of its advertising
        network. Personal data collected: Cookies and usage data.
        <span v-html="rawtlhi"></span>
      </p>
      <p class="p16">
        <span>Place of processing: USA</span>
        <br />
        <span>
          Privacy policy
          <span v-html="rawvlk3"></span>
        </span>
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noreferrer noopener"
          class="cookie-policy-link"
        >
          https://policies.google.com/privacy?hl=en
        </a>
        <br />
        <span>
          Opt out:
          <span v-html="raw4gn2"></span>
        </span>
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=en"
          target="_blank"
          rel="noreferrer noopener"
          class="cookie-policy-link1"
        >
          https://tools.google.com/dlpage/gaoptout?hl=en
        </a>
        <span><span v-html="rawnijz"></span></span>
        <br />
        <span>
          Duration of cookies Cookies have a duration dictated by the expiration
          date (or by a specific action such as closing the browser) set at the
          time of installation.
        </span>
      </p>
      <p class="p16">
        <span>Cookies, in this sense, can be:</span>
        <br />
      </p>
      <ul class="cookie-policy-ul01 list">
        <li class="cookie-policy-li05">
          <p class="p16">
            temporary or session: they are used to store temporary information,
            allow you to link the actions performed during a specific session and
            are removed from the computer when the browser is closed;
          </p>
        </li>
        <li class="cookie-policy-li06">
          <p class="p16">
            permanent: they are used to store information, such as the login name
            and password, in order to avoid the user having to type them again
            each time he visits a specific site. These remain stored on your
            computer even after you close your browser. Cookie management The User
            can deny his consent and/or modify his options relating to the use of
            cookies by this Cookie Policy at any time, by accessing the same which
            can be &quot;linked&quot; from every page of this Site.
            <span v-html="rawj4qx"></span>
          </p>
        </li>
      </ul>
      <app-h3
        h3="How to disable/delete cookies by configuring the browser"
      ></app-h3>
      <app-h3 h3="Chrome"></app-h3>
      <ul class="cookie-policy-ul02 list">
        <li class="cookie-policy-li07"><p class="p16">Run Chrome Browser</p></li>
        <li class="cookie-policy-li08">
          <p class="p16">
            <span>
              Click on the menu in the browser toolbar next to the url entry
              window for navigation
              <span v-html="rawqy26"></span>
            </span>
            <br />
            <span><span v-html="rawr3lg"></span></span>
          </p>
        </li>
        <li class="cookie-policy-li09">
          <p class="p16">
            Select Settings
            <span v-html="raw1qbb"></span>
          </p>
        </li>
        <li class="cookie-policy-li10">
          <p class="p16">Click Show Advanced Settings</p>
        </li>
        <li class="cookie-policy-li11">
          <p class="p16">
            In the &quot;Privacy&quot; section, click on the Content Settings
            button
            <span v-html="rawqgoe"></span>
          </p>
        </li>
      </ul>
      <ul class="cookie-policy-ul03 list">
        <li class="cookie-policy-li12">
          <p class="p16">
            Allow saving data locally
            <span v-html="raweo2k"></span>
          </p>
        </li>
        <li class="cookie-policy-li13">
          <p class="p16">
            Change the local data only until the browser is closed
            <span v-html="rawflgc"></span>
          </p>
        </li>
        <li class="cookie-policy-li14">
          <p class="p16">
            Prevent sites from setting cookies
            <span v-html="rawbvw8"></span>
          </p>
        </li>
        <li class="cookie-policy-li15">
          <p class="p16">
            Block third-party cookies and site data
            <span v-html="rawtbc1"></span>
          </p>
        </li>
        <li class="cookie-policy-li16">
          <p class="p16">
            Manage exceptions for some internet sites
            <span v-html="rawggdp"></span>
          </p>
        </li>
        <li class="cookie-policy-li17">
          <p class="p16">
            Delete one or all cookies
            <span v-html="rawxlca"></span>
          </p>
        </li>
      </ul>
      <p class="p16">
        For more information, visit the dedicated page.
        <span v-html="rawvkpu"></span>
      </p>
      <p class="p16"></p>
      <app-h3 h3="Mozilla Firefox"></app-h3>
      <ul class="cookie-policy-ul04 list">
        <li class="cookie-policy-li18">
          <p class="p16">
            Run the Mozilla Firefox Browser
            <span v-html="rawvkxp"></span>
          </p>
        </li>
        <li class="cookie-policy-li19">
          <p class="p16">
            Click on the menu in the browser toolbar next to the url entry window
            for navigation
            <span v-html="rawzwlq"></span>
          </p>
        </li>
        <li class="cookie-policy-li20">
          <p class="p16">
            Select Options
            <span v-html="raw9gw1"></span>
          </p>
        </li>
        <li class="cookie-policy-li21">
          <p class="p16">
            Select the Privacy panel
            <span v-html="rawrdnv"></span>
          </p>
        </li>
        <li class="cookie-policy-li22">
          <p class="p16">
            Click Show Advanced Settings
            <span v-html="rawsvqi"></span>
          </p>
        </li>
        <li class="cookie-policy-li23">
          <p class="p16">
            In the &quot;Privacy&quot; section, click on the Content Settings
            button
            <span v-html="rawhwq5"></span>
          </p>
        </li>
      </ul>
      <p class="p16">
        In the &quot;Privacy&quot; section, click on the Content Settings button
        <span v-html="rawojxq"></span>
      </p>
      <ul class="cookie-policy-ul05 list">
        <li class="cookie-policy-li24">
          <p class="p16">
            Request sites not to do any tracking
            <span v-html="raw5jqh"></span>
          </p>
        </li>
        <li class="cookie-policy-li25">
          <p class="p16">
            Communicate to the sites the availability to be tracked
            <span v-html="raweqh1"></span>
          </p>
        </li>
        <li class="cookie-policy-li26">
          <p class="p16">
            Do not communicate any preferences regarding the tracking of personal
            data
            <span v-html="rawvk94"></span>
          </p>
        </li>
      </ul>
      <p class="p16">
        From the History section you can:
        <span v-html="raw0dlb"></span>
      </p>
      <ul class="cookie-policy-ul06 list">
        <li class="cookie-policy-li27">
          <p class="p16">
            <span>
              By enabling &quot;Use custom settings&quot;, select to accept
              third-party cookies (always, from the most visited or never visited
              sites) and to keep them for a specific period (until they expire,
              close Firefox or ask each time)
              <span v-html="raw4ts2"></span>
            </span>
            <br />
            <span><span v-html="raw3c5g"></span></span>
          </p>
        </li>
        <li class="cookie-policy-li28">
          <p class="p16">Remove individual stored cookies.</p>
        </li>
      </ul>
      <app-h3 h3="Internet Explorer"></app-h3>
      <ul class="cookie-policy-ul07 list">
        <li class="cookie-policy-li29">
          <p class="p16">
            Run the Internet Explorer Browser
            <span v-html="raw4n3h"></span>
          </p>
        </li>
        <li class="cookie-policy-li30">
          <p class="p16">
            Click the Tools button and choose Internet Options
            <span v-html="raw4xk8"></span>
          </p>
        </li>
        <li class="cookie-policy-li31">
          <p class="p16">
            Click on the Privacy tab and, in the Settings section, change the
            slider according to the desired action for the cookies:
            <span v-html="raw3y32"></span>
          </p>
        </li>
        <li class="cookie-policy-li32">
          <p class="p16">
            Block all cookies
            <span v-html="rawm3un"></span>
          </p>
        </li>
        <li class="cookie-policy-li33"><p class="p16">Allow all cookies</p></li>
        <li class="cookie-policy-li34">
          <p class="p16">
            Select the sites from which to obtain cookies: move the cursor to an
            intermediate position so as not to block or allow all cookies, then
            press on Sites, in the Web Site Address box enter an internet site and
            then press on Block or Allow.
            <span v-html="rawdp38"></span>
          </p>
        </li>
      </ul>
      <p class="p16">
        For more information, visit the dedicated page.
        <span v-html="rawwlhh"></span>
      </p>
      <app-h3 h3="Safari 6"></app-h3>
      <ul class="cookie-policy-ul08 list">
        <li class="cookie-policy-li35">
          <p class="p16">
            Run the Safari Browser
            <span v-html="rawi6su"></span>
          </p>
        </li>
        <li class="cookie-policy-li36">
          <p class="p16">
            Click on Safari, select Preferences and click on Privacy
            <span v-html="rawdwc7"></span>
          </p>
        </li>
        <li class="cookie-policy-li37">
          <p class="p16">
            In the Block Cookies section, specify how Safari must accept cookies
            from websites. To view which sites have stored cookies, click on
            Details
            <span v-html="rawcxix"></span>
          </p>
        </li>
      </ul>
      <p class="p16">
        For more information, visit the dedicated page.
        <span v-html="raw4y2h"></span>
      </p>
      <app-h3 h3="Safari iOS (mobile devices)"></app-h3>
      <ul class="cookie-policy-ul09 list">
        <li class="cookie-policy-li38">
          <p class="p16">
            Run the iOS Safari Browser
            <span v-html="raw49z0"></span>
          </p>
        </li>
        <li class="cookie-policy-li39">
          <p class="p16">
            Tap on Settings and then Safari
            <span v-html="rawuljv"></span>
          </p>
        </li>
        <li class="cookie-policy-li40">
          <p class="p16">
            Touch Block Cookies and choose from the various options:
            &quot;Never&quot;, &quot;Third-party and advertisers&quot; or
            &quot;Always&quot;
            <span v-html="rawyv0s"></span>
          </p>
        </li>
      </ul>
      <p class="p16">
        To delete all cookies stored by Safari, tap on Settings, then on Safari
        and finally on Clear Cookies and Data
        <span v-html="rawstsa"></span>
      </p>
      <p class="p16">
        For more information, visit the dedicated page.
        <span v-html="rawbsrn"></span>
      </p>
      <app-h3 h3="Opera"></app-h3>
      <ul class="cookie-policy-ul10 list">
        <li class="cookie-policy-li41">
          <p class="p16">
            Run the Opera Browser
            <span v-html="rawtqp0"></span>
          </p>
        </li>
        <li class="cookie-policy-li42">
          <p class="p16">
            Select one of the following options:
            <span v-html="rawsij4"></span>
          </p>
          <ul class="cookie-policy-ul11 list">
            <li class="cookie-policy-li43">
              <p class="p16">
                Accept all cookies
                <span v-html="rawib4u"></span>
              </p>
            </li>
            <li class="cookie-policy-li44">
              <p class="p16">
                Accept cookies only from the site you are visiting: third-party
                cookies and those sent from a domain other than the one you are
                visiting will be rejected
                <span v-html="rawd7rn"></span>
              </p>
            </li>
            <li class="cookie-policy-li45">
              <p class="p16">
                Never accept cookies: all cookies will never be saved.
                <span v-html="raw50rd"></span>
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <app-footer rootClassName="footer-root-class-name16"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppH2 from '../components/h2'
import AppH3 from '../components/h3'
import AppFooter from '../components/footer'

export default {
  name: 'CookiePolicy',
  components: {
    AppHeader,
    AppH2,
    AppH3,
    AppFooter,
  },
  data() {
    return {
      rawu7r0: ' ',
      rawhfjx: ' ',
      rawsjqw: ' ',
      rawtlhi: ' ',
      rawvlk3: ' ',
      rawmkw0: ' ',
      rawnijz: ' ',
      rawj4qx: ' ',
      rawqy26: ' ',
      rawr3lg: ' ',
      raw1qbb: ' ',
      rawqgoe: ' ',
      raweo2k: ' ',
      rawflgc: ' ',
      rawbvw8: ' ',
      rawtbc1: ' ',
      rawggdp: ' ',
      rawxlca: ' ',
      rawvkpu: ' ',
      rawvkxp: ' ',
      rawzwlq: ' ',
      raw9gw1: ' ',
      rawrdnv: ' ',
      rawsvqi: ' ',
      rawhwq5: ' ',
      rawojxq: ' ',
      raw5jqh: ' ',
      raweqh1: ' ',
      rawvk94: ' ',
      raw0dlb: ' ',
      raw4ts2: ' ',
      raw3c5g: ' ',
      raw4n3h: ' ',
      raw4xk8: ' ',
      raw3y32: ' ',
      rawm3un: ' ',
      rawdp38: ' ',
      rawwlhh: ' ',
      rawi6su: ' ',
      rawdwc7: ' ',
      rawcxix: ' ',
      raw4y2h: ' ',
      raw49z0: ' ',
      rawuljv: ' ',
      rawi1nx: ' ',
      rawc9c5: ' ',
      rawbsrn: ' ',
      rawtqp0: ' ',
      rawsij4: ' ',
      rawib4u: ' ',
      rawd7rn: ' ',
      raw50rd: ' ',
    }
  },
  metaInfo: {
    title: 'Cookie-Policy - SkillQuest',
    meta: [
      {
        property: 'og:title',
        content: 'Cookie-Policy - SkillQuest',
      },
    ],
  },
}
</script>

<style scoped>
.cookie-policy-container {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.cookie-policy-container1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.cookie-policy-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.cookie-policy-ul {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li01 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li02 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li03 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li04 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-link {
  text-decoration: underline;
}
.cookie-policy-link1 {
  text-decoration: underline;
}
.cookie-policy-ul01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li05 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li06 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-ul02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li07 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li08 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li09 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li10 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li11 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-ul03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li12 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li13 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li14 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li15 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li16 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li17 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-ul04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li18 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li19 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li20 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li21 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li22 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li23 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-ul05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li24 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li25 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li26 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-ul06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li27 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li28 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-ul07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li29 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li30 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li31 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li32 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li33 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li34 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-ul08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li35 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li36 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li37 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-ul09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li38 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li39 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li40 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-ul10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li41 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li42 {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-ul11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.cookie-policy-li43 {
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li44 {
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.cookie-policy-li45 {
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
@media(max-width: 767px) {
  .cookie-policy-container {
    gap: var(--dl-space-space-sixunits);
  }
}
</style>
